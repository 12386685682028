<template>
  <div class="box">
    <div class="formClass">
      <el-form
        ref="queryForm"
        :label-position="$i18n.locale!='zh'?'top':'right'"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :model="param"
        :rules="rules"
        class="queryFormClass"
      >
        <el-row :gutter="20">
          <el-col v-if="operationType === 2" :span="24">
            <!--费用编码-->
            <el-form-item :label="$t('basicData.FeeCode')" prop="code">
              <span>{{ param.code }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称-->
            <el-form-item :label="$t('basicData.ExpenseName')" prop="name">
              <el-input v-model="param.name" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称(PU)-->
            <el-form-item :label="$t('basicData.ExpenseName') + '(PU)'" prop="namePu">
              <el-input v-model="param.namePu" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="param.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" multiple>
                <!--快递-->
                <el-option key="0" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                <!--揽收-->
                <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="1" />
                <!--派件-->
                <el-option key="2" :label="$t('collectionCenter.delivery')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--状态-->
            <el-form-item :label="$t('basicData.status')" prop="state">
              <el-radio-group v-model="param.state" size="small">
                <!--禁用-->
                <el-radio-button :label="false">{{ $t('basicData.disable') }}</el-radio-button>
                <!--启用-->
                <el-radio-button :label="true">{{ $t('basicData.enable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--备注-->
            <el-form-item :label="$t('basicData.remark')" prop="remark">
              <el-input
                v-model="param.remark" :placeholder="$t('basicData.PleaseEnter')" :rows="4"
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--特殊规则-->
            <el-form-item :label="$t('basicData.SpecialRules')" prop="specialRules">
              <el-select v-model="param.specialRules" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <!--申报金额比例（可设置最低）-->
                <el-option key="0" :label="$t('basicData.PercentageOfDeclaredAmountMinimumCanBeSet')" :value="0" />
                <!--订单所有费用比例（GRIS）-->
                <el-option key="1" :label="$t('basicData.OrderAllCostRatioGRIS') + '（GRIS）'" :value="1" />
                <!--ISS税  ISS税（n/（1-税率）*税率）-->
                <el-option key="2" :label="$t('basicData.Issn1')" :value="2" />
                <!--ISS税(n*税率)-->
                <el-option key="7" :label="$t('basicData.Issn')" :value="7" />
                <!--ICMS税-->
                <el-option key="3" :label="'ICMS' + $t('basicData.tax')" :value="3" />
                <!--超重额外费用-->
                <el-option key="4" :label="$t('basicData.ExcessWeightSurcharges')" :value="4" />
                <!-- *BRL/*KG -->
                <el-option key="5" :label="'*BRL/*KG（' + $t('basicData.roundUp') + '）'" :value="5" />
                <!-- *BRL/票 -->
                <el-option key="6" :label="'*BRL/' + $t('basicData.ticket')" :value="6" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div
      v-permit:remove="'btn:finance:basicData:feesType:add'"
      style="text-align: right;padding: 10px;border-top: 1px solid #ccc;"
    >
      <!--取消-->
      <el-button @click="saveFormData(2)">{{ $t('basicData.Cancel') }}</el-button>
      <!--保存-->
      <el-button type="primary" @click="saveFormData(1)">{{ $t('basicData.Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import { addFeeType, editFeeType } from '@/api/finance/partition';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'CheckDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        attribute: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        remark: [
          { required: false, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        businessType: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      },
      userList: [],
      param: {}
    };
  },
  created() {
    this.param = cloneDeep(this.detailData);
    switch (this.param.businessType) {
      case 0:
        this.param.businessType = [0];
        break;
      case 1:
        this.param.businessType = [1];
        break;
      case 2:
        this.param.businessType = [2];
        break;
      case 3:
        this.param.businessType = [0, 1];
        break;
      case 4:
        this.param.businessType = [0, 2];
        break;
      case 5:
        this.param.businessType = [1, 2];
        break;
      case 6:
        this.param.businessType = [0, 1, 2];
        break;
    }
  },
  methods: {
    saveFormData(type) {
      if (type === 1) {
        this.$refs['queryForm'].validate((valid) => {
          if (valid) {
            const { name, namePu, id, remark, state, businessType, specialRules } = this.param;
            const param = {
              name,
              namePu,
              id,
              remark,
              state,
              businessType,
              specialRules
            };
            param.businessType.sort((a, b) => {
              return a - b;
            });
            switch (param.businessType.join(',')) {
              case '0':
                param.businessType = '0';
                break;
              case '1':
                param.businessType = '1';
                break;
              case '2':
                param.businessType = '2';
                break;
              case '0,1':
                param.businessType = '3';
                break;
              case '0,2':
                param.businessType = '4';
                break;
              case '1,2':
                param.businessType = '5';
                break;
              case '0,1,2':
                param.businessType = '6';
                break;
            }
            // param.businessType = param.businessType.join(',') === '0,1' ? '3' : param.businessType.join(',');

            if (this.operationType === 1) {
              //  新增
              addFeeType(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            } else {
              editFeeType(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          } else {
            return false;
          }
        });
      } else {
        this.$emit('saveChange', type);
      }
    }
  }
};
</script>

<style scoped>
.formClass {
  height: calc(100vh - 138px);
  overflow-y: auto;
  padding: 20px;
}
</style>
