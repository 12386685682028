<template>
  <div class="warp" style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <div style="margin-bottom: 15px">
      <span style="font-weight: bold">{{ $t("GlobalSetObj.Total") }}: {{ total }}</span>
      <span style="margin-left: 30px; color: #409eff; cursor: pointer" @click="exportToExcel">{{
        $t("GlobalSetObj.moreDataDetail")
      }}</span>
    </div>
    <div style="margin-bottom: 10px">
      <el-button size="small" type="primary" @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
    </div>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { endcollectDataDetail, endcollectDataDetailExport } from '@/api/orderApi';

export default {
  props: {
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      },
      tableData: [],
      total: 0,
      tableHeaderList: [
        { id: 1, label: this.$t('GlobalSetObj.waybillNumber'), prop: 'waybillNumber', cloWidth: 180 },
        { id: 2, label: this.$t('GlobalSetObj.ScanNumber'), prop: 'scanOrderNumber', cloWidth: 180 },
        { id: 3, label: this.$t('newOrder.waybillStatus'), prop: 'waybillStatusName', cloWidth: 180 },
        { id: 5, label: this.$t('newOrder.LatestTime'), prop: 'lastTrackTime', cloWidth: 180 },
        { id: 6, label: this.$t('orderCenterCont.zuixinguijineir'), prop: 'lastTrackContent', cloWidth: 180 },
        { id: 4, label: this.$t('collectionCenter.lanshouT'), prop: 'collectTime', cloWidth: 180 },
        { id: 7, label: this.$t('changepwd.collectUserName'), prop: 'collectUserName', cloWidth: 180 },
        { id: 8, label: this.$t('changepwd.collectLoadTime'), prop: 'collectLoadTime', cloWidth: 180 },
        { id: 9, label: this.$t('changepwd.collectLoadUserName'), prop: 'collectLoadUserName', cloWidth: 180 }
      ]
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    getSearch() {
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          reportId: this.dataobj.reportId,
          customerId: this.dataobj.customerId,
          date: this.dataobj.date,
          isTest: this.dataobj.isTest,
          dataType: this.dataobj.dataType,
          customerIdList: this.dataobj.customerIdList,
          startTime: this.dataobj.startTime,
          endTime: this.dataobj.endTime
        }
      };
      endcollectDataDetail(data).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    exportToExcel() {
      const data = {
        reportId: this.dataobj.reportId,
        customerId: this.dataobj.customerId,
        date: this.dataobj.date,
        isTest: this.dataobj.isTest,
        dataType: this.dataobj.dataType,
        customerIdList: this.dataobj.customerIdList,
        startTime: this.dataobj.startTime,
        endTime: this.dataobj.endTime
      };
      endcollectDataDetailExport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$emit('call-parent-method', false);
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      };
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.warp >>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
