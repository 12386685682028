import request from '@/utils/request';

const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 列表分页
export const apiFaFinancialImportExcelLogPage = data => {
  return request({
    url: '/faFinancialImportExcelLog/page',
    method: 'post',
    data,
    baseURL: baseFinanceURL
  })
};
