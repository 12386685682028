import request from '@/utils/request';
const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 理赔账单

// 分页
export const apiClaimBillPage = data => {
  return request({
    url: '/claimBill/page',
    method: 'post',
    data,
    baseURL: baseFinanceURL
  })
  // .post('/fa/claimBill/page', data);
};

// 付款
export const apiClaimBillPayTodo = data => {
  return request({
    url: '/claimBill/payTodo',
    method: 'post',
    data,
    baseURL: baseFinanceURL
  })
  // .post('/fa/claimBill/payTodo', data);
};

// 账单抵扣
export const apiClaimBillDeduction = data => {
  return request({
    url: '/fa/claimBill/deduction',
    method: 'post',
    data,
    // baseURL: baseFinanceURL
  })
  // .post('/fa/claimBill/deduction', data);
};

