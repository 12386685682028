import { render, staticRenderFns } from "./goodsInfo.vue?vue&type=template&id=764c51cc&scoped=true"
import script from "./goodsInfo.vue?vue&type=script&lang=js"
export * from "./goodsInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764c51cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('764c51cc')) {
      api.createRecord('764c51cc', component.options)
    } else {
      api.reload('764c51cc', component.options)
    }
    module.hot.accept("./goodsInfo.vue?vue&type=template&id=764c51cc&scoped=true", function () {
      api.rerender('764c51cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orderCenter/logisticsOrders/components/detailBox/components/goodsInfo.vue"
export default component.exports