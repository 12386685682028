<template>
  <div class="app-container">
    <el-dialog
      :title="roleOperationPop.title"
      :visible.sync="roleOperationPop.visible"
      width="40%"
      @close="closeRoleOperation"
      @open="openRoleOperation"
    >
      <el-form
        ref="roleOperationRef"
        :model="roleForm"
        :rules="roleOperationRules"
        label-width="130px"
      >
        <el-row>
          <el-col :spam="12">
            <!-- 角色名称(中文)： -->
            <el-form-item :label="$t('roleManage.roleNameCH')" prop="roleName">
              <el-input v-model="roleForm.roleName" :disabled="isEdit" :placeholder="$t('roleManage.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :spam="12">
            <!-- 角色名称(葡语)： -->
            <el-form-item :label="$t('roleManage.roleNamePU')" prop="portugal">
              <el-input v-model="roleForm.portugal" :disabled="isEdit" :placeholder="$t('roleManage.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :spam="12">
            <!-- 备注： -->
            <el-form-item :label="$t('roleManage.Note')" prop="remark">
              <el-input v-model="roleForm.remark" :placeholder="$t('roleManage.pleaseInput')" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :spam="12">
            <!-- 选择账号登录限制 -->
            <!-- 角色类别： -->
            <el-form-item :label="$t('roleManage.roleType')">
              <el-radio v-model="roleType" :label="1">{{ $t('roleManage.default') }}</el-radio><!-- 默认 -->
              <el-radio v-model="roleType" :label="2">{{ $t('roleManage.onlySite') }}</el-radio><!-- 仅网点系统 -->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :spam="12">
            <!-- 选择权限 -->
            <el-form-item :label="$t('roleManage.selectPermissions')">
              <permission-tree
                v-if="permissionTreeShow"
                ref="permissionTreeRef"
                :permission-tree-pop="permissionTreePop"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <!--   字符权限     -->
        <el-row>
          <el-col :spam="12">
            <el-form-item :label="$t('GlobalSetObj.characterPermission')">
              <StrPermissionTree
                v-if="permissionTreeShow"
                ref="permissionTreeRefStr"
                :permission-tree-pop="permissionTreePop"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- 确认 -->
        <el-button
          icon="el-icon-search" size="small" type="primary"
          @click="submit"
        >{{
          $t('roleManage.confirm')
        }}</el-button>
        <el-button
          icon="el-icon-refresh"
          size="small"
          type="warning"
          @click="$emit('closeRoleOperationDialog')"
        >{{ $t('roleManage.cancel') }}</el-button>
        <!-- 取消 -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PermissionTree from '@/views/user/positionManage/components/permissionTree';
import StrPermissionTree from '@/views/user/positionManage/components/StrPermissionTree';
import { addRole, checkRoleParameter, editRole } from '@/api/role';

export default {
  name: 'RoleOperation',
  components: { PermissionTree, StrPermissionTree },
  props: {
    roleOperationPop: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      roleOperationRules: {
        roleName: [
          // '请填写角色名称' this.$t('roleManage.inputRoleName')
          { required: true, message: this.$t('roleManage.pleaseInput'), trigger: 'blur' },
          {
            validator: async(rule, value, callback) => {
              if (this.isEdit) {
                return callback();
              }
              // if (!) {
              try {
                const { status } = await checkRoleParameter(value);
                if (status !== 'OK') {
                  // '角色名称重复,请重新填写'
                  return callback(new Error(this.$t('roleManage.repeatReset')));
                }
                return callback();
              } catch (error) {
                return callback(new Error(this.$t('roleManage.repeatReset')));
              }

              // }
            },
            trigger: 'blur'
          },
          {
            min: 2,
            max: 30,
            // '长度在 2 到 30 个字符'
            message: this.$t('roleManage.long2to30'),
            trigger: 'change'
          }
        ],
        portugal: [
          // this.$t('roleManage.inputRoleName')
          { required: true, message: this.$t('roleManage.pleaseInput'), trigger: 'blur' }
        ]
      },
      roleForm: {
        roleName: '',
        permissionIds: '',
        portugal: '',
        roleType: '',
        remark: '',
        id: ''
      },
      roleType: 1,
      // 传递权限树数据
      permissionTreePop: {
        selectedPermissionList: {
          pc: [],
          pda: [],
          char: [],
          schedule: []
        }
      },
      // 权限树组件是否展示
      permissionTreeShow: false,
      seeStrPermission: [], // 查看客户敏感信息
      StrPermission: [] // 勾选的字符串权限
    };
  },
  computed: {
    isEdit: function() {
      return this.roleOperationPop.type === 'edit';
    }
  },
  created() {
    // console.log('LKL');
  },
  methods: {
    openRoleOperation() {
      this.StrPermission = [];
      // getPermissionStructureByField().then((res) => {
      //   if (res.status === 'OK') {
      //     this.seeStrPermission = res.data.permissionStructure;
      //     this.seeStrPermissionTreePop.seeStrPermission = this.seeStrPermission;
      //     console.log(this.seeStrPermission, 'this.seeStrPermission');
      //     console.log(this.seeStrPermissionTreePop, 'this.permissionTreePop');
      //   }
      // }).catch(err => {
      //   console.error(err);
      // });
      const { roleInfo } = this.roleOperationPop;
      if (roleInfo && this.isEdit) {
        // const arr = roleInfo.permissionIds.filter(item => item === 100458); // 过滤出查看脱敏权限
        // if (arr.length) {
        //   this.StrPermission.push('查看客户敏感信息');
        // }
        this.roleForm = { ...roleInfo };
        this.permissionTreePop.selectedPermissionList.char = this.roleForm.permissionIdsChar;
        this.permissionTreePop.selectedPermissionList.pc = this.roleForm.permissionIdsPc;
        this.permissionTreePop.selectedPermissionList.pda = this.roleForm.permissionIdsPda;
        this.permissionTreePop.selectedPermissionList.schedule = this.roleForm.permissionIdsSchedules;
        this.roleType = this.roleForm.roleType;
      }

      this.permissionTreeShow = true;
    },
    closeRoleOperation() {
      this.roleType = 1;
      const roleForm = this.roleForm;
      roleForm.permissionIds = [];
      roleForm.remark = '';
      roleForm.portugal = '';
      roleForm.roleType = 1;
      roleForm.roleName = '';
      roleForm.id = '';
      this.permissionTreePop.selectedPermissionList = [];
      this.permissionTreeShow = false;
      this.$refs.roleOperationRef.clearValidate();
    },
    submit() {
      // 校验form表单
      this.$refs.roleOperationRef.validate((valid) => {
        if (!valid) {
          return;
        }
        const selectedPermissionList = this.$refs.permissionTreeRef.getSelectedPermissionList();
        const permissionTreeRefStrList = this.$refs.permissionTreeRefStr.getSelectedPermissionList(); // 字符串权限
        if (selectedPermissionList.permissionIds && selectedPermissionList.permissionIds.length > 0) {
          const UserPermissionIds = selectedPermissionList.permissionIds.splice(0);
          const strpermissionIds = permissionTreeRefStrList.permissionIds;
          this.roleForm.permissionIds = UserPermissionIds.concat(strpermissionIds);
          this.roleForm.roleType = this.roleType;
          if (this.isEdit) {
            editRole(this.roleForm).then((response) => {
              if (response.status === 'OK') {
                this.$message.success(this.$t('roleManage.TheOperationSucceeded')); // '操作成功!'
                this.$emit('closeRoleOperationDialog');
                this.$emit('RoleTableDataRefresh');
              }
            }).catch(err => {
              console.error(err);
            });
          } else {
            addRole(this.roleForm).then((response) => {
              if (response.status === 'OK') {
                this.$message.success(this.$t('roleManage.TheOperationSucceeded')); // '操作成功!'
                this.$emit('closeRoleOperationDialog');
                this.$emit('RoleTableDataRefresh');
              }
            }).catch(err => {
              console.error(err);
            });
          }
        } else {
          this.$message.warning(this.$t('roleManage.mustChooseOne')); // '必须选择至少一项权限!'
        }
      });
    }
  }
};
</script>

<style scoped></style>
