<template>
  <!-- 搜索栏网点 -->
  <el-select
    v-model="siteId"
    :loading="loading"
    :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
    style="width: 100%;"
    :clearable="clearable"
    :multiple="multiple"
    :multiple-limit="multipleLimit"
    filterable
    remote
    :reserve-keyword="multiple"
    :remote-method="getSitePageList"
    @change="onChange"
  >
    <!--请先选择应收主体-->
    <el-option
      v-for="item in list"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
    <el-option
      v-if="list.length === 0" :value="-1" :label="$t('operatingPlatform.nullMsg')"
      disabled
    />
  </el-select>
</template>

<script>
// import { getDistributionDotList } from '@/api/logisticsOrders';
import { apiCommonSite } from '@/api/logisticsOrders';

const param = {
  pageNumber: 1,
  pageSize: 10,
  params: {
    siteStatus: undefined,
    providerId: '', // 应付主体
    name: '' // 网点名称

  }
};

export default {
  name: '',

  props: {
    // 应付主体
    providerId: {
      type: [String, Number],
      default: ''
    },

    // 默认值
    val: {
      type: [String, Number, Array],
      default: ''
    },

    // 网点状态 0未启用 1启用 undefined全部
    siteStatus: {
      type: [Number, undefined],
      default: 1
    },

    // 是否多选
    multiple: {
      type: Boolean,
      default: false
    },

    // 是否可以清空选项
    clearable: {
      type: Boolean,
      default: true
    },

    // 多选时用户最多可以选择的项目数，为 0 则不限制
    multipleLimit: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      siteId: '',
      list: [],
      loading: false
    };
  },

  watch: {
    val: {
      handler(val) {
        this.siteId = val;
      },
      immediate: true
    },

    providerId: {
      handler(val) {
        this.siteId = '';
        this.list = [];
        this.getSitePageList();
      },

      immediate: true
    }
  },

  methods: {
    // 获取数据
    getSitePageList(name = '') {
      const { providerId, siteStatus } = this;
      param.params.providerId = providerId; // 应付主体ID
      param.params.name = name; // 网点名称
      param.params.siteStatus = siteStatus;

      this.loading = true;
      apiCommonSite(param, false).then(res => {
        if (res.code === 200) {
          const { records } = res.data;
          this.list = (records || []).map(item => ({
            value: item.id,
            label: item.name
          }))
        } else {
          this.list = [];

        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },

    onChange() {
      this.$emit('change', this.siteId);
    },

    // 重置数据及列表
    reset() {
      // this.siteId = '';
      // this.list = [];
      setTimeout(() => {
        this.getSitePageList('');
      }, 500);
      // this.getSitePageList('');
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
