var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "calc(100vh - 150px)",
        overflow: "auto",
        padding: "15px",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "queryForm",
              staticClass: "queryFormClass",
              attrs: {
                "label-width": "auto",
                "label-position": "top",
                size: "mini",
                model: _vm.queryForm,
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("GlobalSetObj.state") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.stateName,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryForm.params, "stateName", $$v)
                              },
                              expression: "queryForm.params.stateName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("orderCenterCont.city") } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.queryForm.params.marketName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.queryForm.params,
                                  "marketName",
                                  $$v
                                )
                              },
                              expression: "queryForm.params.marketName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { type: "flex", justify: "start" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-search",
                          size: "mini",
                        },
                        on: { click: _vm.getDataSearch },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.Search")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-refresh", size: "mini" },
                        on: { click: _vm.queryFormReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("basicData.reset")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("el-divider"),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-bottom": "10px" } },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:finance:basicData:districtManagement:add",
                  expression: "'btn:finance:basicData:districtManagement:add'",
                  arg: "remove",
                },
              ],
              attrs: { size: "mini", type: "primary", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.handlerOperation(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("basicData.Add")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:finance:basicData:districtManagement:import",
                  expression:
                    "'btn:finance:basicData:districtManagement:import'",
                  arg: "remove",
                },
              ],
              attrs: {
                size: "mini",
                type: "primary",
                icon: "el-icon-download",
              },
              on: {
                click: function ($event) {
                  return _vm.handlerOperation(3)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("CenterForSorting.Import")) + " ")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit:remove",
                  value: "btn:finance:basicData:districtManagement:export",
                  expression:
                    "'btn:finance:basicData:districtManagement:export'",
                  arg: "remove",
                },
              ],
              attrs: { size: "mini", type: "primary", icon: "el-icon-upload2" },
              on: {
                click: function ($event) {
                  return _vm.handlerOperation(4)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("CenterForSorting.export")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              ref: "elTable",
              staticClass: "table-height",
              attrs: {
                data: _vm.tableData,
                border: "",
                height: _vm.tableHeightRow.height,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("operationCenter.ind"),
                  width: "80px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              (_vm.queryForm.pageNumber - 1) *
                                _vm.queryForm.pageSize +
                                scope.$index +
                                1
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("GlobalSetObj.state"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.level === 1
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#409eff",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerOperation(2, scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.name))]
                              ),
                            ])
                          : _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#409eff",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlerOperation(
                                        2,
                                        scope.row.parentNode
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.parentNode.name) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "twoCode",
                  label: _vm.$t("CenterForSorting.StateTwoCode"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.level === 1
                          ? _c("div", [
                              _vm._v(" " + _vm._s(scope.row.twoCode) + " "),
                            ])
                          : _c("div", [
                              _vm._v(_vm._s(scope.row.parentNode.twoCode)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: _vm.$t("orderCenterCont.city"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.level === 2
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#409eff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlerOperation(2, scope.row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(scope.row.name) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "zipCode",
                  label: _vm.$t("GlobalSetObj.zipCode"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("div", [_vm._v(_vm._s(scope.row.zipCode))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "issTaxation",
                  label: "ISS" + _vm.$t("GlobalSetObj.rate") + "(%)",
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.issTaxation))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUserStr",
                  label: _vm.$t("basicData.CreatePeople"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$t("basicData.CreationTime"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateUserStr",
                  label: _vm.$t("basicData.LastModifier"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updateTime",
                  label: _vm.$t("basicData.LastModified"),
                  align: "center",
                  "min-width": "160",
                  "show-overflow-tooltip": true,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                staticStyle: { margin: "10px 0" },
                attrs: {
                  layout: "total, sizes, prev, pager, next, jumper",
                  "current-page": _vm.queryForm.pageNumber,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.queryForm.pageSize,
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageNumber", $event)
                  },
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.queryForm, "pageSize", $event)
                  },
                  "current-change": _vm.getCurrencyTableData,
                  "size-change": _vm.getSizeTableData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("basicData.Add"),
            "append-to-body": true,
            visible: _vm.showDetail,
            direction: "ltr",
            size: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDetail = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 25px" } },
            [
              _c(
                "el-form",
                {
                  key: _vm.showDetail,
                  ref: "formParamData",
                  staticClass: "queryFormClass",
                  attrs: {
                    "label-width": "auto",
                    "label-position": "top",
                    size: "small",
                    model: _vm.formParam,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("basicData.Parent") } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("orderDetails.pleaseChoose"),
                            disabled: _vm.operationType === 2,
                          },
                          model: {
                            value: _vm.formParam.parentId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formParam, "parentId", $$v)
                            },
                            expression: "formParam.parentId",
                          },
                        },
                        _vm._l(_vm.parentList, function (item, ind) {
                          return _c("el-option", {
                            key: ind,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("GlobalSetObj.Name"),
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("basicData.CannotBeEmpty"),
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: _vm.$t("orderDetails.pleaseInput"),
                          clearable: "",
                        },
                        model: {
                          value: _vm.formParam.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formParam, "name", $$v)
                          },
                          expression: "formParam.name",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.formParam.parentId
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.twoCode"),
                            prop: "twoCode",
                            rules: [
                              {
                                required: true,
                                validator: _vm.englishLetter1,
                                length: 2,
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                              clearable: "",
                            },
                            model: {
                              value: _vm.formParam.twoCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formParam, "twoCode", $$v)
                              },
                              expression: "formParam.twoCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formParam.parentId
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("basicData.zipCode"),
                            prop: "zipCode",
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("basicData.CannotBeEmpty"),
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 6,
                              placeholder: _vm.$t("basicData.ZipCodeExample"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.zipcodeCollation(
                                  _vm.formParam.zipCode
                                )
                              },
                            },
                            model: {
                              value: _vm.formParam.zipCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formParam, "zipCode", $$v)
                              },
                              expression: "formParam.zipCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formParam.parentId
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "ISS" + _vm.$t("GlobalSetObj.rate") + "(%)",
                            prop: "issTaxation",
                            rules: [
                              {
                                required: true,
                                suffix: 2,
                                negative: "",
                                validator: _vm.priceType,
                                trigger: ["blur", "change"],
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("orderDetails.pleaseInput"),
                            },
                            model: {
                              value: _vm.formParam.issTaxation,
                              callback: function ($$v) {
                                _vm.$set(_vm.formParam, "issTaxation", $$v)
                              },
                              expression: "formParam.issTaxation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("el-form-item", [
                    _vm.operationType === 1
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit:remove",
                                value:
                                  "btn:finance:basicData:districtManagement:add",
                                expression:
                                  "'btn:finance:basicData:districtManagement:add'",
                                arg: "remove",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.formSave()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showDetail = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteData()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.operationType === 2
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit:remove",
                                value:
                                  "btn:finance:basicData:districtManagement:edit",
                                expression:
                                  "'btn:finance:basicData:districtManagement:edit'",
                                arg: "remove",
                              },
                            ],
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.formSave()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Save")))]
                            ),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showDetail = false
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Cancel")))]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteData()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicData.Delete")))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("basicData.ImportAddRegion"),
            visible: _vm.showImportAddRegion,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showImportAddRegion = $event
            },
          },
        },
        [_c("importArea", { on: { importChange: _vm.importChange } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }