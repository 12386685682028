<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px;">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        :label-width="$i18n.locale=='zh'?'120px':'0px'"
        :label-position="'top'"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--分区方案名称-->
            <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
              <el-input v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--备注内容-->
            <el-form-item :label="$t('basicData.RemarkContent')" prop="name">
              <el-input v-model="queryForm.params.remark" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="item in BUSINESS_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="queryFormReset"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <el-card>
      <!--添加-->
      <div style="margin-bottom: 10px;">
        <el-button
          v-permit:remove="'btn:finance:basicData:payable:partition-add'"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handlerOperation(1)"
        >{{ $t('basicData.Add') }}
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--分区方案名称-->
        <el-table-column :label="$t('basicData.PartitionSchemeName')" prop="name" width="160">
          <template slot-scope="scope">
            <div style="color: #409eff;cursor: pointer;" @click="handlerOperation(2, scope.row)">{{ scope.row.name }}</div>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column
          prop="businessType"
          :label="$t('collectionCenter.businessT')"
          align="center"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <!--揽收-->
              <span v-if="scope.row.businessType === 2">{{ $t('collectionCenter.lanshou') }}</span>
              <!--派送-->
              <span v-else-if="scope.row.businessType === 3">{{ $t('collectionCenter.dispatch') }}</span>
              <!--转运-->
              <span v-else-if="scope.row.businessType === 1">{{ $t('collectionCenter.turnTransport') }}</span>
            </div>
          </template>
        </el-table-column>
        <!--分区状态-->
        <el-table-column :label="$t('basicData.PartitionStatus')" width="80">
          <template slot-scope="scope">
            <!--启用-->
            <el-tag v-if="scope.row.state === 1">{{ $t('basicData.enable') }}</el-tag>
            <!--禁用-->
            <el-tag v-else-if="scope.row.state === 0" type="danger">{{ $t('basicData.disable') }}</el-tag>
            <!--未定义-->
            <el-tag v-else type="info">{{ $t('basicData.Undefined') }}</el-tag>
          </template>
        </el-table-column>
        <!--备注内容-->
        <el-table-column :label="$t('basicData.RemarkContent')" prop="remark" />
        <!--创建人-->
        <el-table-column :label="$t('basicData.CreatePeople')" prop="createUserStr" width="80" />
        <!--创建时间-->
        <el-table-column :label="$t('basicData.CreationTime')" prop="createTime" width="160" />
        <!--最后修改人-->
        <el-table-column :label="$t('basicData.LastModifier')" prop="updateUserStr" width="95" />
        <!--最后修改时间-->
        <el-table-column :label="$t('basicData.LastModified')" prop="updateTime" width="160" />
        <!--操作-->
        <el-table-column :label="$t('basicData.operate')" fixed="right" width="160">
          <template slot-scope="scope">
            <div>
              <!--确定禁用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 1"
                v-permit:remove="'btn:finance:basicData:payable:partition-check'"
                :title="$t('basicData.AreYouSureDisabled')"
                @confirm="asyncPartitionEdit(scope.row, 0)"
              >
                <!--禁用-->
                <el-button
                  slot="reference" class="ml" type="warning"
                  size="mini"
                >{{ $t('basicData.disable') }}</el-button>
              </el-popconfirm>
              <!--确定启用吗？-->
              <el-popconfirm
                v-if="scope.row.state === 0"
                v-permit:remove="'btn:finance:basicData:payable:partition-check'"
                :title="$t('basicData.AreYouSureEnabled')"
                @confirm="asyncPartitionEdit(scope.row, 1)"
              >
                <!--启用-->
                <el-button
                  slot="reference" class="ml" type="success"
                  size="mini"
                >{{ $t('basicData.enable') }}</el-button>
              </el-popconfirm>
              <!--确定删除吗？-->
              <el-popconfirm
                v-permit:remove="'btn:finance:basicData:payable:partition-del'"
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="asyncPartitionEdit(scope.row, 2)"
              >
                <!--删除-->
                <el-button
                  slot="reference" class="ml" type="danger"
                  size="mini"
                >{{ $t('basicData.Delete') }}</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getData"
          @size-change="getData"
        />
      </div>
    </el-card>

    <!-- 分区报价 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :with-header="false"
      direction="ltr"
      size="80%"
    >
      <details-cmp
        :key="editKey"
        :type-index="typeIndex"
        :edit-data="editData"
        :all-state="allState"
        :all-city="allCity"
        style="height: 100%;"
        @updatePage="updatePage"
      />
    </el-drawer>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { getUserName } from '@/utils/asyncTools';
import {
  apiPartitionPage,
  apiPartitionChangeState,
  apiPartitionDel
} from '@/api/finance/partition';
import { apiAreaPage } from '@/api/finance/basicData';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE } from '@/views/finance/enums.js';

export default {
  name: 'PayablePartition',
  components: {
    'details-cmp': () => import('./cmp/DetailsCmp.vue')
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          attribute: 2,
          name: '',
          remark: '',
          businessType: null
        }
      },
      tableData: [],
      total: 0,
      // 弹窗
      drawerObj: {
        visible: false
      },
      editData: {
        name: '',
        remark: '',
        id: '',
        tableData: []
      },
      typeIndex: 1,
      editKey: new Date().getTime(),
      userIdAndNameObj: null,
      allState: [],
      allCity: [],
      BUSINESS_TYPE, // 业务类型
    };
  },
  // activated() {
  //   if (this.$route.params.partitionName) {
  //     this.queryForm.params.name = this.$route.params.partitionName;
  //     this.getData();
  //     setTimeout(() => {
  //       this.handlerOperation(2, this.tableData[0]);
  //     }, 1500);
  //   }
  // },
  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getData();
    this.getAllState({ level: 1, parentId: '' });
    this.getAllState({ level: 2, parentId: '' });
  },

  methods: {
    // 获取所有的州
    getAllState(row) {
      const param = {
        pageNumber: 1,
        pageSize: 99999,
        params: {
          level: row.level,
          parentId: row.parentId
        }
      };
      apiAreaPage(param).then(res => {
        if (res.code === 200) {
          if (row.level === 1) {
            this.allState = res.data.records;
          } else {
            this.allCity = res.data.records;
          }
        }
      });
    },
    // 获取应付分区列表数据
    getData() {
      apiPartitionPage(this.queryForm).then(res => {
        const { status } = res;
        if (status !== 'OK') {
          return;
        }
        const { data } = res;
        this.tableData = data.records.map(item => {
          const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
          const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
          return {
            ...item,
            updateUserStr,
            createUserStr
          };
        });
        this.total = data.total;
      }).catch(err => {
        console.error(err);
      });
    },
    // 搜索
    getDataSearch() {
      this.queryForm.pageNumber = 1;
      this.getData();
    },
    // 重置
    queryFormReset() {
      this.queryForm.params.name = '';
      this.queryForm.params.remark = '';
    },

    // 启用禁用 分区
    asyncPartitionEdit(row, type) {
      const { id } = row;
      if (type === 2) {
        apiPartitionDel(row.id).then(res => {
          const { status } = res;
          if (status !== 'OK') {
            return;
          }
          this.$message({
            message: this.$i18n.t('basicData.TheOperationSucceeded'), // '操作成功',
            type: 'success'
          });
          this.getData();
        });
      } else {
        const obj = {
          id,
          state: type
        };

        apiPartitionChangeState(obj).then(res => {
          const { status } = res;
          if (status !== 'OK') {
            return;
          }
          this.$message({
            message: this.$i18n.t('basicData.TheOperationSucceeded'), // '操作成功',
            type: 'success'
          });
          this.getData();
        }).catch(err => {
          console.error(err);
        });
      }
    },

    // 添加 编辑 查看
    handlerOperation(typeIndex, row) {
      this.typeIndex = typeIndex;
      if (row) {
        // 编辑
        this.editData = {
          name: row.name,
          remark: row.remark,
          id: row.id
        };
      } else {
        this.editData = {
          name: '',
          remark: '',
          id: ''
        };
      }
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },

    // 关闭弹窗
    updatePage(isBool) {
      this.drawerObj.visible = false;
      isBool && this.getData();
    }

  }
};
</script>

<style scoped>
.ml {
  margin-left: 10px;
}
</style>

