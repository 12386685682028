<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">

          <el-col :span="7">
            <!--应付主体-->
            <el-form-item :label="$t('basicData.PayablePrincipal')" prop="providerName">
              <!-- <el-input v-model="queryForm.params.providerName" :placeholder="$t('orderDetails.pleaseInput')" clearable /> -->
              <ProviderV2Cmp
                style="width: 100%;"
                :val="queryForm.params.providerId"
                @change="val => queryForm.params.providerId = val"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--应付网点-->
            <el-form-item :label="$t('basicData.PayableOutlets')" prop="siteName">
              <QuerySiteV2Cmp
                style="width: 100%;"
                :val="queryForm.params.ids"
                :provider-id="queryForm.params.providerId"
                :multiple="true"
                :multiple-limit="20"
                @change="val => queryForm.params.ids = val"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!--网点经营模式-->
            <el-form-item :label="$t('basicData.NetworkBusinessModel')" prop="businessModel">
              <el-select v-model="queryForm.params.businessModel" style="width: 100%;" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <!--加盟-->
                <el-option key="1" :label="$t('basicData.join')" :value="2" />
                <!--自营-->
                <el-option key="2" :label="$t('basicData.SelfEmployed')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch(1)"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="getDataSearch(2)"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <div style="margin-bottom: 10px;text-align: right;">
        <!--导出账户信息-->
        <el-button
          type="primary"
          size="mini"
          @click="exportAccountInformation(1)"
        >{{ $t('basicData.ExportAccountInformation') }}
        </el-button>
        <!--导出账户流水-->
        <el-button
          type="primary"
          size="mini"
          @click="exportAccountInformation(2)"
        >{{ $t('basicData.ExportAccountRollover') }}
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table-height"
        :height="tableHeightRow.height"
      >
        <!--序号-->
        <el-table-column
          :label="$t('basicData.serialNumber')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--业务类型-->
        <el-table-column
          v-for="(item, ind) in accountSection"
          :key="ind"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'name'">
              <el-popover
                placement="top-end"
                popper-class="copy"
                trigger="hover"
              >
                <!--复制-->
                <el-button type="text" @click="copy(scope.row.name)">{{ $t('orderDetails.copy') }}</el-button>
                <span slot="reference" style="color: #409eff;cursor: pointer;" @click="handlerOperation(scope.row)">{{ scope.row.name }}</span>
              </el-popover>
            </div>
            <!--网点业务类型-->
            <div v-else-if="item.prop === 'businessType'">
              <div v-if="scope.row.businessType">
                <span v-for="(key, ind) in scope.row.businessType.split(',')" :key="key">
                  <!--揽收-->
                  <span v-if="key === '2'">{{ $t('collectionCenter.lanshou') }}</span>
                  <!--派送-->
                  <span v-else-if="key === '3'">{{ $t('collectionCenter.dispatch') }}</span>
                  <!--转运-->
                  <span v-else-if="key === '1'">{{ $t('collectionCenter.turnTransport') }}</span>
                  <span v-if="ind !== scope.row.businessType.split(',').length - 1">，</span>
                </span>
              </div>
            </div>
            <!--网点类型-->
            <div v-else-if="item.prop === 'siteType'">
              <!--安骏小屋-->
              <span v-if="scope.row.siteType === 1">{{ $t('collectionCenter.hut') }}</span>
              <!--网点-->
              <span v-else-if="scope.row.siteType === 2">{{ $t('collectionCenter.dot') }}</span>
              <!--为转运中心-->
              <span v-else-if="scope.row.siteType === 3">{{ $t('collectionCenter.dot') }}</span>
            </div>
            <!--网点经营模式-->
            <div v-else-if="item.prop === 'businessModel'">
              <!--加盟-->
              <span v-if="scope.row.businessModel === 2">{{ $t('basicData.join') }}</span>
              <!--直营-->
              <span v-else-if="scope.row.businessModel === 1">{{ $t('basicData.SelfEmployed') }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row[item.prop] }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getCurrentData"
          @size-change="getSizeData"
        />
      </div>
    </el-card>
    <!--账户详情-->
    <el-drawer
      :append-to-body="true"
      :title="$t('basicData.AccountDetails')"
      :visible.sync="showAccountDetails"
      custom-class="drawerClass"
      direction="ltr"
      size="90%"
    >
      <check-detail :key="detailKey" :detail-data="detailData" @clearDetail="clearDetail" />
    </el-drawer>
  </div>
</template>

<script>
import { apiGetSiteAccountPage } from '@/api/finance/contasDeClientes';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import { copyData } from '@/utils/copyData';
import { apiExportMeetSiteAccountInfo, apiExportOfBillFlow } from '@/api/finance/basicData';
import checkDetail from './cmp/checkDetail';
import { cloneDeep } from 'lodash-es';
import QuerySiteV2Cmp from '@/views/finance/cmp/Query/QuerySiteV2Cmp.vue';
import ProviderV2Cmp from '@/views/finance/cmp/Query/ProviderV2Cmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'AccountsPayable',
  components: {
    checkDetail,
    QuerySiteV2Cmp,
    ProviderV2Cmp
  },
  mixins: [estimateTableHeight],
  data() {
    return {
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          ids: [],
          providerId: '',
          // siteName: '',
          // providerName: '',
          businessModel: null
        }
      },
      tableData: [],
      total: 0,
      accountSection: [
        // 应付网点
        { label: this.$t('basicData.PayableOutlets'), prop: 'name', width: 160, align: 'left' },
        // 应付合计
        { label: this.$t('receivablePayable.TotalAmountToBePaid'), prop: 'payableUnsettledAmount', width: 160, align: 'left' },
        // 已付合计
        { label: this.$t('receivablePayable.TotalAmountPaid'), prop: 'payedAmount', width: 160, align: 'left' },
        // 未付合计
        { label: this.$t('receivablePayable.TotalAmountOutstanding'), prop: 'unpavedAmount', width: 160, align: 'left' },
        // 网点业务类型
        { label: this.$t('basicData.TheTypeBranchBusiness'), prop: 'businessType', width: 160, align: 'center' },
        // 网点类型
        { label: this.$t('basicData.TheTypeOutlet'), prop: 'siteType', width: 160, align: 'center' },
        // 网点经营模式
        { label: this.$t('basicData.NetworkBusinessModel'), prop: 'businessModel', width: 160, align: 'center' },
        // 应付主体
        { label: this.$t('basicData.PayablePrincipal'), prop: 'providerBody', width: 160, align: 'left' },
        // 应付主体简称
        { label: this.$t('basicData.AbbreviationPayableEntity'), prop: 'providerName', width: 160, align: 'left' }
      ],
      showAccountDetails: false,
      detailData: null,
      detailKey: new Date().getTime()
    };
  },

  created() {
    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTableData();
  },
  methods: {
    // 关闭弹窗详情
    clearDetail() {
      this.showAccountDetails = false;
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 应付账户列表查询
    getTableData() {
      apiGetSiteAccountPage(this.queryForm).then(res => {
        // if (res.code === 200) {
        const { records, total } = res.data;
        this.tableData = (records || []).map(item => {
          const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
          const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
          return Object.freeze({
            ...item,
            updateUserStr,
            createUserStr
          });
        });
        this.total = total;
        // }
      });
    },
    // 导出账户信息、 导出账户流水
    exportAccountInformation(type) {
      if (type === 1) {
        apiExportMeetSiteAccountInfo(this.queryForm.params).then(res => {
          if (res.code === 200) {
            goExportList(this);
          }
        });
      } else {
        const param = cloneDeep(this.queryForm);
        param.params.isSiteDetailsRequest = false;
        apiExportOfBillFlow(param.params).then(res => {
          if (res.code === 200) {
            goExportList(this);
          }
        });
      }
    },
    // 查询、重置
    getDataSearch(type) {
      if (type === 1) {
        this.getTableData();
      } else {
        this.queryForm.pageSize = 10;
        this.queryForm.pageNumber = 1;
        this.queryForm.params.providerId = '';
        this.queryForm.params.ids = [];
        this.queryForm.params.businessModel = null;
        // this.queryForm = {
        //   pageSize: 10,
        //   pageNumber: 1,
        //   params: {
        //     siteName: '',
        //     providerName: '',
        //     businessModel: null
        //   }
        // };
      }
    },
    // 页码切换查询
    getCurrentData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量切换
    getSizeData(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNumber = 1;
      this.getTableData();
    },
    // 查看详情
    handlerOperation(row) {
      this.detailData = row;
      this.showAccountDetails = true;
      this.detailKey = new Date().getTime();
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}
</style>
