<template><!-- 派送AJD重算 -->
  <el-dialog
    :title="$t('receivablePayable.SendAJDRecalculation')"
    :visible.sync="isShow"
    width="600px"
    @close="onClose"
  >
    <el-form
      ref="ajdRuleForm"
      :model="ajdRuleForm"
      :rules="adjRules"
      class="demo-ruleForm"
      label-position="top"
      size="small"
    >
      <!--周期-->
      <el-form-item :label="$t('receivablePayable.cycle')+'：'" prop="cycle">
        <el-select
          v-model="ajdRuleForm.cycle"
          :placeholder="$t('operationCenter.PleaseSelect')"
          clearable
          filterable
          size="small"
          style="width: 100%;"
        >
          <el-option
            v-for="(item,index) in periodList"
            :key="item.label"
            :label="item.label"
            :value="index"
          />
        </el-select>
      </el-form-item>
      <!--派送员-->
      <el-form-item :label="$t('orderCenterCont.deliveryPerson')+'：'" prop="dispatcherList">
        <el-select
          v-model="ajdRuleForm.dispatcherList"
          :multiple-limit="10"
          :placeholder="$t('operationCenter.Only10AreDisplayed')"
          :remote-method="getDeliveryPage"
          clearable
          filterable
          multiple
          remote
          :loading="loading"
          size="small"
          style="width: 100%;"
        >
          <el-option
            v-for="item in deliveryList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button size="small" type="primary" @click="saveReCalculate">{{ $t('basicData.determine') }}</el-button>
      <el-button size="small" @click="isShow = false">{{ $t('basicData.Cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { collectDriverQueryCourier } from '@/api/logisticsOrders';
import { filterAndFormatData, generatePeriods } from './utils';

const QUERY = {
  pageNumber: 1,
  pageSize: 10,
  params: {
    deliveryManName: ''
  }
};

export default {
  name: '',

  data() {
    return {
      isShow: false,
      loading: false, // 派送人加载
      periodList: [], // 周期
      deliveryList: [], // 派送员下拉
      ajdRuleForm: {
        cycle: '', // 周期
        dispatcherList: [] // 派送员
      },

      adjRules: {
        cycle: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur' }
        ],
        dispatcherList: [
          { required: true, message: this.$t('basicData.CannotBeEmpty'), trigger: 'blur' }
        ]
      }
    };
  },

  created() {
    const obj = {
      oneTitle: this.$t('receivablePayable.cycle'),
      twoTitle: this.$t('receivablePayable.cycle'),
      generationCycle2Date: 2, // 2号生成上个月周期2
      generationCycle1Date: 17 // 17号生成本月周期1
    };

    this.periodList = generatePeriods(8, obj).reverse();
  },

  methods: {
    // 打开
    open() {
      this.isShow = true;
      this.ajdRuleForm.cycle = '';
      this.ajdRuleForm.dispatcherList = [];

      this.getDeliveryPage();
    },

    /**
     * @description 关闭
     * @param {boolean} isBool 是否向上传递消息
     * @param {object} query 重算传递的参数
     */
    onClose(isBool, query) {
      this.isShow = false;
      if (isBool) {
        this.$emit('submit', query);
      }
    },

    /**
     * @description 派件AJD重算保存
     */
    saveReCalculate() {
      this.$refs.ajdRuleForm.validate(valid => {
        if (!valid) { return; }
        const { cycle, dispatcherList: list } = this.ajdRuleForm;
        const { years, period } = this.periodList[cycle];
        const dispatcherList = filterAndFormatData(list);

        const query = {
          pageNumber: 1,
          pageSize: 10,
          params: {
            businessType: 3, // 业务类型3为派送
            operationMode: 0, // 运营模式0为 AJD
            payableType: 3, // 账单类型3为 AJD
            reCalculatePriceSource: 4,
            dispatcherList,
            years,
            cycle: period
          }
        };
        setTimeout(() => {
          this.onClose(true, query);
        }, 100);
      });
    },

    /**
     * 获取派送员
     * @param {string} name
     */
    getDeliveryPage(name = '') {
      if (name && (typeof name === 'string')) {
        QUERY.params.deliveryManName = name;
      }
      this.deliveryList = [];
      this.loading = true;
      collectDriverQueryCourier(QUERY, false).then(res => {
        if (res.code === 200) {
          // this.deliveryList = res.data || [];
          this.deliveryList = (res.data || []).map(itme => {
            const { deliveryManName, expressId, supplierId } = itme;

            const value = expressId ? '1:' + deliveryManName + ':' + expressId : '2:' + deliveryManName + ':' + supplierId;
            const obj = {
              label: deliveryManName,
              value
            };
            return Object.freeze(obj);
          });
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    }
  }

};
</script>

<style lang="scss" scoped>
</style>
