<template>
  <div class="app-container">
    <el-form :inline="false" label-position="top" size="small">
      <el-row :gutter="20">
        <el-col :span="7">
          <!--装车时间-->
          <el-form-item :label="$t('GlobalSetObj.LoadCarTime')">
            <el-date-picker
              v-model="timeValue"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']"
              :format="$i18n.locale=='zh'?zhDate:ptDate"
              :placeholder="$t('newOrder.SelectDatetime')"
              range-separator="~"
              style="width:100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </el-col>
        <!--  车牌号 -->
        <el-col :span="4">
          <el-form-item prop="selectType">
            <template slot="label">
              <div class="selectLabel">
                <el-select
                  v-model="queryForm.params.queryType"
                  :style="IsWidth"
                  @change="getVehicleTagNumber01"
                >
                  <el-option key="1" :label="$t('GlobalSetObj.licensePlateNumber')" :value="1" />
                  <el-option key="2" :label="$t('GlobalSetObj.NIV')" :value="2" />
                </el-select>
              </div>
            </template>
            <el-select
              v-model="queryForm.params.numberList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in nivNumberList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!-- 装车人   -->
          <el-form-item :label="$t('GlobalSetObj.Carregador')">
            <el-select
              v-model.trim="queryForm.params.loadUserIdList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in userList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <!--   装车网点   -->
          <el-form-item :label="$t('customerManagements.loadSiteIdList')">
            <el-select
              v-model="queryForm.params.loadSiteIdList"
              :placeholder="$t('GlobalSetObj.pleaseChoose')"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in siteList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="5">
          <!--   下一站   -->
          <el-form-item :label="$t('GlobalSetObj.nextStation')">
            <el-select
              v-model="queryForm.params.nextSiteIdList"
              clearable
              filterable
              multiple
              remote
              reserve-keyword
              style="width: 100%"
            >
              <el-option
                v-for="(item,index) in siteList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <div style="margin-top: 27px;">
            <!--搜索-->
            <el-button
              icon="el-icon-search" size="small" type="primary"
              @click="enterSearch"
            >{{
              $t('newOrder.search')
            }}
            </el-button>
            <el-button
              icon="el-icon-upload2" size="small" style="margin-bottom: 10px"
              @click="allExportOrder"
            >{{
              $t('newOrder.export')
            }}
            </el-button>
            <el-button
              v-permit:remove="'btn:deliveryLoading:airExportOrder'" size="small" style="margin-bottom: 10px"
              @click="airExportOrder"
            >{{
              $t('GlobalSetObj.exportDIR')
            }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh-left" size="small" type="info"
              @click="resetForm"
            >{{
              $t('newOrder.reset')
            }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')"
        align="center"
        type="index"
        width="80"
      />
      <el-table-column
        v-for="item in tableHeaderList"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'sumIsUnload'">
            <div v-if="scope.row['sumIsUnload'] === 1">{{ $t('GlobalSetObj.yes') }}</div>
            <div v-else style="color: #f56c6c;">{{ $t('GlobalSetObj.No') }}</div>
          </div>
          <div
            v-else
            :class="strARR.includes(item.prop) && scope.row[item.prop] !== '0' ? 'WaybillNumber' :''"
            @click="seeDetial01(scope.row,scope.row[item.prop],item.numberType)"
          >{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="item in tableHeaderList1"
        :key="item.id"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div
            :class="strARR.includes(item.prop) && scope.row[item.prop] !== '0' ? 'WaybillNumber' :''"
            @click="seeDetial(scope.row,scope.row[item.prop],item.numberType)"
          >{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('GlobalSetObj.operate')"
        min-width="150"
        prop="varianceNumber"
      >
        <template slot-scope="scope">
          <div class="WaybillNumber" @click="seeDetialImage(scope.row)">{{ $t('navbar.seeImage') }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!--  分页  -->
    <el-pagination
      :current-page="queryForm.number"
      :page-size="queryForm.pageSize"
      :page-sizes="[10, 20, 50, 100]"
      :total="queryForm.total"
      background
      layout="total, sizes, prev, pager, next, jumper"
      style="margin: 10px 0;float: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- 袋号明细 -->
    <el-drawer
      :title="$t('GlobalSetObj.BagNumbeDetail')"
      :visible.sync="dialogVisible01"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <detail01
        v-if="dialogVisible01"
        ref="votesDetails"
        :dataobj="dataObj"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
    <!-- 包裹明细 -->
    <el-drawer
      :title="$t('customerManagements.packageDetail')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <packageDetail
        v-if="dialogVisible"
        ref="votesDetails"
        :dataobj="dataObj"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
    <!-- 查看图片 -->
    <el-drawer
      :title="$t('navbar.loadImage')"
      :visible.sync="photoSeeRefVisible"
      center
      direction="ltr"
      size="900px"
      @close="closeDrawer"
    >
      <photoSee v-if="photoSeeRefVisible === true " ref="photoSeeRef" :dataobj="dataObj" />
    </el-drawer>
  </div>
</template>

<script>
import {
  loadUnloadSumExportAll,
  loadUnloadSumpageQuery,
  loadUnloadSumuserAndSite,
  tfkDirExport,
  vehicleTagNumber
} from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'DeliveryLoading',
  components: {
    'detail01': () => import('./component/detail01'),
    'packageDetail': () => import('./component/packageDetail'),
    'photoSee': () => import('./component/photoSee')
  },
  data() {
    return {
      // 是否格式化成巴西时间
      isTormat: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      timeValue: [],
      queryForm: {
        pageSize: 10,
        number: 1,
        total: 0,
        params: {
          numberList: [],
          queryType: 1,
          loadSiteIdList: [], // 装车网点
          nextSiteIdList: [], // 下一站
          loadUserIdList: [] // 装车人
        }
      },
      siteList: [], // 网点
      tableData: [],
      userList: [], // 用户列表
      nivNumberList: [],
      dialogVisible: false, // 详情弹窗
      dataObj: {},
      dialogVisible01: false,
      photoSeeRefVisible: false,
      tableHeaderList: [
        { label: this.$t('GlobalSetObj.LoadCarTime'), prop: 'loadTime', cloWidth: 130 },
        { label: this.$t('GlobalSetObj.licensePlateNumber'), prop: 'vehicleNumber', cloWidth: 150 },
        { label: this.$t('navbar.vehicleDriver'), prop: 'vehicleDriver', cloWidth: 150 },
        { label: this.$t('GlobalSetObj.NIV'), prop: 'vehicleTagNumber', cloWidth: 110 },
        { label: this.$t('GlobalSetObj.Carregador'), prop: 'loadUserName', cloWidth: 150 },
        { label: this.$t('customerManagements.loadSiteIdList'), prop: 'loadSiteName', cloWidth: 120 },
        { label: this.$t('GlobalSetObj.nextStation'), prop: 'nextSiteName', cloWidth: 160 },
        { label: this.$t('GlobalSetObj.sealNumber'), prop: 'sealNumber', cloWidth: 150 },
        { label: this.$t('GlobalSetObj.sealVehicleTime'), prop: 'sealVehicleTime', cloWidth: 160 },
        { label: this.$t('GlobalSetObj.unsealVehicleTime'), prop: 'unsealVehicleTime', cloWidth: 160 },
        { label: this.$t('navbar.sumIsUnload'), prop: 'sumIsUnload', cloWidth: 160 },
        { label: this.$t('customerManagements.loadBags'), prop: 'loadBagNumber', cloWidth: 160, numberType: 1 },
        { label: this.$t('navbar.unLoadBagNumber'), prop: 'unLoadBagNumber', cloWidth: 160, numberType: 2 },
        { label: this.$t('navbar.errorUnLoadBagNumber'), prop: 'errorUnLoadBagNumber', cloWidth: 160, numberType: 3 },
        { label: this.$t('navbar.notUnLoadBagNumber'), prop: 'notUnLoadBagNumber', cloWidth: 160, numberType: 4 }
      ],
      tableHeaderList1: [
        {
          label: this.$t('customerManagements.loadPackageNumber'),
          prop: 'loadPackageNumber',
          cloWidth: 160,
          numberType: 1
        },
        {
          label: this.$t('customerManagements.unLoadPackageNumber'),
          prop: 'unLoadPackageNumber',
          cloWidth: 160,
          numberType: 2
        },
        {
          label: this.$t('navbar.errorUnLoadPackageNumber'),
          prop: 'errorUnLoadPackageNumber',
          cloWidth: 160,
          numberType: 3
        },
        { label: this.$t('navbar.notUnLoadPackageNumber'), prop: 'notUnLoadPackageNumber', cloWidth: 160, numberType: 4 }
      ],
      strARR: ['loadBagNumber', 'unLoadBagNumber', 'errorUnLoadBagNumber', 'notUnLoadBagNumber',
        'loadPackageNumber', 'unLoadPackageNumber', 'errorUnLoadPackageNumber', 'notUnLoadPackageNumber']
    };
  },
  computed: {
    IsWidth() {
      return {
        width: this.isTormat ? '170px' : '120px'
      };
    }
  },
  created() {
    if (this.$i18n.locale !== 'zh') {
      this.isTormat = true;
    }
    this.queryForm.params.loadSiteIdList = [this.$store.getters.siteId];
    this.getTimer();
    this.getVehicleTagNumber();
    this.getSiteList();
    this.getSearch('');
    // 获取装车人列表
    this.getOperationUserInfo1();
  },
  methods: {
    enterSearch() {
      this.queryForm.number = 1;
      this.getSearch();
    },
    // 重置
    resetForm() {
      this.queryForm.number = 1;
      this.isTest = 1;
      this.getTimer();
      this.queryForm.params = {
        numberList: [],
        queryType: 1,
        loadSiteIdList: [], // 装车网点
        nextSiteIdList: [], // 下一站
        loadUserIdList: [] // 装车人
      };
      this.getVehicleTagNumber01();
      this.getSearch();
    },
    parentMethod(arg) {
      this.dialogVisible = arg;
      this.dialogVisible01 = arg;
      this.photoSeeRefVisible = arg;
    },
    seeDetial(row, number, queryType) {
      console.log(row, number, 'number');
      this.dataObj = {
        id: row.id, // 列表id
        nextSiteId: row.nextSiteId, // 下一站
        queryType: queryType // 查询类型 1装车包裹数,2卸车包裹数,3差异数量
      };
      if (queryType) {
        if (number === '0' || number === '-' || number === null) {
          console.log('详情');
        } else {
          this.dialogVisible = true;
        }
      }
    },
    seeDetial01(row, number, queryType) {
      this.dataObj = {
        id: row.id, // 列表id
        nextSiteId: row.nextSiteId, // 下一站
        queryType: queryType // 查询类型 1装车包裹数,2卸车包裹数,3差异数量
      };
      if (queryType) {
        if (number === '0' || number === '-' || number === null) {
          console.log('详情');
        } else {
          this.dialogVisible01 = true;
        }
      }
    },
    seeDetialImage(row) {
      this.dataObj = {
        id: row.id, // 列表id
        nextSiteId: row.nextSiteId, // 下一站
        vehicleTagNumber: row.vehicleTagNumber
      };
      this.photoSeeRefVisible = true;
    },
    // 查询
    getSearch(val) {
      const data = {
        params: {}
      };
      data.pageSize = this.queryForm.pageSize;
      data.pageNumber = this.queryForm.number;
      if (this.timeValue && this.timeValue.length > 0) {
        data.params.loadStartTime = this.timeValue[0];
        data.params.loadEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      data.params.queryType = this.queryForm.params.queryType;
      data.params.loadSiteIdList = this.queryForm.params.loadSiteIdList;
      data.params.nextSiteIdList = this.queryForm.params.nextSiteIdList;
      data.params.numberList = this.queryForm.params.numberList;
      data.params.loadUserIdList = this.queryForm.params.loadUserIdList;
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      loadUnloadSumpageQuery(data).then(res => {
        if (res.status === 'OK') {
          this.queryForm.total = res.data.total;
          this.tableData = res.data.records;
          if (val === 1) {
            loading.close();
          }
          console.log(this.tableData, 'tableData');
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    // 点击分页
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.number = 1;
      this.getSearch();
    },
    handleCurrentChange(val) {
      this.queryForm.number = val;
      this.getSearch();
    },
    // 获取最近一星期时间
    getTimer() {
      const today = dayjs().format('YYYY-MM-DD');
      this.timeValue = [today + ' 00:00:00', today + ' 23:59:59'];
    },
    closeDrawer() {
      this.dialogVisible = false;
      this.photoSeeRefVisible = false;
      this.dialogVisible01 = false;
      console.log(this.photoSeeRefVisible, 'photoSeeRefVisible');
    },
    allExportOrder() {
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.loadStartTime = this.timeValue[0];
        data.loadEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      data.queryType = this.queryForm.params.queryType;
      data.loadSiteIdList = this.queryForm.params.loadSiteIdList;
      data.nextSiteIdList = this.queryForm.params.nextSiteIdList;
      data.numberList = this.queryForm.params.numberList;
      data.loadUserIdList = this.queryForm.params.loadUserIdList;
      loadUnloadSumExportAll(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    },
    airExportOrder() {
      const data = {};
      if (this.timeValue && this.timeValue.length > 0) {
        data.loadStartTime = this.timeValue[0];
        data.loadEndTime = this.timeValue[1];
      } else {
        this.$message.warning(this.$t('GlobalSetObj.selectTime'));
        return;
      }
      data.queryType = this.queryForm.params.queryType;
      data.loadSiteIdList = this.queryForm.params.loadSiteIdList;
      data.nextSiteIdList = this.queryForm.params.nextSiteIdList;
      data.numberList = this.queryForm.params.numberList;
      data.loadUserIdList = this.queryForm.params.loadUserIdList;
      tfkDirExport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    },
    changeVehicleTagNumber(val) {
      console.log(val, 'getVehicleTagNumber01');
      if (val.length === 0) {
        this.queryForm.params.numberList = [];
        this.getVehicleTagNumber('');
      }
      if (this.queryForm.params.numberList.length === 0) {
        this.getVehicleTagNumber('');
      }
    },
    getVehicleTagNumber01() {
      this.queryForm.params.numberList = [];
      this.getVehicleTagNumber('');
    },
    // 查询车签号, 车牌号
    getVehicleTagNumber(val) {
      const data = {
        number: val,
        queryType: this.queryForm.params.queryType
      };
      vehicleTagNumber(data).then((res) => {
        if (res.status === 'OK') {
          this.nivNumberList = res.data;
        }
      });
    },
    changeGetSiteList1(val) {
      console.log(val, 'getVehicleTagNumber01');
      if (val.length === 0) {
        this.queryForm.params.loadSiteIdList = [];
        this.getSiteList('');
      }
      if (this.queryForm.params.loadSiteIdList.length === 0) {
        this.getSiteList('');
      }
    },
    changeGetSiteList(val) {
      console.log(val, 'getVehicleTagNumber01');
      if (val.length === 0) {
        this.queryForm.params.nextSiteIdList = [];
        this.getSiteList('');
      }
      if (this.queryForm.params.nextSiteIdList.length === 0) {
        this.getSiteList('');
      }
    },
    getSiteList(val) {
      const data = {
        siteName: val
      };
      loadUnloadSumuserAndSite(data).then((res) => {
        if (res.status === 'OK') {
          this.siteList = res.data.siteList;
        }
      });
    },
    changeOperationUserInfo(val) {
      console.log(val, 'getVehicleTagNumber01');
      if (val.length === 0) {
        this.queryForm.params.loadUserIdList = [];
        this.getOperationUserInfo1('');
      }
      if (this.queryForm.params.loadUserIdList.length === 0) {
        this.getOperationUserInfo1('');
      }
    },
    getOperationUserInfo1(val) {
      const data = {
        operateUserName: val
      };
      loadUnloadSumuserAndSite(data).then((res) => {
        if (res.status === 'OK') {
          this.userList = res.data.operatorList;
        }
      });
    }
  }
};
</script>

<style scoped>
.app-container >>> .drawerClass header {
  border-bottom: 1px solid #ccc !important;
  margin-bottom: 0 !important;
  padding: 20px 20px 20px !important;
}

.app-container >>> .el-form--label-top .el-form-item__label {
  width: 100% !important;
}

.app-container >>> .selectLabel .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
  border: none;
  font-weight: bold;
  text-align: left;
}

.app-container >>> .selectLabel .el-input__icon {
  line-height: 28px !important;
}

.selectType ::v-deep.el-input--suffix .el-input__inner {
  border: none !important;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.WaybillNumber {
  color: #409eff;
  cursor: pointer;
}

.app-container >>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}
</style>
