<template>
  <div class="warp" style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <el-form
      :inline="true" :model="searchForm.params" class="search-form"
      label-position="top" size="small"
    >
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item :label="$t('GlobalSetObj.waybillNumber')" style="width: 100%">
            <el-input
              v-model="searchForm.params.waybillNumber"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              clearable
            />
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('newOrder.StorageTimeOutlets')">
            <!--网点入库时间-->
            <el-date-picker
              v-model="queryDateTime01"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('operationCenter.endDate')"
              :picker-options="pickerOptions"
              :start-placeholder="$t('operationCenter.startDate')"
              range-separator="~"
              style="width: 100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @blur="refreshDate"
            />
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item :label="$t('GlobalSetObj.SigningTime')">
            <!--签收时间-->
            <el-date-picker
              v-model="queryDateTime02"
              :default-time="['00:00:00', '23:59:59']"
              :end-placeholder="$t('operationCenter.endDate')"
              :picker-options="pickerOptions"
              :start-placeholder="$t('operationCenter.startDate')"
              range-separator="~"
              style="width: 100%"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              @blur="refreshDate"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="margin-bottom: 20px">
      <el-button size="small" type="primary" @click="getSearch">{{ $t('newOrder.search') }}</el-button>
      <el-button size="small" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
      <el-button size="small" @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%;">
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="index"
        type="index" width="60px"
      />
      <el-table-column
        :label="$t('GlobalSetObj.waybillNumber')" align="center" min-width="160px"
        prop="waybillNumber"
      />
      <el-table-column
        :label="$t('GlobalSetObj.infeatSite')"
        align="center"
        min-width="150px"
        prop="deliverWarehouseStationName"
      />
      <el-table-column
        :label="$t('newOrder.waybillStatus')" align="center" min-width="150px"
        prop="waybillStatusName"
      />
      <el-table-column
        :label="$t('basicData.transportWeight')"
        align="center"
        min-width="130px"
        prop="transportWeight"
      />
      <el-table-column
        :label="$t('placeOrder.area')" align="center" min-width="130px"
        prop="region"
      />
      <el-table-column
        :label="$t('placeOrder.cityType')" align="center" min-width="140px"
        prop="cluster"
      />
      <el-table-column
        :label="$t('newOrder.StorageTimeOutlets')"
        align="center"
        min-width="150px"
        prop="dispatchReceiptTime"
      />
      <el-table-column
        :label="$t('GlobalSetObj.SigningTime')" align="center" min-width="150px"
        prop="deliveredTime"
      />
      <el-table-column
        :label="$t('basicData.shouldDeliveredTime')"
        align="center"
        min-width="160px"
        prop="expectedDeliveredTime"
      />
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="getSearch"
        @size-change="getSearch"
      />
    </div>
  </div>
</template>

<script>
import { detailExport, siteWaybillDetail } from '@/api/finance/basicData';
import dayjs from 'dayjs';

export default {
  props: {
    queryDateTime: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      queryDateTime01: [],
      queryDateTime02: [],
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      },
      tableData: [],
      total: 0,
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 30
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 30
          );
          const min = minTime;
          const newDate =
            new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后一个月时间可选
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      }
    };
  },
  created() {
    // return;
    this.getSearch();
  },
  methods: {
    exportToExcel() {
      const data = {
        waybillNumber: this.searchForm.params.waybillNumber,
        dataType: this.dataobj.dataType,
        beginDate: this.queryDateTime[0],
        endDate: this.queryDateTime[1],
        customerType: this.dataobj.customerType,
        state: this.dataobj.state,
        siteIdList: this.dataobj.siteIdList,
        stationId: this.dataobj.stationId,
        searchDate: this.dataobj.searchDate
      };
      if (this.queryDateTime01.length > 0) {
        data.stationInBeginTime = this.queryDateTime01[0];
        data.stationInEndTime = this.queryDateTime01[1];
      }
      if (this.queryDateTime02.length > 0) {
        data.deliveredBeginTime = this.queryDateTime02[0];
        data.deliveredEndTime = this.queryDateTime02[1];
      }
      detailExport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              this.$emit('call-parent-method', false);
              this.$router.push({ path: '/resource/exportTaskList' });
            })
            .catch(() => {
            });
        }
      });
    },
    getSearch() {
      console.log(this.dataobj, 'dataobj');
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          waybillNumber: this.searchForm.params.waybillNumber,
          dataType: this.dataobj.dataType,
          beginDate: this.queryDateTime[0],
          endDate: this.queryDateTime[1],
          customerType: this.dataobj.customerType,
          state: this.dataobj.state,
          siteIdList: this.dataobj.siteIdList,
          stationId: this.dataobj.stationId,
          searchDate: this.dataobj.searchDate
        }
      };
      if (this.queryDateTime01.length > 0) {
        data.params.stationInBeginTime = this.queryDateTime01[0];
        data.params.stationInEndTime = this.queryDateTime01[1];
      }
      if (this.queryDateTime02.length > 0) {
        data.params.deliveredBeginTime = this.queryDateTime02[0];
        data.params.deliveredEndTime = this.queryDateTime02[1];
      }
      siteWaybillDetail(data)
        .then((res) => {
          console.log(res);
          if (res.status === 'OK') {
            this.tableData = res.data.records;
            this.total = res.data.total;
          }
        });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      };
      this.queryDateTime01 = [];
      this.queryDateTime02 = [];
      this.getSearch();
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD') + ' 00:00:00'
      };
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.warp >>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
