import request from '@/utils/request';
const baseFinanceURL = process.env.VUE_APP_FINANCE;

/**
 * 索赔列表
 */

// 分页查询
export const apidDmageClaimformPage = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/page',
    data,
    baseURL: baseFinanceURL
  })
};

// 列表导出
export const apidDmageClaimformExport = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/formExport',
    data,
    baseURL: baseFinanceURL
  })
};

// 详情
export const apiDamageClaimFormId = id => {
  return request({
    method: 'GET',
    url: `/demageClaimform/${id}`,
    baseURL: baseFinanceURL
  })
};

// 新增/保存
export const apiDemageClaimFormAdd = data => {
  return request.post('/fa/demageClaimform/add', data);
};

// 修改
export const apiDemageClaimFormEdit = data => {
  return request({
    method: 'PUT',
    url: '/demageClaimform/edit',
    data,
    baseURL: baseFinanceURL
  })
};

// 关联订单导出
export const apiDemageClaimformExport = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/export',
    data,
    baseURL: baseFinanceURL
  })
};

// 关联单号校验/导入
export const apiDemageClaimformOrderDetail = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/demageClaimform/orderDetail',
    data,
    isLoading, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 移除关联订单
export const apiDemageClaimFormRemoveBill = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/removeBill',
    data,
    baseURL: baseFinanceURL
  })
};

// 提交变更节点
export const apiDemageClaimform = id => {
  return request({
    method: 'GET',
    url: `/demageClaimform/${id}`,
    baseURL: baseFinanceURL
  })
};

// 审核--变更节点
export const apiDemageClaimformRejectAndPass = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/rejectAndPass',
    data,
    baseURL: baseFinanceURL
  })
};

// 审批流
export const apiDemageClaimformCommit = id => {
  return request({
    method: 'POST',
    url: `/demageClaimform/commit/${id}`,
    baseURL: baseFinanceURL
  })
};

// 关闭
export const apiDemageClaimformClose = id => {
  return request({
    method: 'GET',
    url: `/demageClaimform/close/${id}`,
    baseURL: baseFinanceURL
  })
};

// 付款
export const apiDemageClaimformCommitPay = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/commitPay',
    data,
    baseURL: baseFinanceURL
  })
};
// 取消付款
export const apiDemageClaimformUnCommitPay = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/unCommitPay',
    data,
    baseURL: baseFinanceURL
  })
};
// 索赔账单取消付款
export const apiDemageClaimBillUnCommitPay = data => {
  return request({
    method: 'POST',
    url: '/demageClaimBill/unCommitPay',
    data,
    baseURL: baseFinanceURL
  })
};
// 关联订单分页查询
export const apiDemageClaimformUnClimFromDetail = data => {
  return request({
    method: 'POST',
    url: '/demageClaimform/climFromDetail',
    data,
    baseURL: baseFinanceURL
  })
};
