<template>
  <div class="background">
    <div v-if="tableData.length > 0" style="margin-bottom: 10px;color: #f00;">{{ tableData[0].msg }}</div>
    <div style="padding-bottom: 20px;">
      <div class="colorCon">
        <span
          class="colorClass colorClass1"
          style="background:#64B1FF;"
        />
        <!--重算后价格变低-->
        <span class="colorTitle">{{
          $t('receivablePayable.ThePriceBecomesLowerAfterRecalculation')
        }}（{{ tableData[0].resultTypeCountMap[3] || 0 }}）</span>
      </div>
      <div class="colorCon">
        <span
          class="colorClass colorClass2"
          style="background:#FF3E3E;"
        />
        <!--重算后价格变高-->
        <span class="colorTitle">{{
          $t('receivablePayable.ThePriceBecomesHigherAfterRecalculation')
        }}（{{ tableData[0].resultTypeCountMap[2] || 0 }}）</span>
      </div>
      <div class="colorCon">
        <span
          class="colorClass colorClass3"
          style="background:#999999;"
        />
        <!--重算后价格为0-->
        <span class="colorTitle">{{
          $t('receivablePayable.ThePriceAfterRecalculationIs0')
        }}（{{ tableData[0].resultTypeCountMap[1] || 0 }}）</span>
      </div>
      <div class="colorCon">
        <span
          class="colorClass colorClass4"
          style="background:#FFFFFF;border: 1px solid #999999;"
        />
        <!--重算后价格不变-->
        <span class="colorTitle">{{
          $t('receivablePayable.ThePriceRemainsUnchangedAfterRecalculation')
        }}（{{ tableData[0].resultTypeCountMap[0] || 0 }}）</span>
      </div>
    </div>
    <el-table
      :cell-class-name="myClass"
      :data="tableData"
      border
      height="400"
    >
      <el-table-column
        :label="$t('operationCenter.ind')"
        align="center"
        width="60px"
      >
        <template slot-scope="scope">
          <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in currentColumns"
        :key="index"
        :align="item.fixed"
        :label="item.label"
        :prop="item.prop"
        :show-overflow-tooltip="true"
        :width="item.width"
      >
        <template slot-scope="scope">
          <div>{{ scope.row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页工具 -->
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getTablePageData"
        @size-change="getTablePageData"
      />
    </div>
    <div style="text-align: right;">
      <!--确认替换价格-->
      <el-button
        size="small"
        type="primary"
        @click="confirmReplacement(true)"
      >{{ $t('receivablePayable.ConfirmTheReplacementPrice') }}
      </el-button>
      <!--取消重算-->
      <el-button
        size="small"
        @click="confirmReplacement(false)"
      >{{ $t('receivablePayable.CancelTheRecalculation') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {
  apiCustomerExpenseReCalculatePrice,
  apiCustomerExpenseReCalculatePriceResult,
  apiReCalculatePriceResult
} from '@/api/finance/receivables';
import { cloneDeep } from 'lodash-es';

export default {
  name: 'RecalculatePrice',
  props: {
    getParam: {
      type: Object,
      default: () => {
        return {
          providerId: '', // 应收主体id
          siteId: '', // 战点id
          startTime: '', // 业务开始时间
          endTime: '', // 业务结束时间
          timeField: 'operate_time', // 查询时间类型 入库时间:input_time 出库时间:out_time 业务时间:operate_time
          numberList: [], // 单号
          numberField: 'waybill_number', // waybill_number: 业务单号 customer_number: 客户单号
          billBatchNumber: '', // // 批次号
          feeName: '', // 费用类型名称
          isGenBill: null, // 是否生成对账单
          isPaid: null, // 是否已支付
          isOut: null // 是否已出库
        };
      }
    },
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    importList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    importData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    calculationType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tableData: [],
      queryForm: {
        pageNumber: 1,
        pageSize: 10
      },
      total: 0,
      currentColumns: [
        // 业务单号
        {
          prop: 'waybillNumber',
          label: this.$i18n.t('receivablePayable.TicketNumber'),
          width: 200,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 客户单号
        {
          prop: 'customerNumber',
          label: this.$i18n.t('receivablePayable.CustomerTrackingNumber'),
          width: 200,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 网点
        {
          prop: 'siteName',
          label: this.$i18n.t('basicData.Outlets'),
          width: 160,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 客户
        {
          prop: 'customerAlias',
          label: this.$i18n.t('receivablePayable.customer'),
          width: 120,
          fixed: 'left',
          highlight: true,
          disabled: true
        },
        // 费用类型
        {
          prop: 'feeName',
          label: this.$i18n.t('basicData.ExpenseType'),
          width: 120,
          fixed: 'left',
          highlight: true,
          disabled: true
        },
        // 价格（重算前）
        {
          prop: 'oldFee',
          label: this.$i18n.t('receivablePayable.PriceBeforeRecalculation'),
          width: 160,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 价格（重算后）
        {
          prop: 'newFee',
          label: this.$i18n.t('receivablePayable.PriceAfterRecalculation'),
          width: 160,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 计费重量（重算前）
        {
          prop: 'oldChargeWeight',
          label: this.$i18n.t('receivablePayable.BillableWeightBeforeRecalculation') + '(KG)',
          width: 160,
          fixed: 'left',
          highlight: false,
          disabled: true
        },
        // 计费重量(重算后)
        {
          prop: 'newChargeWeight',
          label: this.$i18n.t('receivablePayable.BillableWeightRecalculated') + '(KG)',
          width: 160,
          fixed: 'left',
          highlight: false,
          disabled: true
        }
      ]
    };
  },
  created() {
    this.getRecalculateTable();
  },
  methods: {
    // 确定替换
    confirmReplacement(type) {
      apiReCalculatePriceResult(type)
        .then((res) => {
          if (res.code === 200) {
            const msg = type ? this.$i18n.t('receivablePayable.ReplacementSuccessful') : this.$i18n.t('orderCenterCont.CancellationSuccessful'); // '替换成功' : '取消成功'
            this.$message.success(msg);
            this.$emit('changePage', true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 背景色
    myClass({ row, column, rowIndex, columnIndex }) {
      if (row.resultType === 0) {
        return 'unchanged';
      } else if (row.resultType === 1) {
        return 'zero';
      } else if (row.resultType === 2) {
        return 'getHigher';
      } else if (row.resultType === 3) {
        return 'lower';
      }
    },
    // 获取列表数据
    getRecalculateTable() {
      const param = {
        pageNumber: this.queryForm.pageNumber,
        pageSize: this.queryForm.pageSize,
        params: cloneDeep(this.getParam)
      };
      param.params.reCalculatePriceSource = this.calculationType;
      const ids = [];
      if (this.calculationType === 1) {
        if (this.selectData.length > 0) {
          this.selectData.map((item) => {
            ids.push(item.id);
          });
        }
      }
      param.params.ids = ids;
      param.params.importDataList = this.importData;
      apiCustomerExpenseReCalculatePrice(param).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.$emit('changePage', false);
        }
      }).catch(() => {
        this.$emit('changePage', false);
      });
    },
    // 获取tableData page
    getTablePageData() {
      apiCustomerExpenseReCalculatePriceResult(this.queryForm).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records;
        }
      }).catch((err) => {
        console.error(err);
      });
    }
  }
};
</script>

<style>
.background .el-table tbody td {
  color: #000;
}

.background .el-table tbody tr:hover td {
  background-color: null !important;
}

.background .el-table tbody td.unchanged {
  background-color: #fff !important;
}

.background .el-table tbody td.zero {
  background-color: #999999 !important;
}

.background .el-table tbody td.getHigher {
  background-color: #ff3e3e !important;
}

.background .el-table tbody td.lower {
  background-color: #64b1ff !important;
}

.colorCon {
  margin-right: 10px;
  display: inline-block;
  line-height: 20px;
}

.colorClass {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  float: left;
}

.colorTitle {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  float: left;
}
</style>
