import request from '@/utils/request';

const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 理赔审核规则

// 分页
export const apiOperationBigBagInfoPage = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/claimsReviewConfiguration/page',
    data,
    isLoading, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 添加
export const apiClaimsReviewConfigurationAdd = data => {
  return request({
    method: 'POST',
    url: '/claimsReviewConfiguration/add',
    data, 
    baseURL: baseFinanceURL
  });
};

// 修改
export const apiClaimsReviewConfigurationEdit = data => {
  return request({
    method: 'PUT',
    url: '/claimsReviewConfiguration/edit',
    data, 
    baseURL: baseFinanceURL
  });
};

// 删除
export const apiClaimsReviewConfigurationDelete = ids => {
  return request({
    method: 'DELETE',
    url: '/claimsReviewConfiguration/delete/' + ids,
    baseURL: baseFinanceURL
  });
  // return request.delete(`/fa/claimsReviewConfiguration/delete/${ids}`);
};
