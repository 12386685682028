import { render, staticRenderFns } from "./userManage.vue?vue&type=template&id=b4e5df76&scoped=true"
import script from "./userManage.vue?vue&type=script&lang=js"
export * from "./userManage.vue?vue&type=script&lang=js"
import style0 from "./userManage.vue?vue&type=style&index=0&id=b4e5df76&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4e5df76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b4e5df76')) {
      api.createRecord('b4e5df76', component.options)
    } else {
      api.reload('b4e5df76', component.options)
    }
    module.hot.accept("./userManage.vue?vue&type=template&id=b4e5df76&scoped=true", function () {
      api.rerender('b4e5df76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/userManage/userManage.vue"
export default component.exports