<template>
  <div class="app-container">
    <el-card class="box-card" style="margin-bottom: 10px;">
      <el-form label-position="top" :model="queryForm" size="small">
        <el-row :gutter="10">
          <el-col :span="6">
            <!--费用名称-->
            <el-form-item :label="$t('basicData.ExpenseName')" prop="name">
              <el-input id="name" v-model="queryForm.params.name" :placeholder="$t('orderDetails.pleaseInput')" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select id="businessType" v-model="queryForm.params.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <!--全部-->
                <el-option key="0" :label="$t('collectionCenter.tudo')" :value="null" />
                <el-option
                  v-for="item in BUSINESS_TYPE_TWO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="small"
              @click="getDataSearch(1)"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="small"
              @click="getDataSearch(2)"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <div style="margin-bottom: 10px;">
        <el-button
          v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'"
          type="primary"
          size="small"
          @click="addEdit(1)"
        >{{ $t('GlobalSetObj.append') }}
        </el-button>
      </div>
      <div>
        <el-table
          ref="elTableFeesType"
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
        >
          <el-table-column :label="$t('operationCenter.ind')" width="60px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <!--费用名称-->
          <el-table-column
            prop="displayName"
            :label="$t('basicData.ExpenseName')"
            align="left"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div style="color: #409eff;cursor: pointer;" @click="addEdit(2, scope.row)">{{ scope.row.displayName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="code"
            :label="$t('basicData.FeeCode')"
            align="left"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.code }}</div>
            </template>
          </el-table-column>
          <!--业务类型-->
          <el-table-column
            prop="businessType"
            :label="$t('collectionCenter.businessT')"
            align="center"
            width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-for="(item, index) in BUSINESS_TYPE_TWO">
                <span
                  :key="index"
                  v-if="scope.row.businessType === item.value"
                >
                  {{ item.label }}
                </span>
              </span>
            </template>
          </el-table-column>
          <!--状态-->
          <el-table-column
            prop="state"
            :label="$t('basicData.status')"
            align="center"
            width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <!--启用-->
              <el-tag v-if="scope.row.state">{{ $t('basicData.enable') }}</el-tag>
              <!--禁用-->
              <el-tag v-else type="danger">{{ $t('basicData.disable') }}</el-tag>
            </template>
          </el-table-column>
          <!--创建人-->
          <el-table-column
            prop="createUserStr"
            :label="$t('basicData.CreatePeople')"
            align="center"
            width="120"
            :show-overflow-tooltip="true"
          />
          <!--创建时间-->
          <el-table-column
            prop="createTime"
            :label="$t('basicData.CreationTime')"
            align="center"
            width="160"
            :show-overflow-tooltip="true"
          />
          <!--最后修改人-->
          <el-table-column
            prop="updateUserStr"
            :label="$t('basicData.LastModifier')"
            align="center"
            width="120"
            :show-overflow-tooltip="true"
          />
          <!--最后修改时间-->
          <el-table-column
            prop="updateTime"
            :label="$t('basicData.LastModified')"
            align="center"
            width="160"
            :show-overflow-tooltip="true"
          />
          <!--备注-->
          <el-table-column
            prop="remark"
            :label="$t('basicData.remark')"
            align="left"
            :show-overflow-tooltip="true"
            width="200"
          />
          <el-table-column
            :label="$t('basicData.operate')" fixed="right" width="200"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                <!--确定禁用吗？-->
                <el-popconfirm
                  v-if="scope.row.state"
                  v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'"
                  :title="$t('basicData.AreYouSureDisabled')"
                  @confirm="asyncPartitionEdit(scope.row, 0)"
                >
                  <!--禁用-->
                  <el-button
                    slot="reference" class="ml" type="warning"
                    size="small"
                  >{{ $t('basicData.disable') }}</el-button>
                </el-popconfirm>
                <!--确定启用吗？-->
                <el-popconfirm
                  v-if="!scope.row.state"
                  v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'"
                  :title="$t('basicData.AreYouSureEnabled')"
                  @confirm="asyncPartitionEdit(scope.row, 1)"
                >
                  <!--启用-->
                  <el-button
                    slot="reference" class="ml" type="primary"
                    size="small"
                  >{{ $t('basicData.enable') }}</el-button>
                </el-popconfirm>
                <!--删除-->
                <el-popconfirm
                  v-if="!scope.row.state"
                  v-permit:remove="'btn:finance:basicData:payable:feesType-del'"
                  :title="$t('basicData.AreYouSureYouWantToDelete')"
                  @confirm="deleteAccount(scope.row)"
                >
                  <!--删除-->
                  <el-button
                    slot="reference" class="ml" type="danger"
                    size="small" style="margin-left: 10px;"
                  >{{ $t('basicData.Delete') }}</el-button>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页工具 -->
        <div class="pagination">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="queryForm.pageNumber"
            :page-sizes="[10, 20, 50, 100]"
            :page-size.sync="queryForm.pageSize"
            :total="total"
            background
            style="margin: 10px 0"
            @current-change="currencyChangeTableData"
            @size-change="sizeChangeTableData"
          />
        </div>
      </div>
    </el-card>
    <!-- 费用类型添加、修改 -->
    <el-drawer
      :visible.sync="drawerObj.visible"
      :title="drawerObj.typeIndex === 1 ? $t('basicData.AddAFee') : $t('basicData.FeeDetails')"
      direction="ltr"
      size="60%"
      :wrapper-closable="false"
    >
      <check-detail
        v-if="drawerObj.visible"
        :key="editKey"
        :detail-data="drawerObj.editData"
        :operation-type="drawerObj.typeIndex"
        @saveChange="saveChange"
      />
    </el-drawer>
  </div>
</template>

<script>
import checkDetail from './cmp/checkDetail';
import { editStatusFee, feeDelete, feePage } from '@/api/finance/partition';
import { getUserName } from '@/utils/asyncTools';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { BUSINESS_TYPE_TWO } from '@/views/finance/utils.js';

export default {
  name: 'FeesTypeCope',
  components: {
    checkDetail
  },
  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          name: '',
          businessType: null,
          attribute: 2
        }
      },
      tableData: [],
      total: 0,
      drawerObj: {
        visible: false,
        typeIndex: 1,
        editData: {
          code: '',
          name: '',
          namePu: '',
          remark: '',
          state: true,
          operationMode: 1, // 经营模式 默认自营
          businessType: null,
          collectType: null,
          id: '',
          attribute: 2, // 用于区分应付or应收
        }
      },
      editKey: new Date().getTime(),
      userIdAndNameObj: null
    };
  },
  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO; // 业务类型

    this.userIdAndNameObj = getUserIdAndNameObj();
    this.getTableDataList();
  },
  methods: {
    // 搜索、重置
    getDataSearch(type) {
      this.queryForm.pageNumber = 1;
      if (type === 2) {
        this.queryForm.params.name = '';
        this.queryForm.params.businessType = null;
      }
      this.getTableDataList();
    },
    // 编辑保存
    saveChange(type) {
      this.drawerObj = {
        visible: false,
        typeIndex: 1,
        editData: {
          name: '',
          namePu: '',
          remark: '',
          state: true,
          businessType: null,
          collectType: null,
          id: '',
          attribute: 2
        }
      };
      if (type === 1) {
        this.getTableDataList();
      }
    },
    // 添加、编辑
    addEdit(type, row) {
      if (type === 1) {
        this.drawerObj.editData = {
          code: '',
          name: '',
          namePu: '',
          remark: '',
          state: true,
          businessType: null,
          collectType: null,
          id: '',
          attribute: 2
        };
      } else {
        const { code, name, namePu, id, remark, state, operationMode, businessType, collectType, attribute, ajdDetailsList, taxationType, taxBase, reduction,ajdId } = row;
        const obj = {
          code,
          name,
          namePu,
          id,
          remark,
          state,
          operationMode,
          businessType,
          collectType,
          attribute,
          ajdDetailsList,
          taxationType,
          taxBase,
          reduction,
          ajdId
        };
        // 转运
        if (businessType === 1) {
          obj.collectType = null;
          obj.operationMode = null;
        }

        // 揽收
        if (businessType === 2) {
          obj.collectType = collectType;
          obj.operationMode = null;
        }
        // 派送
        if (businessType === 3) {
          obj.collectType = null;
          obj.operationMode = operationMode;
        }

        obj.attribute = 2;

        Object.assign(this.drawerObj.editData, obj);

        // this.drawerObj.editData = cloneDeep(row);
        // this.drawerObj.editData.attribute = 2;
      }
      this.drawerObj.typeIndex = type;
      this.drawerObj.visible = true;
      this.editKey = new Date().getTime();
    },
    // 启用、禁用
    asyncPartitionEdit(row, type) {
      const param = {
        id: row.id,
        state: type
      };
      editStatusFee(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getTableDataList();
        }
      });
    },
    // 删除
    deleteAccount(row) {
      feeDelete({ id: row.id }).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          this.getTableDataList();
        }
      });
    },
    // 获取费用类型列表
    getTableDataList() {
      feePage(this.queryForm).then(res => {
        if (res.code === 200) {
          const { data } = res;
          this.tableData = data.records.map(item => {
            const updateUserStr = getUserName(item.updateUser, this.userIdAndNameObj);
            const createUserStr = getUserName(item.createUser, this.userIdAndNameObj);
            for (const key in item) {
              if (key === 'createTime' || key === 'updateTime') {
                item[key] = item[key] ? item[key].replace('T', ' ') : '';
              }
            }
            return Object.freeze({
              ...item,
              updateUserStr,
              createUserStr
            });
          });
          this.total = data.total;
        }
      });
    },
    // 翻页
    currencyChangeTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableDataList();
    },
    // 每页显示数量切换
    sizeChangeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableDataList();
    }
  }
};
</script>

<style scoped>

</style>
