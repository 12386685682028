<template>
  <div class="app-container">
    <el-tabs type="border-card">
      <el-tab-pane label="地区信息">
        <div style="margin: -15px;height: calc(100vh - 160px);">
          <region />
        </div>
      </el-tab-pane>
      <el-tab-pane label="ICMS税费参照表">
        <div style="margin: -15px;height: calc(100vh - 160px);">
          <icms />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import region from './cmp/region';
import icms from './cmp/icms';
export default {
  name: 'DistrictManagement',
  components: {
    region,
    icms
  }
};
</script>

<style scoped>

</style>
