<template>
  <div class="warp" style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <el-form
      :inline="true" :model="searchForm.params" class="search-form"
      label-position="top" size="small"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('GlobalSetObj.bagNumber')" style="width: 100%">
            <el-input
              v-model="searchForm.params.inputValue"
              :placeholder="$t('GlobalSetObj.pleaseInput')"
              :rows="1"
              clearable
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('navbar.sumIsUnload')" style="width: 100%">
            <el-select
              v-model="searchForm.params.sumIsUnload"
              style="width: 100%"
            >
              <el-option key="0" :label="$t('GlobalSetObj.all')" value="" />
              <el-option key="1" :label="$t('GlobalSetObj.yes')" :value="1" />
              <el-option key="2" :label="$t('GlobalSetObj.No')" :value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <div style="margin-top: 26px">
            <el-button size="small" type="primary" @click="getSearch">{{ $t('newOrder.search') }}</el-button>
            <el-button size="small" @click="resetForm">{{ $t('newOrder.reset') }}</el-button>
            <el-button size="small" @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!--  表格数据  -->
    <el-table
      :data="tableData"
      :header-cell-style="{background:'#f2f2f2',color:'black'}"
      border
      style="width: 100%"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="(item,index) in tableHeaderList"
        :key="index"
        :label="item.label"
        :min-width="item.cloWidth"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.prop === 'sumIsUnload'">
            <div v-if="scope.row['sumIsUnload'] === 1">{{ $t('GlobalSetObj.yes') }}</div>
            <div v-else style="color: #f56c6c;">{{ $t('GlobalSetObj.No') }}</div>
          </div>
          <div v-else>{{ scope.row[item.prop] ? `${scope.row[item.prop]}` : '--' }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="getSearch"
        @size-change="getSearch"
      />
    </div>
  </div>
</template>

<script>
import { loadUnloadDetailedbagexportAll, loadUnloadDetailedbagNumber } from '@/api/finance/basicData';

export default {
  props: {
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          inputValue: '',
          sumIsUnload: ''
        }
      },
      tableData: [],
      total: 0,
      tableHeaderList: [
        { id: 1, label: this.$t('GlobalSetObj.bagNumber'), prop: 'newBagNumber', cloWidth: 180 },
        { id: 2, label: this.$t('navbar.sumIsUnload'), prop: 'sumIsUnload', cloWidth: 180 },
        { id: 3, label: this.$t('GlobalSetObj.LoadCarTime'), prop: 'loadTime', cloWidth: 180 },
        { id: 5, label: this.$t('GlobalSetObj.Carregador'), prop: 'loadUserName', cloWidth: 180 },
        { id: 6, label: this.$t('GlobalSetObj.unCarTime'), prop: 'unLoadTime', cloWidth: 180 },
        { id: 4, label: this.$t('GlobalSetObj.Descarregador'), prop: 'unLoadUserName', cloWidth: 180 },
        { id: 7, label: this.$t('navbar.unLoadSiteName'), prop: 'unLoadSiteName', cloWidth: 180 }
      ]
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    getSearch() {
      let inputValList = [];
      if (this.searchForm.params.inputValue) {
        inputValList = this.searchForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          id: this.dataobj.id,
          nextSiteId: this.dataobj.nextSiteId,
          queryType: this.dataobj.queryType,
          bagNumberList: inputValList,
          sumIsUnload: this.searchForm.params.sumIsUnload || null
        }
      };
      loadUnloadDetailedbagNumber(data).then((res) => {
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    exportToExcel() {
      let inputValList = [];
      if (this.searchForm.params.inputValue) {
        inputValList = this.searchForm.params.inputValue.split('\n');
      }
      if (inputValList.length > 2000) {
        this.$message.warning(this.$t('newOrder.searchMax1000'));
        inputValList = inputValList.slice(0, 2000); // 截取前2000个单号
      }
      const data = {
        id: this.dataobj.id,
        nextSiteId: this.dataobj.nextSiteId,
        queryType: this.dataobj.queryType,
        bagNumberList: inputValList,
        sumIsUnload: this.searchForm.params.sumIsUnload || null
      };
      loadUnloadDetailedbagexportAll(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$emit('call-parent-method', false);
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          inputValue: '',
          sumIsUnload: ''
        }
      };
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.warp >>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
