import request from '@/utils/request';
import { getToken } from '@/utils/auth';

const baseURL = process.env.VUE_APP_BASE_WMS_API;
const baseURL1 = process.env.VUE_APP_BASE_API4; // /api/v2/wms
const baseURL2 = process.env.VUE_APP_BASE_API; // /api/express/v1
const baseUserURL = process.env.VUE_APP_USER;

// 导入模版 分拣规则
export const sorterGridRuleImport = (data) => {
  // return request.post('/wms/sorterGridRule/import', data);
  return request({
    method: 'POST',
    url: '/wms/sorterGridRule/import',
    data,
    headers: { 'content-type': 'multipart/form-data' }
  });
};
// 查询所有客户渠道 分拣规则
export const customerChannel = () => {
  return request.post('/customerChannel/findAll');
};
// 查询分拣机订单信息 分拣订单
export const sorterOrderPage = (data) => {
  return request.post('/sorterOrder/page', data);
};
// 更新redis缓存
export const cacheAllRule = () => {
  return request.get('/wms/sorterGridRule/cacheAllRule');
};
// 邮编全部查询
export const zipcodeAreaPage = (data) => {
  // return request.post('/zipcode/area/page', data);
  return request.post('/zipcode/area/getAll', data);
};
// 邮编分页查询
export const zipcodeAreaPagePaging = (data) => {
  return request.post('/zipcode/area/page', data);
};
// 添加分拣规则
export const addSorterGridRule = (data) => {
  return request.post('/wms/sorterGridRule/add', data);
};

// 分拣规则修改
export const editSorterGridRule = (data) => {
  return request.put('/wms/sorterGridRule/edit', data);
};

// 获取所有邮件分区
export const getAllZipcode = () => {
  return request.get('/zipcode/area/getAvailableGrid');
};
// 根据id删除规则
export const deleteSorterGridRule = (id) => {
  return request.delete(`/wms/sorterGridRule/delete/${id}`);
};

// 查询所有客户 ==> 王兴
export const getAllcustomer = (data) => {
  return request.post('/customer/customerPage', data);
};

// 根据id分拣信息 ==> 王兴
export const getsorterGridRuleInfo = (data) => {
  return request.get(`/wms/sorterGridRule/${data}`);
};
// 查询邮编分区列表 ==> 王兴
export const getAllDivision = (data) => {
  return request.get(`/zipcode/division/getAll`);
};
// 新增邮编分区
export const zipcodeAreaAdd = (data) => {
  return request.post('/zipcode/area', data);
};
// 编辑邮编分区
export const zipcodeAreaEdit = (data) => {
  return request.put('/zipcode/area', data);
};
// 分拣机（分拣规则-新增）
export const sorterGridRuleAdd = (data) => {
  return request.post('/wms/sorterGridRule/add', data);
};
// 分拣机（分拣规则-编辑）
export const sorterGridRuleEdit = (data) => {
  return request.put('/wms/sorterGridRule/edit', data);
};
// 分拣机（分拣规则-查询可⽤格⼝）
export const getAvailableGrid = (id) => {
  return request({
    method: 'GET',
    // url: '/wms/sorterGridRule/getIdleGridByRuleId',
    url: '/wms/sorterGridRule/getIdleGridByRuleId?newRuleId=' + id,
    headers: { 'token': getToken() }
  });
};
// 分拣机（分拣规则-删除）
export const sorterGridRuleDel = (id) => {
  return request.delete('/wms/sorterGridRule/delete/' + id);
};
// 分拣机（分拣规则-校验规则名称是否存在）
export const IsExist = (data) => {
  return request.get('/wms/sorterGridRule/checkGridRule?ruleName=' + data.ruleName);
};

/* 获取用户信息*/
export function getUserInfoById(userId) {
  return request({
    url: '/findById/' + userId,
    method: 'get',
    baseURL: baseUserURL
  });
}

// 格口管理分页
export const apiPage = (data) => {
  // return request.post('/wms/sorterGrid/page', data);
  return request({
    method: 'POST',
    data,
    url: '/wms/sorterGrid/page'
  });
};

// 格口列表
export const apiList = () => {
  return request.get('/wms/sorterGrid/list');
};

// 新增格口
export const apiAdd = (data) => {
  return request.post('/wms/sorterGrid/add', data);
};

// 编辑格口
export const apiEdit = (data) => {
  return request.put('/wms/sorterGrid/edit', data);
};

// 删除格口
export const apiDelete = ids => {
  return request.delete('/wms/sorterGrid/delete/' + ids);
};

// 新增格口规则
export const apiAddSorterGridRule = data => {
  return request.post('/wms/sorterGridRule/add', data);
};

// 格口校验
export const getSorterGridInfoByRuleIdAndGridNums = data => {
  return request.get('/wms/sorterGridRule/checkBeforeAddOrEdit?id=' + data.id + '&gridNums=' + data.gridNums + '&ruleType=' + data.ruleType);
};

// 修改格口规则
export const apiEditSorterGridRule = data => {
  return request.put('/wms/sorterGridRule/edit', data);
};

// 获取客户可用渠道
export const apiGetIdleCustomerChannel = () => {
  return request.get('/wms/sorterGridRule/getIdleCustomerChannel');
};

// 获取客户可用下家
export const apiGetDownstreamList = data => {
  return request.post('/customerChannelCountryDownstream/list', data);
};

// 校验当前已选客户渠道是否都支持同一下家
export const apiGetDownstream = data => {
  return request.get(`/wms/sorterGridRule/getDownstreamByCustomerChannelIds/${data}`);
};

// 获取可用格口
// export const apiGetIdleGrid = () => {
//   return request.get('/wms/sorterGridRule/getIdleGrid');
// };

// 格口规则分页
// 分拣规则分页
export const apiRulePage = data => {
  return request.post('/wms/sorterGridRule/page', data);
};
// 选择渠道
export const getAllProviderChannel = data => {
  return request.post(`/providerChannel/getAll`);
};
// 获取客户渠道支持国家
export const apiGetCustomerChannelCountry = data => {
  return request.get(`/wms/sorterGridRule/getCustomerChannelCountry/${data}`);
};

// 删除规则
export const apiDelSorterGridRule = ids => {
  return request.delete(`/wms/sorterGridRule/delete/${ids}`);
};

// 格口大包查询
export const apiFindOne = ids => {
  return request.get(`/wms/sorterSmallBag/findOne/${ids}`);
};

// 删除大包记录
export const apiDeleteOne = ids => {
  return request.delete(`/wms/sorterSmallBag/deleteOne/${ids}`);
};
// 获取可选格口 ==> 王兴
export const getAllRuleId = ids => {
  return request.get('/wms/sorterGridRule/getIdleGridByRuleId');
};
// 查询可用格口 ==> 王兴
// export const getAllRuleId = ids => {
//   return request.get('/zipcode/area/getAvailableGrid');
// };
// 获取可选格口
export const apiGetIdleGridByRuleId = ids => {
  return request.get('/wms/sorterGridRule/getIdleGridByRuleId?ruleId=' + ids.ruleId + '&ruleType=' + ids.ruleType);
};

// 初始化数据
export const apiGeGridInfo = () => {
  // return request.get(`/wms/sorterGridRule/getIdleGridByRuleId/${ids}`);
  return request({
    method: 'GET',
    // url: '/sorter/gridInfo',
    url: '/sorterGrid/gridInfo',
    baseURL
  });
};

// 手动取消格口闪烁
export const apiUnlockGrid = ids => {
  // return request.get(`/wms/sorter/unlockGrid?gridNum=${ids}`);
  return request({
    method: 'GET',
    url: `/sorter/unlockGrid?gridNum=${ids}`,
    baseURL
  });
};

// 清空格口数量统计信息
export const apiClearGridCountStatisticalInfo = () => {
  return request({
    method: 'GET',
    url: '/sorter/clearGridCountStatisticalInfo',
    baseURL
  });
};

// 查询所有启用网点 ==> dxk
export const getqueryAll = () => {
  return request.get('/site/queryAll');
};

// 获取分拣格口 cql
export const getGridNum = (data) => {
  return request({
    method: 'post',
    data,
    url: '/wms/getGridNum',
    baseURL: baseURL1,
    headers: {
      'username': 'shein-test',
      'password': 'JR+kFAt0BDMNaB30o186jjNkBLFupNyFUkLRW3qtSKtUDY5DhsyLVT7tEynbrIeK/iaAH6TbnHo4+mHwMQ8TxnczzIUyb0In1rXEB87wwTDb6j2k5RoLRkrlnzmb3UhuqzEdZgEzz6TjkguT8Ru8STj/0X8XuqhdSnyGRtSK0IQ=,H0bEuLotzP3ashde3CR7IkRCiuS4YIx945BhaTOEhK9XJrXpIpIT60NNlagSc8Nz5S1C8TUIRWEKHyDHQcJCPCL/Q57xfYQmmrRX9QxzO7403AOG7xcTJzYCTgLpIAzQFtfj0rYYSbCxORKVfeuuG4VhloWLGWi05i1Byf5Qy1g=,bo+0fOMiOd8VOU/FbTowKNXdVmaPW7VGaqxI9bRap0AAyt4ui0O4bstINH1F4zkyDeMAZKKTuSnZFHYabSuOfIfeh0ZrQPVxLaiX/pSSoqizdSpG7pHpshNTPaM6JS6zcPX+pKfBqoG8En9Y5UdfVNRTkKiDku4Q5fTUqonsT8g=,'
    }
  });
};
// 手动分拣分页
export const anualSortingPage = data => {
  return request.post(`/sortedRecord/pageQuery`, data);
};
// 分拣规则分页
export const siteProvider = () => {
  return request.post('/wms/sorterGridRule/provider/and/site/list');
};
// 获取服务商
export const apiFuzzySearchProvider = (data) => {
  return request.post('/wms/bigBag/fuzzySearchProvider', data);
};
// 获取派送网点
export const apiFuzzySearchSite = (data) => {
  return request.post('/wms/bigBag/fuzzySearchSite', data);
};
// 获取设备列表
export const apiDeviceManagementPage = (data) => {
  return request({
    method: 'post',
    data,
    url: '/deviceManagement/page',
    baseURL: baseURL2
  });
};
// 获取中转仓
export const apiSelectTransitCenter = (data) => {
  return request({
    method: 'get',
    baseURL: baseURL2,
    data,
    url: '/deviceManagement/selectTransitCenter'
  });
};
// 新增设备
export const apiDeviceManagementAdd = (data) => {
  return request({
    method: 'post',
    data,
    url: '/deviceManagement/add',
    baseURL: baseURL2
  });
};
// 编辑设备
export const apiDeviceManagementEdit = (data) => {
  return request({
    method: 'post',
    data,
    url: '/deviceManagement/edit',
    baseURL: baseURL2
  });
};

/**
 * @description 获取
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deviceManagementList = () => {
  return request.get('/deviceManagement/list');
};

/**
 * @description 通过设备查询设备绑定的格口号
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const sorterGridRuleSelectGrid = (data) => {
  return request.post('/wms/sorterGridRule/selectGrid', data);
};
export const newsorterGridRuleSelectGrid = (data) => {
  return request.post('/wms/sortedRule/selectGrid', data);
};
// 获取可选格口 ==> 王兴
export const getMoreRule = ruleCode => {
  return request.get(`/wms/sorterGridRule/setRuleCode?ruleCode=${ruleCode}`);
};
// 发件   扫描运单袋号
export const scanShipment = (data) => {
  return request({
    method: 'post',
    data,
    url: '/wmsApi/transitBag/scanShipment',
    baseURL: baseURL1
  });
};
// 发件   删除发件扫描
export const removeShipment = (scanId) => {
  return request({
    method: 'get',
    url: `/wmsApi/transitBag/removeShipment?scanId=${scanId}`,
    baseURL: baseURL1
  });
};
// 发件   运单袋号上传
export const uploadShipment = (data) => {
  return request({
    method: 'post',
    data,
    url: `/wmsApi/transitBag/uploadShipment`,
    baseURL: baseURL1
  });
};
// 到件   中转仓-到件扫描
export const warehousereceive = (waybillBagNumber) => {
  return request({
    method: 'get',
    url: `/warehouse/receive/scanPc/${waybillBagNumber}`,
    baseURL: baseURL1
  });
};
// 到件   到件删除
export const warehouseDelete = (waybillBagNumber) => {
  return request({
    method: 'get',
    url: `/scanRecord/delete/${waybillBagNumber}`,
    baseURL: baseURL1
  });
};
// 到件   运单袋号上传
export const warehousereceiveUpload = (data) => {
  return request({
    method: 'post',
    data,
    url: `/warehouse/receive/upload`,
    baseURL: baseURL1
  });
};
// 到件 分页
export const warehouseReceivePage = () => {
  return request.get(`/collectOrderScan/scanInfo/warehouseReceivePage`);
};
// 发件 分页
export const warehouSenderPage = () => {
  return request.get(`/shipment/selectShipmentScan`);
};

/**
 * @description 到件记录分页查询
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const arriveRecordsPage = (data) => {
  return request.post('/arriveRecords/page', data);
};

/**
 * @description 到件记录导出
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export const arriveRecordsExportAll = (data) => {
  return request.post('/arriveRecords/exportAll', data);
};
/**
 * @description 通过设备查询设备绑定的格口号  dxk
 */
export const sorterGridRuleSelectGrid01 = (data) => {
  return request.post('/wms/sorterGridRule/selectGridInfo', data);
};
// 获取服务商
export const apiSearchProviderSite = () => {
  return request.get('/message/providerSiteList');
};
// 到件 分页
export const waybillPrintablePage = (data) => {
  return request.post(`/waybillPrintable/page`, data);
};
// 面单打印
export const quickOrderChange = (data) => {
  return request.post(`/waybillPrintable/quickOrderChange`, data);
};
// 新分拣规则分页
export const newApiRulePage = data => {
  return request.post('/wms/sortedRule/page', data);
};
// 根据分拣规则返回列表
export const queryByRuleType = data => {
  return request.post('/wms/sortedRule/queryByRuleType', data);
};
// 新增分拣规则
export const addSortedRule = data => {
  return request.post('/wms/sortedRule/add', data);
};
// 编辑分拣规则
export const editSubRule = data => {
  return request.post('/wms/sortedRule/editSubRule', data);
};
// 分拣规则 条件导出
export const sortedRuleExport = data => {
  return request.post('/wms/sortedRule/export', data);
};
// 设备管理-根据设备id查询分拣规则
export const querySortedRule = data => {
  return request.post('/deviceManagement/querySortedRule', data);
};
// 新建子规则
export const newChilderRules = data => {
  return request.post('/wms/sortedRule/addSubRules', data);
};
// 新建子规则
export const deleteSubRule = data => {
  return request.post(`/wms/sortedRule/deleteSubRule`, data);
};
// 面单打印
export const automaticPrintingBagNumber = (data) => {
  return request({
    method: 'post',
    data,
    url: '/wms/transitBag/automaticPrintingBagNumber',
    baseURL: baseURL1
  });
};
// 新分拣规则分页
export const messageBoardPage = data => {
  return request.post('/messageBoard/getPage', data);
};
// 分拣规则 条件导出
export const messageBoardExport = data => {
  return request.post('/messageBoard/export', data);
};
// 分拣规则 条件导出
export const messageBoardClose = data => {
  return request.get(`/messageBoard/close?messageId=${data}`);
};
// 分拣规则 保存备注
export const messageBoardSaveRemark = data => {
  return request.post('/messageBoard/saveRemark', data);
};
// 分拣规则 消息分页
// export const messageBoardContentPage = data => {
//   return request.post('/messageBoardContent/page', data);
// };
// 分拣规则 回复消息
export const messageBoardMessage = data => {
  return request.post('/messageBoard/sendMessageBoard', data);
};
// 查询消息
export const messageBoardContentPage = (data) => {
  return request({
    url: '/messageBoardContent/getPage',
    method: 'post',
    data,
    isLoading: false
  });
};
// 分拣规则 获取服务商用户
export const getPmUserList = data => {
//  return request.post('/pmUser/getPmUser', data);
  return request({
    method: 'post',
    data,
    url: '/pmUser/getPmUser',
    baseURL: baseUserURL
  });
};
// 短信配置获取操作节点
export const apiSMSGetOperationNode = (data) => {
  return request({
    method: 'get',
    data,
    url: '/smsConfig/getOperationNode',
    baseURL: baseURL2
  });
};
// 根据选择州获取网点列表
export const apiSMSGetSiteList = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsConfig/getSiteList',
    baseURL: baseURL2
  });
};
// 获取短信配置
export const apiSMSPageQuery = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsConfig/pageQuery',
    baseURL: baseURL2
  });
};
// 短信配置编辑
export const apiSMSEdit = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsConfig/edit',
    baseURL: baseURL2
  });
};
// 短信配置新增
export const apiSMSAdd = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsConfig/add',
    baseURL: baseURL2
  });
};
// 获取短信模板
export const apiSmsTemplatePage = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsTemplate/pageQuery',
    baseURL: baseURL2
  });
};
// 新增短信模板
export const apiSmsTemplateAdd = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsTemplate/add',
    baseURL: baseURL2
  });
};
// 编辑短信模板
export const apiSmsTemplateEdit = (data) => {
  return request({
    method: 'POST',
    data,
    url: '/smsTemplate/edit',
    baseURL: baseURL2
  });
};
