import request from '@/utils/request';
const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 索赔账单

// 分页
export const apiDemageClaimBillPage = data => {
  return request({
    method: 'post',
    url: `/demageClaimBill/page`,
    data,
    baseURL: baseFinanceURL
  })
};

// 付款
export const apiDemageClaimBillPayTodo = data => {
  return request({
    method: 'post',
    url: `/demageClaimBill/payTodo`,
    data,
    baseURL: baseFinanceURL
  })
};

// 账单抵扣
export const apiDemageClaimBillDeduction = data => {
  return request({
    method: 'post',
    url: `/demageClaimBill/deduction`,
    data,
    baseURL: baseFinanceURL
  })
};
