<template>
  <div class="box">
    <div class="formClass">
      <el-form
        ref="queryForm"
        :model="detailData"
        :rules="rules"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :label-position="$i18n.locale!='zh'?'top':'right'"
      >
        <el-row>
          <el-col :span="24">
            <!--账户名称-->
            <el-form-item :label="$t('basicData.AccountName')" prop="accountAlias">
              <el-input v-model="detailData.accountAlias" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--户名-->
            <el-form-item :label="$t('basicData.AccountOpeningName')" prop="accountName">
              <el-input v-model="detailData.accountName" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--账户号码-->
            <el-form-item :label="$t('basicData.AccountNumber')" prop="accountNumber">
              <el-input v-model="detailData.accountNumber" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--开户行-->
            <el-form-item :label="$t('basicData.TheBankWithWhichTheAccountOpened')" prop="bank">
              <el-input v-model="detailData.bank" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--状态-->
            <el-form-item :label="$t('basicData.status')" prop="status">
              <el-radio-group v-model="detailData.status" size="small">
                <!--禁用-->
                <el-radio-button :label="0">{{ $t('basicData.disable') }}</el-radio-button>
                <!--启用-->
                <el-radio-button :label="1">{{ $t('basicData.enable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--备注-->
            <el-form-item :label="$t('basicData.remark')" prop="remark">
              <el-input
                v-model="detailData.remark" type="textarea" :rows="4"
                :placeholder="$t('basicData.PleaseEnter')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-permit="'but:finance:basicData:accountManagement:edit'" style="text-align: right;padding: 10px;border-top: 1px solid #ccc;">
      <!--取消-->
      <el-button @click="saveFormData(2)">{{ $t('basicData.Cancel') }}</el-button>
      <!--保存-->
      <el-button type="primary" @click="saveFormData(1)">{{ $t('basicData.Save') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  accountAdd,
  accountEdit
} from '@/api/finance/partition';
export default {
  name: 'CheckDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      rules: {
        accountAlias: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        accountName: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        accountNumber: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        bank: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        remark: [
          { required: false, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  methods: {
    saveFormData(type) {
      if (type === 1) {
        this.$refs['queryForm'].validate((valid) => {
          if (valid) {
            if (this.operationType === 1) {
              //  新增
              accountAdd(this.detailData).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            } else {
              accountEdit(this.detailData).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          } else {
            console.error('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('saveChange', type);
      }
    }
  }
};
</script>

<style scoped>
.formClass {
  height: calc(100vh - 138px);
  overflow-y: auto;
  padding: 20px;
}
</style>
