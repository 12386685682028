import request from '@/utils/request';

const baseUserURL = process.env.VUE_APP_USER;

/**
 * PDA添加同级 / PDA添加下级
 */
export const addPdaPermission = data => {
//  return request.post('/permission/add', data);
  return request({
    url: '/permission/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};

/**
 * 编辑权限——PDA
 */
export const editPdaPermission = data => {
//  return request.post('/permission/edit', data);
  return request({
    url: '/permission/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};

/**
 * 删除权限——PDA
 */
export const delPermission = data => {
  return request({
    url: '/permission/delete',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};

// PC和PDA——权限树
export function getPermissionStructureAll() {
  return request({
    url: '/permission/getPermissionStructure',
    method: 'get',
    baseURL: baseUserURL
  });
}
// PC——权限树展示
export function getPermissionStructure() {
  return request({
    url: '/permission/getPermissionStructureByPc',
    method: 'get',
    baseURL: baseUserURL
  });
}

// PDA——无id值时(权限管理——pda权限表)
export function collectDriver() {
  return request({
    url: '/permission/permissionStructureByPda',
    method: 'get',
    baseURL: baseUserURL
  });
}
/**
 * 删除权限
 * @param permissionId
 * @returns {*}
 */
export const removePermission = data => {
  return request({
    url: '/permission/delete',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};

/**
 * 添加权限
 * @param data
 * @returns
 */
export const addPermission = data => {
//  return request.post('/permission/add', data);
  return request({
    url: '/permission/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};

/**
 * 编辑权限
 */
export const editPermission = data => {
//  return request.post('/permission/edit', data);
  return request({
    url: '/permission/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
};
