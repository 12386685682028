// import axios from 'axios';
import request from '@/utils/request';
import { getToken } from '@/utils/auth';
// 操作平台
const baseURL = process.env.VUE_APP_BASE_API1;
const baseURLCollection = process.env.VUE_APP_BASE_API2;

const baseURL2 = process.env.VUE_APP_BASE_API2; // /api/v2/collection

/**
 * 网点揽收入库封袋————打印袋号条形码
 */
export const printLabel = (data) => {
  return request({
    method: 'POST',
    url: '/collect/collectNumberPrintLabel',
    data,
    headers: { 'token': getToken() },
    baseURL: baseURL2
  });
};

// 网点揽收入库封袋————生成袋号
// eslint-disable-next-line no-empty-pattern
export const generatingBagNum = (data) => {
  return request({
    method: 'get',
    url: '/collect/collectNumberCreator',
    data,
    headers: { 'token': getToken() },
    baseURL: baseURL2
  });
};

/**
 * 签收——查询单号扫描记录
 */
export const pcQueryScanRecord = () => {
  return request({
    method: 'GET',
    url: '/logistics/order/client/pcQueryScanRecord?status=5',
    headers: { 'token': getToken() },
    baseURL,
    isLoading: false
  });
};

/**
 * 签收——获取收件人姓名
 */
export const getAddresseeName = (data) => {
  return request({
    method: 'post',
    url: '/logistics/order/client/getAddresseeName',
    data,
    baseURL,
    isLoading: false
  });
};

// 网点派件入库扫描
export const pcSiteDeliveryWarehouseScan = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSiteDeliveryWarehouseScanNoBagStart',
    data,
    headers: { 'token': getToken() },
    baseURL: baseURLCollection
  });
};

/*
*当扫描的包裹不属于该袋号时弹框“xxxxx不属于该袋号，是否加入到扫描列表
*”选择“是”则 进行扫描把数据加入到扫描列表，选择“否”则关闭
*
*/
export const changeBagPackage = (data) => {
  return request({
    method: 'GET',
    url: `/collection/popUpBoxToSelect?windowType=${data.windowType}&yseOrNo=${data.yseOrNo}&scanId=${data.scanId}`,
    headers: { 'token': getToken() },
    baseURL: baseURLCollection
  });
};

// 网点派件入库扫描——删除扫描记录
export const pcSiteDeliveryWarehouseScanDeleted = (data) => {
  return request({
    method: 'POST',
    url: '/collection/pcSiteDeliveryWarehouseScanDeleted',
    data,
    headers: { 'token': getToken() },
    baseURL: baseURLCollection
  });
};

// 网点揽收入库扫描
export const pcSiteCollectWarehouseScan = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSiteCollectWarehouseScan',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};
// 扫描(签收,网点揽收出库)
export const pcScanWaybillNumber = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcScanWaybillNumber',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};
// 扫描(派件)
export const pcScanWaybillNumberTow = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcScanWaybillNumberTow',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 派件扫描提交
export const pcDispatchSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcDispatchSubmit',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 签收扫描提交
export const pcSignerSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSignerSubmit',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 网点揽收出库提交
export const pcSiteCollectOutboundSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSiteCollectOutboundSubmit',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 网点揽收入库封袋
export const pcSiteCollectInStoreBagSeal = (data) => {
  return request({
    method: 'POST',
    url: '/collect/pcSiteCollectInStoreBagSeal',
    data,
    headers: { 'token': getToken() },
    baseURL: baseURL2
  });
};
// 网点揽收入库提交
export const pcSiteCollectWarehouseSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSiteCollectWarehouseSubmitNew',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 网点派件入库提交
export const pcSiteDeliveryWarehouseSubmit = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/pcSiteDeliveryWarehouseSubmit',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

// 删除扫描记录
export const deleteScanRecord = (data) => {
  return request({
    method: 'POST',
    url: '/logistics/order/client/deleteScanRecord',
    data,
    headers: { 'token': getToken() },
    baseURL
  });
};

/**
 * @description pc端 手动替换订单绑定大包
 * @param data
 * @returns {*}
 */
export const collectPcReplaceCollectOrderBag = (data) => {
  return request({
    method: 'POST',
    url: '/collect/pcReplaceCollectOrderBag',
    data,
    baseURL: baseURL2
  });
};
