import request from '@/utils/request';

const baseFinanceURL = process.env.VUE_APP_FINANCE; // finance-admin

// 揽收每日应收统计 分页
export const apiStatisticsPage = (data) => {
  return request({
    method: 'POST',
    url: '/statistics/page',
    data,
    baseURL: baseFinanceURL
  });
};

// 揽收每日应收统计 刷新数据
export const apiStatisticsAgainStatistics = (time) => {
  return request({
    method: 'GET',
    url: `/statistics/againStatistics?time=${time}`,
    baseURL: baseFinanceURL
  });
};

// 揽收每日应收统计 导出文件
export const apiStatisticsExport = (data) => {
  return request({
    method: 'POST',
    url: '/fa/statistics/export',
    data,
    // baseURL: baseFinanceURL
  });
};
