import request from '@/utils/request';
import qs from 'qs';
const baseFinanceURL = process.env.VUE_APP_FINANCE; // finance-admin
const baseAdmin = process.env.VUE_APP_ADMIN; // admin

// ----------------------应收客户账户start---------------------

// 客户收款单分页
export const apiCustomerReceiptPage = data => {
  return request.post('/fa/customerReceipt/page', data);
};

// 收款详情
export const apiCustomerReceiptDetailsPage = data => {
  return request.post('/fa/customerReceiptDetails/page', data);
};

// 获取客户账户列表
export const apiGetCustomerPage = data => {
  return request({
    method: 'POST',
    url: '/fa/customer/page',
    data,
    // baseURL: baseFinanceURL
  });
};

// ----------------------应收客户账户end---------------------

// 修改账期
export const apiUpdatePaymentCycle = data => {
  return request({
    method: 'POST',
    url: '/customer/updatePaymentCycle',
    data: qs.stringify(data),
    // baseURL: baseAdmin
  })
};

// ---------------------应收网点账户start---------------------

// 分页列表
export const apiGetSiteAccountPage = data => {
  return request({
    method: 'POST',
    url: '/siteAccount/page',
    data,
    baseURL: baseFinanceURL
  });
};
// 设置账期
export const apiSiteAccountSetCycle = data => {
  return request({
    method: 'POST',
    url: '/siteAccount/setCycle',
    data,
    baseURL: baseFinanceURL
  });
};
// 导出网点账户信息
export const apiSiteAccountExportSiteAccountInfo = data => {
  return request({
    method: 'POST',
    url: '/siteAccount/exportSiteAccountInfo',
    data,
    baseURL: baseFinanceURL
  });
};
// 导出网点账户流水
export const apiSiteAccountExportExportOfBillFlow = data => {
  return request({
    method: 'POST',
    url: '/SiteReceipt/exportOfBillFlow',
    data,
    baseURL: baseFinanceURL
  });
};

// ---------------------应收网点账户end---------------------
