<template>
  <div>
    <div class="addFormBox">
      <div class="title">
        <!--基础数据-->
        {{ $t('basicData.UnderlyingData') }}
      </div>
      <el-form
        ref="queryForm"
        :model="formData"
        :rules="rules"
        class="queryFormClass"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--报价方案名称-->
            <el-form-item :label="$t('basicData.TheNameOfTheQuoteProposal')" prop="name">
              <el-input
                v-model.trim="formData.name"
                :placeholder="$t('orderDetails.pleaseInput')"
                clearable
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--有效期开始-->
            <el-form-item :label="$t('basicData.TheValidityPeriodBegins')" prop="startTime">
              <el-date-picker
                v-model="formData.startTime"
                :disabled="operationType===2&&editData&&editData.offerPriceStatus===1"
                :format="$i18n.locale=='zh'?zhDate:ptDate"
                :placeholder="$t('basicData.SelectADatetime')"
                style="width: 100%;"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select
                v-model="formData.businessType"
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
                style="width: 100%;"
                @change="changeBusinessType(1)"
              >
                <!--快递-->
                <el-option key="0" :label="$t('collectionCenter.expressDelivery')" :value="0" />
                <!--揽收-->
                <el-option key="1" :label="$t('collectionCenter.lanshou')" :value="1" />
                <!--派件-->
                <el-option key="2" :label="$t('collectionCenter.delivery')" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col v-if="formData.businessType === 1" :span="6">
            <!--揽收类别-->
            <el-form-item :label="$t('basicData.PickupCategory')" prop="collectionCategory">
              <el-select
                v-model="formData.collectionCategory"
                :placeholder="$t('basicData.PleaseSelect')"
                filterable
                style="width: 100%;"
                @change="changeBusinessType(2)"
              >
                <!--普通揽收-->
                <el-option key="0" :label="$t('basicData.OrdinaryPickUp')" :value="0" />
                <!--备货揽收-->
                <el-option key="1" :label="$t('basicData.StockingAndPickup')" :value="1" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <!--分区方案名称-->
            <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="partitionId">
              <el-select
                v-model="formData.partitionId"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                :remote-method="getSubregionData"
                filterable
                remote
                style="width: 100%;"
                @change="getOtherFeeDetail()"
              >
                <el-option
                  v-for="item in partitionSchemeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--报价类型-->
            <el-form-item :label="$t('basicData.QuoteType')" prop="offerType">
              <el-select
                v-model="formData.offerType"
                :placeholder="$t('basicData.PleaseSelect')"
                style="width: 100%;"
                @change="offerTypeChange(formData.offerType)"
              >
                <el-option
                  v-for="item in quoteTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.offerType === 3 || formData.offerType === 2" :span="6">
            <!--应收主体-->
            <el-form-item :label="$t('basicData.businessPrincipal')" prop="providerInfoId">
              <el-select
                v-model="formData.providerInfoId"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                :remote-method="getProviderPage"
                filterable
                remote
                reserve-keyword
                style="width: 100%;"
                @change="providerInfoChange(formData.providerInfoId)"
                @focus="getProviderPage()"
              >
                <el-option
                  v-for="item in businessPrincipalList"
                  :key="item.id"
                  :label="item.providerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.offerType === 4" :span="6">
            <!--客户-->
            <el-form-item :label="$t('receivablePayable.customer')" prop="customerIds">
              <el-select
                v-model="formData.customerIds"
                :multiple="editData.id ? false : true"
                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"
                :remote-method="getCustomerPage"
                clearable
                filterable
                remote
                reserve-keyword
                style="width: 100%;"
                @change="customerChange"
              >
                <el-option
                  v-for="(item, ind) in customerList"
                  :key="ind"
                  :label="item.customerAlias + '(' + item.username + ')'"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="formData.offerType === 3" :span="6">
            <!--网点-->
            <el-form-item :label="$t('basicData.Outlets')" prop="siteIds">
              <el-select
                v-model="formData.siteIds"
                :multiple="editData.id ? false : true"
                :placeholder="$t('basicData.PleaseSelect')"
                filterable
                style="width: 100%;"
                @change="siteIdChange(formData.siteIds)"
              >
                <!--请先选择应收主体-->
                <el-option v-if="!formData.providerInfoId" value=""><span
                  style="color:#f00;font-weight: bold"
                >{{ $t('basicData.SelectTheBusinessPrincipalFirst') }}</span>
                </el-option>
                <el-option
                  v-for="item in outletsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="title">
        <!--价格详情-->
        {{ $t('basicData.PriceDetails') }}
      </div>
      <div style="margin-bottom: 20px;clear: both;">
        <!--快递类型-->
        <div style="float:left;">
          <el-radio-group v-model="formData.expressType" size="small">
            <el-radio-button v-for="item in EXPRESS_TYPE" :key="item.value" :label="item.value">{{ item.name }}</el-radio-button>

            <!--普快-->
            <!-- <el-radio-button :label="2">{{ $t('basicData.OrdinaryCourier') }}</el-radio-button> -->
            <!--特快-->
            <!-- <el-radio-button :label="1">{{ $t('basicData.express') }}</el-radio-button> -->
            <!--邮政-->
            <!-- <el-radio-button :label="3">{{ $t('basicData.postal') }}</el-radio-button> -->
          </el-radio-group>

          <!-- 货物类型 -->
          <el-radio-group v-model="formData.cargoType" size="small" style="margin-left: 20px;">
            <el-radio-button v-for="item in CARGO_TYPE" :key="item.value" :label="item.value">{{ item.name }}</el-radio-button>
            <!--正常-->
            <!-- <el-radio-button :label="0">正常</el-radio-button> -->
            <!--大件-->
            <!-- <el-radio-button :label="1">大件</el-radio-button> -->
          </el-radio-group>
        </div>

        <!--分区-->
        <div v-if="formData.partitionId" style="float:left;margin-left: 20px;">
          <el-radio-group v-model="formData.partitionAffiliateId" size="small">
            <el-radio-button
              v-for="(item, ind) in subregionList"
              :key="ind"
              :label="item.id"
            >
              {{ item.partitionIdentifier }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div style="clear: both;" />
      </div>
      <div class="priceTableForm" style="margin-top: 10px;">
        <!--增加一行-->
        <el-form
          ref="tableForm1"
          :model="formData"
          class="demo-ruleForm"
          label-width="0"
        >
          <!--普通规则-->
          <div style="margin-bottom: 20px;">
            <div style="margin-bottom: 10px;">
              <el-button
                v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
                size="small"
                type="primary"
                @click="handleAdd()"
              >{{ $t('basicData.AddARow') }}
              </el-button>
              <el-popover
                placement="bottom-end"
                trigger="hover"
              >
                <div>
                  <import-download
                    :key="exportKey"
                    :expense-type-list="expenseTypeList"
                    :import-type="1"
                    :table-data="{ formData, subregionList }"
                    :table-data-obj="tableDataObj.collectFeesList"
                    @importPartition="importPartition"
                  />
                </div>
                <!--导入/下载-->
                <el-button
                  slot="reference" plain size="small"
                  style="margin-left: 10px"
                >
                  {{ $t('basicData.ImportDownload') }}
                </el-button>
              </el-popover>
              <!--清空报价-->
              <el-popover
                placement="bottom-end"
                trigger="hover"
              >
                <div>
                  <!--清空当前分区-->
                  <div style="margin-bottom: 10px;">
                    <el-button
                      size="small"
                      type="primary"
                      @click="clearOffers(1)"
                    >{{ $t('basicData.EmptyCurrentPartition') }}
                    </el-button>
                  </div>
                  <!--清空所有分区-->
                  <div>
                    <el-button
                      size="small"
                      type="primary"
                      @click="clearOffers(2)"
                    >{{ $t('basicData.EmptyAllPartitions') }}
                    </el-button>
                  </div>
                </div>
                <!--清空报价-->
                <el-button
                  slot="reference"
                  v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
                  plain
                  size="small"
                  style="margin-left: 10px"
                >
                  {{ $t('basicData.ClearTheOffer') }}
                </el-button>
              </el-popover>
            </div>
            <el-table
              :key="tableKey"
              :data="formData.collectFeesList"
              border
              class="priceTable"
            >
              <!--费用类型-->
              <el-table-column :label="$t('basicData.ExpenseType')" min-width="180">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.ExpenseType') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.feeTypeCode'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.feeTypeCode"
                        :placeholder="$t('basicData.PleaseSelect')"
                        style="width: 100%;"
                        @change="chargeTypeChange(scope)"
                      >
                        <el-option
                          v-for="item in expenseTypeList"
                          :key="item.id"
                          :label="item.displayName"
                          :value="item.code"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--计费类型-->
              <el-table-column
                v-if="formData.collectionCategory !== 1"
                :label="$t('basicData.chargeType')"
                min-width="180"
              >
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.chargeType') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.billingType'"
                      :rules="{ required: !formData.collectionCategory, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.billingType"
                        :placeholder="$t('basicData.PleaseSelect')"
                        style="width: 100%;"
                        @change="chargeTypeChange(scope)"
                      >
                        <el-option :key="1" :label="$t('newOperationCenter.Weight')" :value="1" />
                        <el-option :key="2" :label="$t('collectionCenter.ballot')" :value="2" />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--揽收类型-->
              <el-table-column
                v-if="formData.businessType === 1 && formData.collectionCategory === 0"
                :label="$t('newOrder.CollectionType')"
                min-width="180"
              >
                <template slot="header">
                  <div>
                    <span v-if="formData.businessType === 1" style="color: red;">*</span>
                    <span>{{ $t('newOrder.CollectionType') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.collectType'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.collectType"
                        :placeholder="$t('basicData.PleaseSelect')"
                        style="width: 100%;"
                      >
                        <!--上门交件-->
                        <el-option :key="1" :label="$t('collectionCenter.dropOff')" :value="1" />
                        <!--上门揽收-->
                        <el-option :key="2" :label="$t('collectionCenter.doorPickUp')" :value="2" />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <template v-if="formData.collectionCategory === 1">
                <!--箱数开始(不包含)-->
                <el-table-column :label="$t('basicData.BoxCountStartsNotIncluded')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.BoxCountStartsNotIncluded') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.startCases'"
                        :rules="{
                          required: false,
                          negative: '',
                          suffix: 2,
                          negative1: 1,
                          package: scope.row.endCases,
                          validator: Integer0,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <el-input v-model.trim="scope.row.startCases" />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--箱数结束(包含)-->
                <el-table-column :label="$t('basicData.EndOfCaseCountIncluded')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.EndOfCaseCountIncluded') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.endCases'"
                        :rules="{
                          required: false,
                          negative: '',
                          suffix: 2,
                          negative1: 2,
                          package: scope.row.startCases,
                          validator: Integer0,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <el-input v-model.trim="scope.row.endCases" />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--价格-->
                <el-table-column :label="$t('basicData.Price')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.Price') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.price'"
                      :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                    >
                      <el-input v-model="scope.row.price" />
                    </el-form-item>
                  </template>
                </el-table-column>
                <!--超出价格-->
                <el-table-column :label="$t('basicData.ExceedingThePrice')" min-width="180">
                  <template slot="header">
                    <span>{{ $t('basicData.ExceedingThePrice') }}</span>
                  </template>
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.overExtraPrice'"
                      :rules="{ required: false, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                    >
                      <el-input v-model="scope.row.overExtraPrice" />
                    </el-form-item>
                  </template>
                </el-table-column>
              </template>
              <template v-else>
                <!--开始（不包含）-->
                <el-table-column :label="$t('basicData.Begin')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.Begin') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.billingType === 2">
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.startWeight'"
                        :rules="{
                          required: false,
                          negative: '',
                          suffix: 2,
                          negative1: 1,
                          package: scope.row.endWeight,
                          validator: priceType,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <div style="display: flex;">
                          <div style="flex: 1">
                            <el-input v-model.trim="scope.row.startWeight" />
                          </div>
                          <div style="width: 35px;text-align: right;">(KG)</div>
                        </div>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.startWeight'"
                        :rules="{
                          required: true,
                          negative: '',
                          suffix: 2,
                          negative1: 1,
                          package: scope.row.endWeight,
                          validator: priceType,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <div style="display: flex;">
                          <div style="flex: 1">
                            <el-input v-model.trim="scope.row.startWeight" />
                          </div>
                          <div style="width: 35px;text-align: right;">(KG)</div>
                        </div>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--结束（包含）-->
                <el-table-column :label="$t('basicData.end')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.end') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.billingType === 2">
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.endWeight'"
                        :rules="{
                          required: false,
                          negative: '',
                          suffix: 2,
                          negative1: 2,
                          package: scope.row.startWeight,
                          validator: priceType,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <div style="display: flex;">
                          <div style="flex: 1">
                            <el-input v-model.trim="scope.row.endWeight" />
                          </div>
                          <div style="width: 35px;text-align: right;">(KG)</div>
                        </div>
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item
                        :prop="'collectFeesList.' + scope.$index + '.endWeight'"
                        :rules="{
                          required: true,
                          negative: '',
                          suffix: 2,
                          negative1: 2,
                          package: scope.row.startWeight,
                          validator: priceType,
                          trigger: ['blur', 'change']
                        }"
                      >
                        <div style="display: flex;">
                          <div style="flex: 1">
                            <el-input v-model.trim="scope.row.endWeight" />
                          </div>
                          <div style="width: 35px;text-align: right;">(KG)</div>
                        </div>
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--价格-->
                <el-table-column :label="$t('basicData.Price')" min-width="180">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('basicData.Price') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <!-- :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }" -->
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.price'"
                      :rules="rules.price"
                    >
                      <div style="display: flex;">
                        <div style="flex: 1">
                          <el-input v-model.trim="scope.row.price" />
                        </div>
                        <div
                          v-if="scope.row.billingType === 2"
                          :style="{ width: $i18n.locale!=='zh' ? '50px' : '25px' }"
                          style="text-align: right;"
                        >
                          /{{ $t('basicData.ticket') }}
                        </div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <!--超出价格-->
                <el-table-column :label="$t('basicData.ExceedingThePrice')" min-width="180">
                  <template slot="header">
                    <span>{{ $t('basicData.ExceedingThePrice') }}</span>
                  </template>
                  <template slot-scope="scope">

                    <!-- :rules="{ required: false, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }" -->
                    <el-form-item
                      :prop="'collectFeesList.' + scope.$index + '.overWeightExtraPrice'"

                      :rules="rules.overWeightExtraPrice"
                    >
                      <div style="display: flex;">
                        <div style="flex: 1">
                          <el-input v-model.trim="scope.row.overWeightExtraPrice" />
                        </div>
                        <div style="width: 35px;text-align: right;">/KG</div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
              </template>
              <!--操作-->
              <el-table-column :label="$t('basicData.operate')" width="120">
                <template slot-scope="scope">
                  <!--确定删除吗？-->
                  <el-popconfirm
                    :title="$t('basicData.AreYouSureYouWantToDelete')"
                    @confirm="handleDel(scope.$index, 'collectFeesList')"
                  >
                    <!--删除-->
                    <el-button slot="reference" size="mini" type="danger">{{ $t('basicData.Delete') }}</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--增加其他规则-->
          <div>
            <div style="margin-bottom: 10px;">
              <el-button
                v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
                size="small"
                type="primary"
                @click="handleAdd1()"
              >{{ $t('basicData.AddAdditionalRules') }}
              </el-button>
              <el-popover
                placement="bottom-end"
                trigger="hover"
              >
                <div>
                  <import-download
                    :key="exportKey"
                    :expense-type-list="expenseTypeList"
                    :import-type="2"
                    :miscellaneous-list="miscellaneousList"
                    :other-rules-raw-data="otherRulesRawData"
                    :table-data="{ formData, subregionList }"
                    :table-data-obj="tableDataObj.collectFeesList"
                    @importPartition="importPartition"
                  />
                </div>
                <!--导入/下载-->
                <el-button
                  slot="reference" plain size="small"
                  style="margin-left: 10px"
                >
                  {{ $t('basicData.ImportDownload') }}
                </el-button>
              </el-popover>
              <!--清空报价-->
              <el-popover
                placement="bottom-end"
                trigger="hover"
              >
                <div>
                  <!--清空当前分区-->
                  <div style="margin-bottom: 10px;">
                    <el-button
                      size="small"
                      type="primary"
                      @click="clearOtherOffers(1)"
                    >{{ $t('basicData.EmptyCurrentPartition') }}
                    </el-button>
                  </div>
                  <!--清空所有分区-->
                  <div>
                    <el-button
                      size="small"
                      type="primary"
                      @click="clearOtherOffers(2)"
                    >{{ $t('basicData.EmptyAllPartitions') }}
                    </el-button>
                  </div>
                </div>
                <!--清空报价-->
                <el-button
                  slot="reference"
                  v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
                  plain
                  size="small"
                  style="margin-left: 10px"
                >
                  {{ $t('basicData.ClearTheOffer') }}
                </el-button>
              </el-popover>
            </div>
            <el-table
              :key="tableKey"
              :data="formData.feeTypeList"
              border
            >
              <!--费用类型-->
              <el-table-column :label="$t('basicData.ExpenseType')" width="200">
                <template slot="header">
                  <div>
                    <span style="color: red;">*</span>
                    <span>{{ $t('basicData.ExpenseType') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item
                      :prop="'feeTypeList.' + scope.$index + '.feeTypeCode'"
                      :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                    >
                      <el-select
                        v-model="scope.row.feeTypeCode"
                        :placeholder="$t('basicData.PleaseSelect')"
                        style="width: 100%;"
                        @change="feeTypeCodeChange(scope.row.feeTypeCode, scope.$index)"
                        @focus="disabledFeeTyp()"
                      >
                        <el-option
                          v-for="(item, ind) in miscellaneousList"
                          :key="ind"
                          :disabled="selectFeeTypeCode.includes(item.code)"
                          :label="item.displayName"
                          :value="item.code"
                        />
                      </el-select>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <!--计费规则-->
              <el-table-column :label="$t('basicData.BillingRules')">
                <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.specialRules === 0">
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.taxRate'"
                          :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                        >
                          <!--商品申报金额-->
                          {{ $t('basicData.TheAmountDeclaredForTheProduct') }} *
                          <el-input v-model="scope.row.taxRate" style="display: inline-block;width: 120px;" />
                          %
                        </el-form-item>
                      </div>
                      <div style="display: inline-block;margin-left: 20px;">
                        <!--
                          暂时不需要校验了
                          :prop="'feeTypeList.' + scope.$index + '.price'"
                          :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                        -->
                        <el-form-item>
                          <!--最低标准-->
                          {{ $t('basicData.MinimumStandards') }}
                          <el-input v-model="scope.row.price" style="display: inline-block;width: 120px;" />
                          BRL
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="scope.row.specialRules === 1">
                      <el-form-item
                        :prop="'feeTypeList.' + scope.$index + '.taxRate'"
                        :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                      >
                        <!--（快递费 + 快递税（含保险））-->
                        <span v-if="formData.businessType === 0">{{
                          '（' + $t('basicData.CourierFee') + ' + ' + $t('basicData.CourierTax') + '(ISS/ICMS)' + '（' + $t('basicData.InsuranceIncluded') + '））'
                        }}</span>
                        <!--（揽收费 + 揽收税（含保险））-->
                        <span v-if="formData.businessType === 1">{{
                          '（' + $t('basicData.CollectFees') + ' + ' + $t('basicData.CollectTaxes') + '(ISS/ICMS)' + '（' + $t('basicData.InsuranceIncluded') + '））'
                        }}</span>
                        <!--（派件费 + 派件税（含保险））-->
                        <span v-if="formData.businessType === 2">{{
                          '（' + $t('basicData.DeliveryFee') + ' + ' + $t('basicData.DeliveryTax') + '(ISS/ICMS)' + '（' + $t('basicData.InsuranceIncluded') + '））'
                        }}</span>
                        *
                        <el-input v-model="scope.row.taxRate" style="display: inline-block;width: 120px;" />
                        %
                      </el-form-item>
                    </div>
                    <div v-if="scope.row.specialRules === 2">
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.taxRateOne'"
                        >
                          <!--快递费-->
                          <span v-if="formData.businessType === 0">{{ $t('basicData.CourierFee') }}</span>
                          <!--揽收费-->
                          <span v-if="formData.businessType === 1">({{
                            $t('basicData.CollectFees') + ' + ' + $t('basicData.insurance')
                          }})</span>
                          <!--快递费-->
                          <span v-if="formData.businessType === 2">{{ $t('basicData.DeliveryFee') }}</span>
                          / (1 - ISS{{ $t('GlobalSetObj.rate') }})
                        </el-form-item>
                      </div>
                      *
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.taxRate'"
                        >
                          ISS{{ $t('GlobalSetObj.rate') }}
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="scope.row.specialRules === 3">
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.taxRateOne'"
                        >
                          <!--快递费-->
                          <span v-if="formData.businessType === 0">{{ $t('basicData.CourierFee') }}</span>
                          <!--揽收费-->
                          <span v-if="formData.businessType === 1">({{
                            $t('basicData.CollectFees') + ' + ' + $t('basicData.insurance')
                          }})</span>
                          <!--快递费-->
                          <span v-if="formData.businessType === 2">{{ $t('basicData.DeliveryFee') }}</span>
                          / (1 - ICMS{{ $t('GlobalSetObj.rate') }}) * ICMS{{ $t('GlobalSetObj.rate') }}
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="scope.row.specialRules === 7">
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.taxRateOne'"
                        >
                          <!--快递费-->
                          <span v-if="formData.businessType === 0">{{ $t('basicData.CourierFee') }}</span>
                          <!--揽收费-->
                          <span v-if="formData.businessType === 1">({{
                            $t('basicData.CollectFees') + ' + ' + $t('basicData.insurance')
                          }})</span>
                          <!--快递费-->
                          <span v-if="formData.businessType === 2">{{ $t('basicData.DeliveryFee') }}</span> *
                          ISS{{ $t('GlobalSetObj.rate') }}
                        </el-form-item>
                      </div>
                    </div>
                    <!--                    <div v-if="scope.row.specialRules === 4">-->
                    <!--                      <el-form-item-->
                    <!--                        :prop="'feeTypeList.' + scope.$index + '.price'"-->
                    <!--                        :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"-->
                    <!--                      >-->
                    <!--                        &lt;!&ndash;称重重量  加收  票&ndash;&gt;-->
                    <!--                        {{ $t('orderCenterCont.WeighAndWeigh') }} >= 50KG {{ $t('basicData.AdditionalCharges') }} <el-input v-model="scope.row.price" style="display: inline-block;width: 120px;" /> BRL/{{ $t('basicData.ticket') }}-->
                    <!--                      </el-form-item>-->
                    <!--                    </div>-->
                    <div v-if="scope.row.specialRules === 4"><!--'FY011'-->
                      <div>
                        <div style="display: inline-block;">
                          <el-form-item
                            :prop="'feeTypeList.' + scope.$index + '.weight'"
                            :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                          >
                            <!--重量-->
                            {{ $t('operationCenter.Weight') }} >=
                            <el-input v-model="scope.row.weight" style="display: inline-block;width: 120px;" />
                            KG
                          </el-form-item>
                        </div>
                        <div style="display: inline-block;margin-left: 20px;">
                          <el-form-item
                            :prop="'feeTypeList.' + scope.$index + '.price'"
                            :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                          >
                            <!--增加价格-->
                            {{ $t('basicData.IncreaseThePrice') }}
                            <el-input v-model="scope.row.price" style="display: inline-block;width: 120px;" />
                            BRL
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <!-- 5:*BRL/*KG(向上取整),   6:*BRL/票 -->
                    <div v-if="scope.row.specialRules === 5">
                      <div style="display: inline-block;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.weight'"
                          :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                        >
                          <!-- *BRL/*KG(向上取整) -->
                          /
                          <el-input v-model="scope.row.weight" style="display: inline-block;width: 120px;" />
                          KG（{{ $t('basicData.roundUp') }}）
                        </el-form-item>
                      </div>
                      <div style="display: inline-block;margin-left: 10px;">
                        <el-form-item
                          :prop="'feeTypeList.' + scope.$index + '.price'"
                          :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                        >
                          {{ $t('basicData.increase') }}
                          <el-input v-model="scope.row.price" style="display: inline-block;width: 120px;" />
                          BRL
                        </el-form-item>
                      </div>
                    </div>
                    <div v-if="scope.row.specialRules === 6">
                      <el-form-item
                        :prop="'feeTypeList.' + scope.$index + '.price'"
                        :rules="{ required: true, suffix: 2, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
                      >
                        <!-- *BRL/票 -->
                        <el-input v-model="scope.row.price" style="display: inline-block;width: 120px;" />
                        /{{ $t('basicData.ticket') }}
                      </el-form-item>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!--操作-->
              <el-table-column :label="$t('basicData.operate')" width="120">
                <template slot-scope="scope">
                  <!--确定删除吗？-->
                  <el-popconfirm
                    :title="$t('basicData.AreYouSureYouWantToDelete')"
                    @confirm="handleDel(scope.$index, 'feeTypeList')"
                  >
                    <!--删除-->
                    <el-button slot="reference" size="mini" type="danger">{{ $t('basicData.Delete') }}</el-button>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </div>
    </div>
    <div
      v-permit:remove="'btn:finance:basicData:quotationManagement:edit'"
      class="addFooter"
    >
      <!--取消-->
      <el-button @click="handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
      <!--保存-->
      <el-button type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
    </div>
    <!--保存提示-->
    <el-dialog
      :append-to-body="true"
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      width="400"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 8px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiGetAffiliateById, apiPartitionPage, feePage } from '@/api/finance/partition';
import {
  apiListByOfferPriceId,
  apiOfferPriceAdd,
  apiOfferPriceDetail,
  apiOfferPriceDetailEdit,
  apiProviderPage,
  apiSiteQuery
} from '@/api/finance/basicData';
import { apiCustomerPage } from '@/api/customer';
import { cloneDeep, groupBy, get } from 'lodash-es';
import { ROWS, VALIDATE_PRICE_OBJ, VALIDATE_OTHER_PRICE_OBJ, EXPRESS_TYPE, CARGO_TYPE, fromValidate } from './utils';

export default {
  components: {
    'import-download': () => import('./ImportDownload')
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    operationType: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    // 正负保留小数点
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative1 === 2) {
                if (parseFloat(value) <= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else if (parseFloat(value) <= 0) {
                  return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
                }
              } else {
                if (parseFloat(value) >= parseFloat(rule.package)) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
          } else {
            this.adopt = true;
            if (rule.negative1 === 2) {
              if (parseFloat(value) <= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
              } else if (parseFloat(value) <= 0) {
                return callback(new Error(this.$i18n.t('basicData.MustBeGreaterThan0'))); // 必须大于0
              }
            } else {
              if (parseFloat(value) >= parseFloat(rule.package)) {
                return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
              } else {
                return callback();
              }
            }
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };

    // 价格校验
    const priceCheck = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$i18n.t('GlobalSetObj.pleaseInput'))); // 请输入
      }

      // 是否是数字
      if (Number.isNaN(Number(value))) {
        return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
      }
      return callback();
    };

    // 超出价格校验
    const overWeightExtraPriceCheck = (rule, value, callback) => {
      // 是否是数字
      if (Number.isNaN(Number(value))) {
        return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // '格式不正确'
      }
      return callback();
    };

    // 大于0的正整数
    const Integer0 = (rule, value, callback) => {
      let reg;
      if (rule.negative === 1) {
        reg = /(^[1-9]\d*$)/;
      } else {
        reg = /(^[0-9]\d*$)/;
      }
      if (rule.required) {
        if (value !== null && value !== '' && value !== undefined) {
          if (reg.test(value) === false) {
            if (rule.negative === 1) {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThan0'))); // '必须是大于0的正整数'
            } else {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThanOrEqual0'))); // '必须是大于或等于0的正整数'
            }
          } else {
            if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
              if (rule.negative === 1) {
                if (value <= rule.package) {
                  return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
                } else {
                  return callback();
                }
              } else {
                if (value >= rule.package) {
                  return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
                } else {
                  return callback();
                }
              }
            }
            return callback();
          }
        } else {
          return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
        }
      } else {
        if (value !== null && value !== '' && value !== undefined) {
          if (reg.test(value) === false) {
            if (rule.negative === 1) {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThan0'))); // '必须是大于0的正整数'
            } else {
              return callback(new Error(this.$i18n.t('basicData.MustPositiveIntegerGreaterThanOrEqual0'))); // '必须是大于或等于0的正整数'
            }
          } else {
            return callback();
          }
        } else {
          return callback();
        }
      }
    };
    // 有效期校验
    const checkBeginTime = (rule, value, callback) => {
      if (this.operationType === 2 && this.editData && this.editData.offerPriceStatus === 1) {
        callback();
      }
      if (!value) {
        return callback(new Error(this.$i18n.t('basicData.CannotBeEmpty'))); // '不能为空'
      }
      // const valTime = date2TimeStamp(value);
      // const nowTime = Date.now();
      // if (valTime <= nowTime) {
      //   return callback(new Error(this.$i18n.t('basicData.CannotBeLessThanTheCurrentDate'))); // 日期不能小于当前日期
      // }
      callback();
    };
    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
          // '长度在 2 到 50 个字符'
          {
            min: 2,
            max: 50,
            message: this.$i18n.t('basicData.TheLengthBetween2And50Characters'),
            trigger: ['blur', 'change']
          }
        ],
        startTime: [
          {
            required: !(this.operationType === 2 && this.editData && this.editData.offerPriceStatus === 1),
            validator: checkBeginTime,
            trigger: ['blur', 'change']
          }
        ],
        businessType: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        partitionId: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        offerType: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        offerAttr: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        providerInfoId: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        siteIds: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        customerIds: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }
        ],
        price: [
          {
            required: true,
            validator: priceCheck,
            trigger: ['blur', 'change']
          }
        ],

        overWeightExtraPrice: [
          {
            required: false,
            validator: overWeightExtraPriceCheck,
            trigger: ['blur', 'change']
          }
        ]
      },
      errData: [],
      dialogVisible: false,
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      partitionSchemeList: [], // 分区方案
      quoteTypeList: [
        { name: this.$t('basicData.StandardPrice'), id: 1 }, // '标准价'
        { name: this.$t('basicData.BusinessEntityPrice'), id: 2 }, // '应收主体价'
        { name: this.$t('basicData.SitePrice'), id: 3 }, // '网点价'
        { name: this.$t('basicData.SpecifyCustomerPrice'), id: 4 } // '指定客户价'
      ], // 报价类型
      businessPrincipalList: [], // 应收主体
      outletsList: [], // 网点
      subregionList: [], // 分区
      expenseTypeList: [], // 费用类型选项
      formData: {
        name: '', // 报价名称
        offerType: '', // 报价类型 1:标准价 2:应收主体价 3:网点价
        offerAttr: 1, // 使用类型 1:应收 2:应付
        providerInfoId: '', // 应收主体
        customerIds: [], // 客户
        businessType: '', // 业务类型
        collectionCategory: 0, // 揽收类型
        cargoType: 0, // 货物类型  0：正常 1：大件
        providerInfoName: '',
        // siteId: [], // 网点id
        siteIds: [], // 网点id
        siteName: '', // 网点名称,
        partitionId: '', // 分区方案id,
        partitionName: '', // 分区方案名称
        startTime: '', // 开始时间
        expressType: 2, // 快递类型
        partitionAffiliateId: '', // 分区id
        collectFeesList: [],
        feeTypeList: [] // 其他规则列表
      },
      tableDataObj: {
        collectFeesList: cloneDeep(ROWS),
        feeTypeList: cloneDeep(ROWS)
      },
      Integer0,
      priceType,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 3600 * 1000; // 不能选择生效日期在当前服务时间之前
        }
      },
      exportKey: new Date().getTime(),
      customerList: [],
      selectCustomer: [],
      idCustomerList: [],
      miscellaneousList: [],
      selectFeeTypeCode: [],
      tableKey: new Date().getTime(),
      otherRulesRawData: [] // 其它规则接口响应原始数据，在其它规则导出报价时使用，这是前端未处理过的数据
    };
  },
  watch: {
    // 快递类型改变
    'formData.expressType'(val) {
      if (val === undefined) { return; }
      // 快递类型 -> 货物类型 -> 分区
      const { cargoType, partitionAffiliateId } = this.formData;
      const { collectFeesList, feeTypeList } = this.tableDataObj;

      // 定义了
      if (Array.isArray(collectFeesList[val][cargoType][partitionAffiliateId])) {
        this.tableDataObj.collectFeesList[val][cargoType][partitionAffiliateId] = collectFeesList[val][cargoType][partitionAffiliateId];
        this.formData['collectFeesList'] = collectFeesList[val][cargoType][partitionAffiliateId];
      } else {
        // 未定义
        this.$set(this.tableDataObj.collectFeesList[val][cargoType], partitionAffiliateId, []);
        this.formData['collectFeesList'] = [];
      }

      if (Array.isArray(feeTypeList[val][cargoType][partitionAffiliateId])) {
        this.tableDataObj.feeTypeList[val][cargoType][partitionAffiliateId] = feeTypeList[val][cargoType][partitionAffiliateId];
        this.formData['feeTypeList'] = feeTypeList[val][cargoType][partitionAffiliateId];
      } else {
        // 未定义
        this.$set(this.tableDataObj.feeTypeList[val][cargoType], partitionAffiliateId, []);
        this.formData['feeTypeList'] = [];
      }
    },

    // 货物类型变化
    'formData.cargoType'(val) {
      if (val === undefined) { return; }

      const { expressType, partitionAffiliateId } = this.formData;
      const { collectFeesList, feeTypeList } = this.tableDataObj;

      // 存在
      if (Array.isArray(collectFeesList[expressType][val][partitionAffiliateId])) {
        this.tableDataObj.collectFeesList[expressType][val][partitionAffiliateId] = collectFeesList[expressType][val][partitionAffiliateId];
        this.formData['collectFeesList'] = collectFeesList[expressType][val][partitionAffiliateId];
      } else {
        // 未定义
        this.$set(this.tableDataObj.collectFeesList[expressType][val], partitionAffiliateId, []);
        this.formData['collectFeesList'] = [];
      }

      if (Array.isArray(feeTypeList[expressType][val][partitionAffiliateId])) {
        this.tableDataObj.feeTypeList[expressType][val][partitionAffiliateId] = feeTypeList[expressType][val][partitionAffiliateId];
        this.formData['feeTypeList'] = feeTypeList[expressType][val][partitionAffiliateId];
      } else {
        // 未定义
        this.$set(this.tableDataObj.feeTypeList[expressType][val], partitionAffiliateId, []);
        this.formData['feeTypeList'] = [];
      }
    },

    // 分区变化
    'formData.partitionAffiliateId'(val) {
      if (val === undefined) { return; }

      // 快递类型 -> 货物类型 -> 分区
      const { expressType, cargoType } = this.formData;
      const { collectFeesList, feeTypeList } = this.tableDataObj;

      // this.tableDataObj.collectFeesList[expressType][val] = collectFeesList[expressType][cargoType][val] || [];
      // this.formData['collectFeesList'] = collectFeesList[expressType][cargoType][val];
      // this.tableDataObj.feeTypeList[expressType][val] = feeTypeList[expressType][cargoType][val] || [];
      // this.formData['feeTypeList'] = feeTypeList[expressType][val];

      if (Array.isArray(collectFeesList[expressType][cargoType][val])) {
        this.tableDataObj.collectFeesList[expressType][cargoType][val] = collectFeesList[expressType][cargoType][val];
        this.formData['collectFeesList'] = collectFeesList[expressType][cargoType][val];
      } else {
        // 未定义
        this.$set(this.tableDataObj.collectFeesList[expressType][cargoType], val, []);
        this.formData['collectFeesList'] = [];
      }

      if (Array.isArray(feeTypeList[expressType][cargoType][val])) {
        this.tableDataObj.feeTypeList[expressType][cargoType][val] = feeTypeList[expressType][cargoType][val];
        this.formData['feeTypeList'] = feeTypeList[expressType][cargoType][val];
      } else {
        // 未定义
        this.$set(this.tableDataObj.feeTypeList[expressType][cargoType], val, []);
        this.formData['feeTypeList'] = [];
      }
    }
  },

  created() {
    this.EXPRESS_TYPE = EXPRESS_TYPE;
    this.CARGO_TYPE = CARGO_TYPE;

    const edit = cloneDeep(this.editData);
    // 获取客户
    this.getCustomerPage();
    // 获取应收主体选项
    this.getProviderPage();
    // 获取分区方案
    this.getSubregionData();
    // 获取费用类型
    this.getExpenseTypeList();
    if (edit.id) {
      // 编辑时获取已选客户
      this.getIdSelect();
      this.selectCustomer = [
        { id: edit.customerId, customerAlias: edit.customerName }
      ];

      this.formData = {
        businessType: edit.businessType,
        collectionCategory: edit.collectionCategory,
        name: edit.name, // 报价名称
        offerType: edit.offerType, // 报价类型 1:标准价 2:应收主体价 3:网点价
        offerAttr: parseInt(edit.offerAttr), // 使用类型 1:应收 2:应付
        providerInfoId: parseInt(edit.providerInfoId), // 应收主体
        customerIds: edit.customerId,
        providerInfoName: edit.providerInfoName,
        // siteId: siteIdArr, // 网点id
        siteIds: edit.siteId, // 网点id
        cargoType: 0, // 货物类型  0：正常 1：大件
        siteName: edit.siteName, // 网点名称,
        partitionId: edit.partitionId, // 分区方案id,
        partitionName: edit.partitionName, // 分区方案名称
        startTime: edit.startTime, // 开始时间
        partitionAffiliateId: edit.partitionAffiliateId, // 分区
        expressType: 2, // 快递类型
        collectFeesList: [],
        feeTypeList: [] // 其他规则列表
      };
      if (edit.partitionId) {
        // 获取分区方案详情
        this.getOfferAffiliateList(edit.partitionId);
      }
      if (edit.providerInfoId) {
        // 获取站点
        this.getSiteQuery(edit.providerInfoId);
      }
      // 查看报价方案详情和其他规则
      this.asyncGetAffiliateById(edit.id);
      this.exportKey = new Date().getTime();
    }
  },
  methods: {
    // 清空其他规则报价
    clearOtherOffers(type) {
      // type 1:清空当前分区 2:清空所有分区
      if (type === 1) {
        const { expressType, cargoType, partitionAffiliateId } = this.formData;
        this.formData['feeTypeList'] = [];
        this.tableDataObj.feeTypeList[expressType][cargoType][partitionAffiliateId] = [];
      } else {
        this.subregionList.map(item => {
          for (const key in this.tableDataObj.feeTypeList) {
            for (const k in this.tableDataObj.feeTypeList[key]) {
              this.tableDataObj.feeTypeList[key][k][item.id] = [];
            }
          }
          // this.tableDataObj.feeTypeList[1][item.id] = [];
          // this.tableDataObj.feeTypeList[2][item.id] = [];
          // this.tableDataObj.feeTypeList[3][item.id] = [];
        });
        this.formData['feeTypeList'] = [];
      }
    },
    // 清空报价
    clearOffers(type) {
      // type 1:清空当前分区 2:清空所有分区
      if (type === 1) {
        const { expressType, cargoType, partitionAffiliateId } = this.formData;
        this.formData['collectFeesList'] = [];
        this.tableDataObj.collectFeesList[expressType][cargoType][partitionAffiliateId] = [];
      } else {
        this.subregionList.map(item => {
          for (const key in this.tableDataObj.collectFeesList) {
            for (const k in this.tableDataObj.collectFeesList[key]) {
              this.tableDataObj.collectFeesList[key][k][item.id] = [];
            }
          }

          // this.tableDataObj.collectFeesList[1][item.id] = [];
          // this.tableDataObj.collectFeesList[2][item.id] = [];
          // this.tableDataObj.collectFeesList[3][item.id] = [];
        });
        this.formData['collectFeesList'] = [];
      }
    },
    // 获取已选费用类型code  =>
    disabledFeeTyp() {
      this.selectFeeTypeCode = [];
      const dataCode = []; // ['FY005', 'FY006', 'FY007', 'FY008', 'FY009', 'FY011'];
      this.miscellaneousList.map(item => {
        if (dataCode.includes(item.code) === false) {
          dataCode.push(item.code);
        }
      });
      this.formData.feeTypeList.map(item => {
        if (dataCode.includes(item.feeTypeCode) === true) {
          if (this.selectFeeTypeCode.includes(item.feeTypeCode) === false) {
            this.selectFeeTypeCode.push(item.feeTypeCode);
          }
        }
      });
    },
    // 其他规则费用类型变化
    feeTypeCodeChange(code, index) {
      this.miscellaneousList.map(item => {
        if (code === item.code) {
          this.formData.feeTypeList[index].specialRules = item.specialRules;
        }
      });
    },
    // 编辑时获取已选客户
    getIdSelect() {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          id: this.editData.customerId
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code === 200) {
          this.idCustomerList = res.data.records;
        }
      });
    },
    // 选择客户变化
    customerChange() {
      if (this.editData.id) {
        this.selectCustomer = [];
        this.customerList.map(item => {
          if (item.id === this.formData.customerIds) {
            this.selectCustomer.push({
              id: item.id,
              customerAlias: item.customerAlias
            });
          }
        });
      } else {
        const selectedCus = [];
        this.selectCustomer.map(item => {
          if (this.formData.customerIds.includes(item.id) === true) {
            selectedCus.push(item);
          }
        });
        this.customerList.map(item => {
          this.formData.customerIds.map(list => {
            if (item.id === list) {
              selectedCus.push({
                id: item.id,
                customerAlias: item.customerAlias
              });
            }
          });
        });
        this.selectCustomer = selectedCus;
        const newObj = {};
        this.selectCustomer = this.selectCustomer.reduce((preVal, curVal) => {
          newObj[curVal.id] ? '' : newObj[curVal.id] = preVal.push(curVal);
          return preVal;
        }, []);
      }
    },
    // 获取客户
    getCustomerPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          quickSearch: name
        }
      };
      apiCustomerPage(param).then((res) => {
        if (res.code === 200) {
          const ids = [];
          res.data.records.map(item => {
            ids.push(item.id);
          });
          if (ids.includes(this.editData.customerId) === false) {
            res.data.records = [...this.idCustomerList, ...res.data.records];
          }
          this.customerList = res.data.records;
        }
      });
    },
    // 计费类型变化判断同一费用类型是否存在多种计费类型
    chargeTypeChange(scope) {
      this.formData.collectFeesList.map((item, ind) => {
        if (ind !== scope.$index) {
          if (item.feeTypeCode && (item.feeTypeCode === scope.row.feeTypeCode)) {
            if (item.billingType && (item.billingType !== scope.row.billingType)) {
              this.$message.warning(this.$t('basicData.ThereCantBeDifferentBillingTypesForTheSameChargeType')); // '同一费用类型不能存在相同计费类型'
              this.formData.collectFeesList[scope.$index].billingType = '';
            }
          }
        }
      });
    },
    // 获取费用类型
    getExpenseTypeList() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 100000,
        params: {
          state: 1,
          businessType: this.formData.businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.expenseTypeList = [];
          this.miscellaneousList = [];
          res.data.records.map(item => {
            if (item.specialRules || item.specialRules === 0) {
              this.miscellaneousList.push(item);
            } else {
              this.expenseTypeList.push(item);
            }
          });
          this.exportKey = new Date().getTime();
        }
      });
    },
    // 获取分区方案详情
    getOfferAffiliateList(id) {
      apiGetAffiliateById(id, false).then(res => {
        if (res.code === 200) {
          this.subregionList = res.data.partitionAffiliateList;
          this.formData.partitionAffiliateId = this.subregionList[0].id;
        }
      });
    },
    // 获取站点
    getSiteQuery(id) {
      apiSiteQuery(id, false).then(res => {
        if (res.code === 200) {
          this.outletsList = res.data;
        }
      });
    },
    // 站点变化
    siteIdChange(val) {
      // if (val) {
      //   this.outletsList.map(item => {
      //     if (val === item.id) {
      //       this.formData.siteName = item.name;
      //     }
      //   });
      // } else {
      //   this.formData.siteName = '';
      // }
    },
    // 报价类型变化
    offerTypeChange() {
      this.formData.siteIds = [];
      this.formData.siteName = '';
      this.formData.providerInfoId = '';
      this.formData.providerInfoName = '';
      this.formData.customerIds = [];
      this.selectCustomer = [];
    },
    // 应收主体变化
    providerInfoChange(val) {
      this.formData.siteIds = [];
      this.formData.siteName = '';
      this.formData.providerInfoName = '';
      this.outletsList = [];
      this.businessPrincipalList.map(item => {
        if (item.id === val) {
          this.formData.providerInfoName = item.name;
        }
      });
      if (val) {
        // 获取站点
        this.getSiteQuery(val);
      }
    },
    // 获取应收主体选项
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          let idProvider = true;
          if (!name) {
            res.data.records.map(item => {
              if (parseInt(item.id) === parseInt(this.editData.providerInfoId)) {
                idProvider = false;
              }
            });
            if (idProvider) {
              if (this.editData.providerInfoId) {
                const obj = {
                  providerName: this.editData.providerInfoName,
                  id: this.editData.providerInfoId
                };
                res.data.records.push(obj);
              }
            }
          }
          this.businessPrincipalList = res.data.records;
        }
      });
    },
    // 业务类型变化
    changeBusinessType(type) {
      this.editData.partitionId = null;
      // 获取分区方案
      this.getSubregionData('');
      // 获取费用类型
      this.getExpenseTypeList();
      this.formData.partitionId = '';
      this.formData.partitionName = '';
      if (type === 1) {
        this.formData.collectionCategory = 0;
      }
      this.formData['collectFeesList'] = [];
      this.formData['feeTypeList'] = [];
      this.tableDataObj = {
        collectFeesList: {
          1: {
            0: {},
            1: {}
          },
          2: {
            0: {},
            1: {}
          },
          3: {
            0: {},
            1: {}
          }
        },
        feeTypeList: {
          1: {
            0: {},
            1: {}
          },
          2: {
            0: {},
            1: {}
          },
          3: {
            0: {},
            1: {}
          }
        }
      };
      this.tableKey = new Date().getTime();
      this.exportKey = new Date().getTime();
    },
    // 切换分区方案清空价格详情和其他规则
    getOtherFeeDetail() {
      if (this.formData.partitionId) {
        this.partitionSchemeList.map(item => {
          if (item.id === this.formData.partitionId) {
            this.formData.partitionName = item.name;
          }
        });
      } else {
        this.formData.partitionName = '';
      }
      this.formData['collectFeesList'] = [];
      this.formData['feeTypeList'] = [];
      this.tableDataObj = {
        collectFeesList: cloneDeep(ROWS),
        feeTypeList: cloneDeep(ROWS)
      };
      this.getOfferAffiliateList(this.formData.partitionId);
      this.subregionList.map(item => {
        this.tableDataObj.collectFeesList[this.formData.expressType][item.id] = [];
        this.tableDataObj.feeTypeList[this.formData.expressType][item.id] = [];
      });
    },
    // 获取分区方案
    getSubregionData(name = '') {
      const param = {
        pageNumber: 1,
        pageSize: 100000,
        params: {
          name,
          state: 1,
          businessType: this.formData.businessType
        }
      };
      apiPartitionPage(param).then(res => {
        const { status, data } = res;
        if (status !== 'OK') {
          return;
        }
        if (!name) {
          let idProvider = true;
          res.data.records.map(item => {
            if (item.id === this.editData.partitionId) {
              idProvider = false;
            }
          });
          if (idProvider) {
            if (this.editData.partitionId) {
              const obj = {
                name: this.editData.partitionName,
                id: this.editData.partitionId
              };
              res.data.records.push(obj);
            }
          }
        }
        this.partitionSchemeList = data.records;
      }).catch(err => {
        console.error(err);
      });
    },
    // 增加报价
    handleAdd() {
      // 分区校验
      {
        // 分区不存在
        const isBool = this.isCheckPartitionId();
        if (!isBool) {
          return;
        }
      }

      const obj = {
        ...VALIDATE_PRICE_OBJ,
        // businessType: '',
        // feeTypeCode: '', // 费用类型
        // billingType: '', // 计费类型
        // startWeight: '', // 开始重量
        // endWeight: '', // 结束重量
        // beginCount: '', // 开始数量
        // endCount: '', // 结束数量
        // price: '', // 价格
        // startCases: '', // 箱数开始
        // endCases: '', // 箱数结束
        // overExtraPrice: '', // 备货揽收超出价
        // overWeightExtraPrice: '', // 超重加价
        expressType: this.formData.expressType, // 快递类型 2:普快 1 特快
        cargoType: this.formData.cargoType, // 货物类型 0：正常 1：大件
        partitionAffiliateId: this.formData.partitionAffiliateId // 分区
      };
      this.formData['collectFeesList'].push(obj);
      const { expressType, cargoType, partitionAffiliateId } = this.formData;
      // 快递类型 -> 货物类型 -> 分区

      this.$set(this.tableDataObj.collectFeesList[expressType][cargoType], partitionAffiliateId, this.formData['collectFeesList']);
      this.exportKey = new Date().getTime();
    },
    // 增加其他规则
    handleAdd1() {
      // 分区校验
      {
        // 分区不存在
        const isBool = this.isCheckPartitionId();
        if (!isBool) {
          return;
        }
      }
      const obj = {
        ...VALIDATE_OTHER_PRICE_OBJ,
        // feeTypeCode: '', // '费用类型',
        // taxRateOne: '', // 税率1
        // taxRate: '', // 税率
        // price: '', // 价格
        // weight: '', // 重量
        // specialRules: '', // 特殊规则
        expressType: this.formData.expressType, // 快递类型 2:普快 1 特快
        cargoType: this.formData.cargoType, // 货物类型 0：正常 1：大件
        partitionAffiliateId: this.formData.partitionAffiliateId // 分区
      };
      this.formData['feeTypeList'].push(obj);

      const { expressType, cargoType, partitionAffiliateId } = this.formData;
      // 快递类型 -> 货物类型 -> 分区

      // this.$set(this.tableDataObj.feeTypeList.expressType.cargoType, partitionAffiliateId, this.formData['feeTypeList']);

      // const { expressType, cargoType, partitionAffiliateId } = this.formData;
      // 快递类型 -> 货物类型 -> 分区

      this.$set(this.tableDataObj.feeTypeList[expressType][cargoType], partitionAffiliateId, this.formData['feeTypeList']);

      // if (this.tableDataObj.feeTypeList[expressType] === undefined) {
      //   this.$set(this.tableDataObj.feeTypeList, expressType, {});
      // }
      // if (this.tableDataObj.feeTypeList[expressType][cargoType] === undefined) {
      //   this.$set(this.tableDataObj.feeTypeList.expressType, cargoType, {});
      // }
      // if (this.tableDataObj.feeTypeList[expressType][cargoType][partitionAffiliateId] === undefined) {
      //   this.$set(this.tableDataObj.feeTypeList.expressType.cargoType, partitionAffiliateId, this.formData['feeTypeList']);
      // }

      // this.tableDataObj.feeTypeList[expressType][cargoType][partitionAffiliateId] = this.formData['feeTypeList'];
      this.exportKey = new Date().getTime();
    },
    // 导入价格详情
    importPartition(arr, type) {
      // 校验导入的分区是否存在当前分区中，如果不存终止导入，同时提示
      {
        const isBool = this.isCheckPartition(arr);
        // 分区不存在，终止导入
        if (!isBool) {
          this.$message.warning(this.$t('basicData.templatePartition'));
          return;
        }
      }

      // console.log(arr, type);

      // type 1: 普通价格详情 2: 其他规则
      if (type === 1) {
        this.importPartitionNormal(arr);
        // const { expressType, cargoType } = this.formData;
        // this.subregionList.map(item => {
        //   this.tableDataObj.collectFeesList[expressType][cargoType][item.id] = this.tableDataObj.collectFeesList[expressType][cargoType][item.id] || [];
        // });
        // for (let i = 0; i < arr.length; i++) {
        //   try {
        //     this.tableDataObj.collectFeesList[arr[i].expressType][arr[i].partitionAffiliateId] = [];
        //   } catch (err) {
        //     this.$message.warning(this.$t('basicData.templatePartition'));
        //     break;
        //   }
        // }
        // setTimeout(() => {
        //   for (let i = 0; i < arr.length; i++) {
        //     try {
        //       this.tableDataObj.collectFeesList[arr[i].expressType][arr[i].cargoType][arr[i].partitionAffiliateId].push(arr[i]);
        //     } catch (err) {
        //       this.$message.warning(this.$t('basicData.templatePartition'));
        //       break;
        //     }
        //   }
        //   this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[this.formData.expressType][this.formData.cargoType][this.formData.partitionAffiliateId] || [];
        //   this.exportKey = new Date().getTime();
        // }, 500);
      } else {
        this.importPartitionOther(arr);
        // this.subregionList.map(item => {
        //   this.tableDataObj.feeTypeList[this.formData.expressType][this.formData.cargoType][item.id] = [];
        // });
        // for (let i = 0; i < arr.length; i++) {
        //   try {
        //     this.tableDataObj.feeTypeList[arr[i].expressType][arr[i].cargoType][arr[i].partitionAffiliateId] = []; // .push(arr[i]);
        //   } catch (err) {
        //     this.$message.warning(this.$t('basicData.templatePartition'));
        //     break;
        //   }
        // }
        // setTimeout(() => {
        //   for (let i = 0; i < arr.length; i++) {
        //     try {
        //       this.tableDataObj.feeTypeList[arr[i].expressType][arr[i].cargoType][arr[i].partitionAffiliateId].push(arr[i]);
        //     } catch (err) {
        //       this.$message.warning(this.$t('basicData.templatePartition'));
        //       break;
        //     }
        //   }
        //   this.formData['feeTypeList'] = this.tableDataObj.feeTypeList[this.formData.expressType][this.formData.cargoType][this.formData.partitionAffiliateId] || [];
        //   this.exportKey = new Date().getTime();
        // }, 500);
      }
    },
    // 分区是否存在
    isCheckPartitionId() {
      const { subregionList } = this;
      const str2 = this.$i18n.t('operationCenter.partition');// 分区
      const str = this.$i18n.t('basicData.CannotBeEmpty');// 不能为空

      // 分区不存在
      if (!subregionList || subregionList.length === 0) {
        this.$message.warning(str2 + str);
        return false;
      }
      return true;
    },

    // 分区校验 导入分区是否存在
    isCheckPartition(arr) {
      const { subregionList } = this;

      // 分区不存在
      {
        const isBool = this.isCheckPartitionId();
        if (!isBool) {
          return false;
        }
      }

      // 导入的所有分区
      const partitionAffiliateIdArr = arr.map(item => Number(item.partitionAffiliateId));
      const idArr = subregionList.map(item => item.id);

      const isBool = partitionAffiliateIdArr.every(item => {
        return idArr.includes(item);
      });
      // return isBool;

      // 分区不存在，终止导入
      if (!isBool) {
        this.$message.warning(this.$t('basicData.templatePartition'));
        return false;
      }
      return true;
    },

    // 导入价格详情之普通价格详情
    importPartitionNormal(arr) {
      const { expressType, cargoType, partitionAffiliateId } = this.formData;

      // 将数据回填到
      const list = arr.map(item => {
        return {
          ...VALIDATE_PRICE_OBJ,
          ...item,
          expressType, // 快递类型 2:普快 1 特快
          cargoType // 货物类型 0：正常 1：大件
        };
      });

      // 按照分区分组
      const groupByList = groupBy(list, 'partitionAffiliateId');

      Object.entries(groupByList).forEach(item => {
        const [id, list] = item;
        this.$set(this.tableDataObj.collectFeesList[expressType][cargoType], id, list);
      });
      this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[expressType][cargoType][partitionAffiliateId] || [];
      this.exportKey = new Date().getTime();
      this.$message.success(this.$t('operationCenter.TheImportWasSuccessful')); // 导入成功
    },

    // 导入其他规则
    importPartitionOther(arr) {
      const { expressType, cargoType, partitionAffiliateId } = this.formData;

      // 将数据回填到
      const list = arr.map(item => {
        return {
          ...VALIDATE_PRICE_OBJ,
          ...item,
          expressType, // 快递类型 2:普快 1 特快
          cargoType // 货物类型 0：正常 1：大件
        };
      });

      const groupByList = groupBy(list, 'partitionAffiliateId');

      Object.entries(groupByList).forEach(item => {
        const [id, list] = item;
        this.$set(this.tableDataObj.feeTypeList[expressType][cargoType], id, list);
      });

      this.formData['feeTypeList'] = this.tableDataObj.collectFeesList[expressType][cargoType][partitionAffiliateId] || [];
      this.exportKey = new Date().getTime();
      this.$message.success(this.$t('operationCenter.TheImportWasSuccessful')); // 导入成功
    },

    // 删除分区
    handleDel(index, type) {
      const { expressType, cargoType, partitionAffiliateId } = this.formData;
      this.formData[type].splice(index, 1);
      this.tableDataObj[type][expressType][cargoType][partitionAffiliateId] = this.formData[type];
      this.exportKey = new Date().getTime();
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('saveData', type);
    },
    // 查看报价方案详情和其他规则
    asyncGetAffiliateById(ids) {
      // 切换分区方案清空价格详情和其他规则
      this.getOtherFeeDetail();
      // 报价详情
      apiOfferPriceDetail(ids).then(res => {
        const { status, data } = res;
        if (status !== 'OK') {
          return;
        }
        if (data && data.length > 0) {
          data.forEach((item) => {
            if (this.tableDataObj.collectFeesList[item.expressType] && this.tableDataObj.collectFeesList[item.expressType][item.cargoType]) {
              if (this.tableDataObj.collectFeesList[item.expressType][item.cargoType || 0][item.partitionAffiliateId] === undefined) {
                this.$set(this.tableDataObj.collectFeesList[item.expressType][item.cargoType], item.partitionAffiliateId, [item]);
              } else {
                this.tableDataObj.collectFeesList[item.expressType][item.cargoType || 0][item.partitionAffiliateId].push(item);
              }
            }
          });
        }
        this.formData['collectFeesList'] = this.tableDataObj.collectFeesList[this.formData.expressType][this.formData.cargoType][this.formData.partitionAffiliateId] || [];
        this.exportKey = new Date().getTime();
      });
      // 获取其他规则详情
      apiListByOfferPriceId(ids).then(res => {
        const { status, data } = res;
        if (status !== 'OK') {
          return;
        }
        if (data && data.length > 0) {
          this.otherRulesRawData = data;
          data.map((item) => {
            // const cargoType = item.cargoType || 0;
            if (this.tableDataObj.feeTypeList[item.expressType] && this.tableDataObj.feeTypeList[item.expressType][item.cargoType]) {
              if (this.tableDataObj.feeTypeList[item.expressType][item.cargoType][item.partitionAffiliateId] === undefined) {
                this.$set(this.tableDataObj.feeTypeList[item.expressType][item.cargoType], item.partitionAffiliateId, [item]);
              } else {
                this.tableDataObj.feeTypeList[item.expressType][item.cargoType][item.partitionAffiliateId].push(item);
              }
            }
            // this.tableDataObj.feeTypeList[item.expressType][item.cargoType][item.partitionAffiliateId] = this.tableDataObj.feeTypeList[item.expressType][item.cargoType][item.partitionAffiliateId] || [];
            // this.tableDataObj.feeTypeList[item.expressType][item.cargoType][item.partitionAffiliateId].push(item);
          });
        }
        this.formData['feeTypeList'] = this.tableDataObj.feeTypeList[this.formData.expressType][this.formData.cargoType][this.formData.partitionAffiliateId] || [];
        this.exportKey = new Date().getTime();
      });
    },
    // 校验列表数据
    verificationTable() {
      let tableForm1 = false;

      // 校验当前分区
      this.$refs.tableForm1.validate((tabRules) => {
        tableForm1 = tabRules;
      });

      // 校验所有分区
      {
        const [isBool, str] = fromValidate(this.tableDataObj, this.formData, this.subregionList);

        // 校验不通过
        if (!isBool) {
          this.$message.warning(str);
          return false;
        }
      }

      if (tableForm1) {
        return true;
      } else {
        return false;
      }
    },
    // 保存
    submitForm() {
      this.errData = [];
      this.$refs.queryForm.validate((valid) => {
        if (!valid) {
          return false;
        }

        // 校验不通过
        if (!this.verificationTable()) {
          // this.$message.warning(this.$t('basicData.CheckFail'));
          return false;
        }

        console.log('11111111111111111111111111')
        // 网点字段处理
        let siteIds = '';
        {
          const { siteIds: ids } = this.formData;
          if (Array.isArray(ids)) {
            siteIds = ids.join(',');
          } else {
            siteIds = ids;
          }
        }

        const param = {
          name: this.formData.name,
          offerType: this.formData.offerType,
          offerAttr: this.formData.offerAttr,
          providerInfoId: this.formData.providerInfoId,
          providerInfoName: this.formData.providerInfoName,
          // siteId: this.formData.siteId,
          siteIds,
          siteName: this.formData.siteName,
          partitionId: this.formData.partitionId,
          partitionName: this.formData.partitionName,
          startTime: this.formData.startTime,
          businessType: this.formData.businessType,
          collectionCategory: this.formData.collectionCategory,
          customers: this.selectCustomer,
          detailList: [],
          otherRulesList: []
        };

        const { collectFeesList, feeTypeList } = this.tableDataObj;

        Object.values(collectFeesList).forEach(item => {
          Object.values(item).filter(key => {
            // param.detailList.push(key);
            return Object.values(key).length !== 0;
          }).forEach(item => {
            Object.values(item).forEach(k => {
              param.detailList.push(...k);
            });
          });
        });

        Object.values(feeTypeList).forEach(item => {
          Object.values(item).filter(key => {
            // param.detailList.push(key);
            return Object.values(key).length !== 0;
          }).forEach(item => {
            Object.values(item).forEach(k => {
              param.otherRulesList.push(...k);
            });
          });
        });

        // 校验：在一个分区呢不能出现其他规则报价有数据，而基础报价不存在数据
        {
          const { subregionList } = this;
          const { detailList, otherRulesList } = param;
          const detailListPartitionAffiliateIdArr = detailList.map(item => Number(item.partitionAffiliateId));
          const otherRulesListPartitionAffiliateIdArr = otherRulesList.map(item => Number(item.partitionAffiliateId));

          const arr = otherRulesListPartitionAffiliateIdArr.filter(item => {
            return !detailListPartitionAffiliateIdArr.includes(item);
          });

          if (arr.length > 0) {
            const str1 = this.$t('basicData.TypeOfCourier');// 快递类型
            const str2 = this.$t('placeOrder.cargoType');// 货物类型
            const str3 = this.$t('operationCenter.partition');// 分区
            const str4 = this.$t('customerManagements.notNull');// 不能为空

            // this.errData.push(`在一个分区呢不能出现其他规则报价有数据，而基础报价不存在数据${arr}`);
            // subregionList.filter(item => {
            //   return arr.includes(item.id);
            // }).map(item => {
            //   `${str1}【】，${str2}【】，${str3}【】${str4}`;
            //   return;
            // })

            const msgErrorArr = otherRulesList.filter(item => {
              return arr.includes(Number(item.partitionAffiliateId));
            }).map(item => {
              // const y1 = EXPRESS_TYPE[item.expressType];
              const y1 = get(EXPRESS_TYPE, `${item.expressType}.name`, ''); // 快递类型
              const y2 = get(CARGO_TYPE, `${item.cargoType}.name`, ''); // 货物类型
              const y3 = subregionList.find(key => key.id === Number(item.partitionAffiliateId))?.partitionIdentifier ?? '';

              return `${str1}【${y1}】，${str2}【${y2}】，${str3}【${y3}】${str4}`;
            });
            if (msgErrorArr.length > 0) {
              this.errData.push(...msgErrorArr);
              this.dialogVisible = true;
              // this.$message.warning(msg);
            }
            return;
          }
        }

        // for (const k in collectFeesList) {
        //   for (const k1 in collectFeesList[k]) {
        //     if (collectFeesList[k][k1] && collectFeesList[k][k1].length > 0) {
        //       collectFeesList[k][k1].forEach(item => {
        //         param.detailList.push(item);
        //       });
        //     }
        //   }
        // }

        // for (const k in feeTypeList) {
        //   for (const k1 in feeTypeList[k]) {
        //     if (feeTypeList[k][k1] && feeTypeList[k][k1].length > 0) {
        //       feeTypeList[k][k1].forEach(item => {
        //         param.otherRulesList.push(item);
        //       });
        //     }
        //   }
        // }

        if (this.errData.length > 0) {
          this.dialogVisible = true;
          return;
        }
        if (this.editData.id) {
          param.id = this.editData.id;
          apiOfferPriceDetailEdit(param).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded')); // 操作成功
              this.$emit('saveData', true);
            }
          });
        } else {
          apiOfferPriceAdd(param).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded'));
              this.$emit('saveData', true);
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.addFormBox {
  padding: 20px;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.addFooter {
  border-top: 1px solid #ccc;
  text-align: right;
  padding: 10px;
}

.title {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

::v-deep .priceTable .el-form-item__content {

    margin-bottom: 20px;

}
</style>
<style>
.priceTableForm .el-form-item {
  margin-bottom: 0;
}

</style>
