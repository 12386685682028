import { render, staticRenderFns } from "./actions.vue?vue&type=template&id=24dca03b"
import script from "./actions.vue?vue&type=script&lang=js"
export * from "./actions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24dca03b')) {
      api.createRecord('24dca03b', component.options)
    } else {
      api.reload('24dca03b', component.options)
    }
    module.hot.accept("./actions.vue?vue&type=template&id=24dca03b", function () {
      api.rerender('24dca03b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/transport/driver/components/actions.vue"
export default component.exports