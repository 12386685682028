var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "border-card" } },
        [
          _c("el-tab-pane", { attrs: { label: "地区信息" } }, [
            _c(
              "div",
              {
                staticStyle: { margin: "-15px", height: "calc(100vh - 160px)" },
              },
              [_c("region")],
              1
            ),
          ]),
          _c("el-tab-pane", { attrs: { label: "ICMS税费参照表" } }, [
            _c(
              "div",
              {
                staticStyle: { margin: "-15px", height: "calc(100vh - 160px)" },
              },
              [_c("icms")],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }