<template>
  <div>
    <div>
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="operationBtn"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('receivablePayable.ImportAddCharges')"
        style="display: inline-block;margin-left: 20px;"
      />
    </div>
  </div>
</template>

<script>
const ExcelJS = require('exceljs');
import uploadExcel from '@/components/UploadExcel';
import { feePage } from '@/api/finance/partition';
import { apiCustomerExpenseImportAddFee } from '@/api/finance/receivables';
import { goFileUploadManagement } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

const MAX_LENGTH = 100000; // 最大导入长度

export default {
  name: 'ImportAddFee',
  components: {
    uploadExcel
  },
  props: {
    calculationType: {
      type: Number,
      default: 2
    }
  },
  created() {
    // 每次最多导入xxxx条数据
    this.lenTips = this.$t('receivablePayable.Only50000PiecesImport').replace('50000', MAX_LENGTH);
  },
  methods: {
    // 下载模板
    operationBtn() {
      const that = this;
      async function ddd(outletsList) {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        Sheet1.columns = [
          {
            header: '业务单号',
            key: 'waybillNumber',
            width: 40
          },
          {
            header: '订单号',
            key: 'orderNumber',
            width: 40
          },
          {
            header: '业务类型',
            key: 'businessType',
            width: 40
          },
          {
            header: '费用类型',
            key: 'feeCode',
            width: 20
          },
          {
            header: '应收金额',
            key: 'fee',
            width: 20
          },
          {
            header: '发生时间(格式：2022-01-01 00:00:00)',
            key: 'operateTime',
            width: 50
          },
          {
            header: '备注',
            key: 'remark',
            width: 50
          }
        ];
        Sheet1.getCell('A2').value = 'número do negócio';
        Sheet1.getCell('B2').value = 'Número do pedido';
        Sheet1.getCell('C2').value = 'Tipo de negócio';
        Sheet1.getCell('D2').value = 'Nome da taxa';
        Sheet1.getCell('E2').value = 'Taxas a receber';
        Sheet1.getCell('F2').value = 'Hora da ocorrência (Formato: 2022-01-01 00:00:00)';
        Sheet1.getCell('G2').value = 'Observações';
        Sheet1.mergeCells('A3:G3');
        Sheet1.getCell('A3').value = `1、每次仅导入${MAX_LENGTH}条 /Importar apenas ${MAX_LENGTH} itens de cada vez
          2、除了备注其他必填 /Tudo, exceto as notas, é obrigatório
          3、不可更改此模板 /Esse modelo não pode ser alterado`;
        Sheet1.getRow(3).getCell(1).font = {
          color: { argb: 'ff0000' }
        };

        Sheet2.columns = [
          {
            header: '费用类型(Nome da taxa)',
            key: 'feeCode',
            width: 40
          }
        ];
        const Sheet2_data = [];
        outletsList.map(item => {
          Sheet2_data.push({
            feeCode: item.name + '-' + item.code,
            width: 20
          });
        });
        Sheet2.addRows(Sheet2_data);
        Sheet3.columns = [
          {
            header: '业务类型(Tipo de negócio)',
            key: 'businessType',
            width: 40
          }
        ];
        const Sheet3_data = [
          {
            businessType: that.$t('collectionCenter.lanshou') + '-' + 1,
            width: 20
          },
          {
            businessType: that.$t('collectionCenter.expressDelivery') + '-' + 0,
            width: 20
          },
          {
            businessType: that.$t('collectionCenter.dispatch') + '-' + 2,
            width: 20
          }
        ];
        Sheet3.addRows(Sheet3_data);
        new Array(MAX_LENGTH).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          // 渲染费用类型下拉框
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$3:$A${Sheet2_data.length + 1}`]
          };
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
          Sheet1.getCell(row, 6).numFmt = '@';
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('receivablePayable.ImportAddCharges') + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      const param = {
        pageNumber: 1,
        pageSize: 1000000,
        params: {
          state: true // 状态： true 启用 false 禁用 默认为 true
        }
      };
      feePage(param).then(res => {
        if (res.code === 200) {
          const { data } = res;
          ddd(data.records);
        }
      });
    },
    // 导入时间格式化
    // formatDate(numb, format1, format2) {
    //   const old = numb - 1;
    //   if (isNaN(old) === true) {
    //     return true;
    //   }
    //   const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
    //   const time = new Date(1900, 0, old, 0, 0, t);
    //   const year = time.getFullYear();
    //   const month = time.getMonth() + 1;
    //   const date = time.getDate();
    //   const HH = time.getHours();
    //   const MM = time.getMinutes();
    //   const ss = time.getSeconds();
    //   return year + format1 + (month < 10 ? '0' +
    //       month : month) + format1 + (date < 10 ? '0' + date : date) +
    //     ' ' + (HH < 10 ? '0' + HH : HH) + format2 +
    //     (MM < 10 ? '0' + MM : MM) + format2 + (ss < 10 ? '0' + ss : ss);
    // },
    // 导入费用
    importLogistics(array) {
      const dataArr = [];
      const arrData = array[0].results.splice(2);
      const logisticsKey = {
        '业务单号': 'waybillNumber',
        '订单号': 'orderNumber',
        '业务类型': 'businessType',
        '费用类型': 'feeCode',
        '应收金额': 'fee',
        '发生时间(格式：2022-01-01 00:00:00)': 'operateTime',
        '备注': 'remark'
      };
      // const isTrue = false;
      arrData.map((item) => {
        const dataObj = {};
        for (const k in logisticsKey) {
          if (item[k]) {
            if (k === '费用类型' || k === '业务类型') {
              const index = item[k].lastIndexOf('-');
              const lastSegment = item[k].substring(index + 1);
              if (lastSegment) {
                dataObj[logisticsKey[k]] = lastSegment;
              } else {
                dataObj[logisticsKey[k]] = '';
              }
            } else if (k === '发生时间(格式：2022-01-01 00:00:00)') {
              dataObj[logisticsKey[k]] = item[k];
              // if (this.formatDate(item[k]) === true) {
              //   // isTrue = true;
              //   break;
              // }
              // dataObj[logisticsKey[k]] = this.formatDate(item[k], '-', ':');
            } else {
              dataObj[logisticsKey[k]] = item[k];
            }
          } else {
            dataObj[logisticsKey[k]] = '';
          }
        }
        dataArr.push(dataObj);
      });
      // if (isTrue) {
      //   this.$message.warning(this.$t('receivablePayable.OccurrenceTimeFormatIncorrect')); // 发生时间格式不正确
      //   return;
      // }
      // if (dataArr.length > 50000) {
      //   this.$message.warning(this.$t('receivablePayable.Only50000PiecesImport'));// '每次最多导入50000条数据'
      //   return;
      // }
      if (dataArr.length > MAX_LENGTH) {
        this.$message.warning(this.lenTips);
        return;
      }
      apiCustomerExpenseImportAddFee(dataArr).then(res => {
        if (res.code === 200) {
          // this.$message.success(this.$t('operationCenter.TheImportWasSuccessful'));
          goFileUploadManagement(this);
          this.$emit('changeFeeList', true);
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
