import request from '@/utils/request';
import qs from 'qs';
const baseFinanceURL = process.env.VUE_APP_FINANCE; // //finance-admin

// 财务分区分页
export const apiPartitionPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/partition/page',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 删除分区
export const apiPartitionDel = ids => {
  return request({
    method: 'DELETE',
    url: `/partition/delete/${ids}`,
    baseURL: baseFinanceURL
  });
};

// 禁用 启用 分区
export const apiPartitionChangeState = ({ id, state }) => {
  // return request.post('/fa/partition/edit', data);
  return request({
    method: 'POST',
    url: `/partition/changeState?id=${id}&state=${state}`,
    baseURL: baseFinanceURL
  });
};

// 新增财务分区
export const apiAffiliateAdd = data => {
  return request({
    method: 'POST',
    url: '/partition/affiliate/add',
    data,
    baseURL: baseFinanceURL
  });
};

// 编辑财务分区
export const apiAffiliateEdit = data => {
  return request({
    method: 'POST',
    url: '/partition/affiliate/edit',
    data,
    baseURL: baseFinanceURL
  });
};
// 分区删除
export const apiAffiliateDelete = data => {
  return request({
    method: 'DELETE',
    url: `/partition/affiliate/delete/${data}`,
    baseURL: baseFinanceURL
  });
};
// 分区操作日志
export const apiListByPartitionId = data => {
  return request({
    method: 'GET',
    url: `/partitionOperateLog/listByPartitionId/${data}`,
    baseURL: baseFinanceURL
  })
};
// 模糊查询 国家 州省 城市
export const apilgAreaSearchList = data => {
  const str = qs.stringify(data);

  return request({
    method: 'GET',
    url: `/logistics/lgArea/search/list?${str}`,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    isLoading: false // 是否显示加载中参数
  });
};

// 获取分区规则
export const apiGetAffiliateById = (ids, isBool = true) => {
  return request({
    method: 'POST',
    url: `/partition/affiliate/getAffiliateById?id=${ids}`,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 获取分区规则 -- 从报价中查看
export const apiGetPartitionDetailsByOfferPriceId = ids => {
  return request({
    method: 'GET',
    url: `/offerPrice/partition/affiliate/getPartitionDetailsByOfferPriceId?offerPriceId=${ids}`,
    baseURL: baseFinanceURL
  });
};

// 查看所有已启用的分区方案
export const apiGetEnableList = () => {
  return request({
    method: 'GET',
    url: '/partition/enable/list',
    baseURL: baseFinanceURL
  });
};

// 客户渠道报价新增
export const apiOfferPriceAffiliateAdd = data => {
  return request({
    method: 'POST',
    url: '/offerPrice/affiliate/add',
    data,
    baseURL: baseFinanceURL
  });
};

// 根据分区名搜索
export const apiPartitionOfferPage = (data, isBool = true) => {
  // return request.post('/fa/partition/offer/page', data);
  return request({
    method: 'POST',
    data,
    url: `/partition/offer/page`,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 账单列表分页查询
export const billPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/fa/bill/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 账单详情分页查询
export const billDetailPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/fa/billDetail/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};
// 客户账户
export const customerBalancePage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/user/customer/balance/page`,
    isLoading: isBool // 是否显示加载中参数
  });
};

// --------------------币种类型start--------------------

// 币种分页
export const currencyTypePage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/currency/type/page`,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 添加币种
export const addCurrencyType = (data) => {
  return request({
    method: 'POST',
    url: '/currency/type/add', 
    data,
    baseURL: baseFinanceURL
  })
};
// 币种编辑
export const editCurrencyType = (data) => {
  return request({
    method: 'POST',
    url: '/currency/type/edit', 
    data,
    baseURL: baseFinanceURL
  })
};
// 批量禁用币种类型
export const batchDisabledType = (data) => {
  return request({
    method: 'POST',
    url: '/currency/type/disabled/batch',
    data,
    baseURL: baseFinanceURL
  })
};
// 批量启用币种类型
export const batchEnabledType = (data) => {
  return request({
    method: 'POST',
    url: '/currency/type/enabled/batch',
    data,
    baseURL: baseFinanceURL
  })
};
// 删除币种
export const deleteCurrentcyType = (data) => {
  return request({
    method: 'POST',
    url: '/currency/type/delete',
    data,
    baseURL: baseFinanceURL
  })
};

// --------------------币种类型end---------------------

// -------------------应收费用类型start--------------------

// 分页
export const feePage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/fee/page`,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 启用，停用费用类型
export const editStatusFee = (data) => {
  return request({
    method: 'POST',
    url: '/fee/onOrOff',
    data: qs.stringify(data),
    baseURL: baseFinanceURL
  });
};
// 删除费用类型
export const feeDelete = (data) => {
  return request({
    method: 'POST',
    url: '/fee/delete',
    data,
    baseURL: baseFinanceURL
  });
};
// 新增费用类型
export const addFeeType = (data) => {
  return request({
    method: 'POST',
    url: '/fee/add',
    data,
    baseURL: baseFinanceURL
  });
};
// 编辑费用类型
export const editFeeType = (data) => {
  return request({
    method: 'POST',
    url: '/fee/edit',
    data,
    baseURL: baseFinanceURL
  });
};

// -------------------应收费用类型end--------------------


// 修改收款周期
export const modifyPaymentCycle = (data) => {
  return request.post('/user/customer/modifyPaymentCycle', data);
};

// --------------------------应收账户管理start--------------------

// 账户分页查询
export const accountPage = (data, isBool = true) => {
  return request({
    method: 'POST',
    data,
    url: `/account/page`,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
// 更改账户状态
export const changeState = (data) => {
  return request({
    method: 'POST',
    url: '/account/changeState?ids=' + data.ids + '&status=' + data.status,
    baseURL: baseFinanceURL
  });
};
// 账户删除
export const accountDelete = (data) => {
  return request({
    method: 'POST',
    url: '/account/delete',
    data,
    baseURL: baseFinanceURL
  });
};
// 新增账户
export const accountAdd = (data) => {
  return request({
    method: 'POST',
    url: '/account/add',
    data,
    baseURL: baseFinanceURL
  });
};
// 账户修改
export const accountEdit = (data) => {
  return request({
    method: 'POST',
    url: '/account/edit',
    data,
    baseURL: baseFinanceURL
  });
};

// --------------------------应收账户管理end--------------------

export const accountBalance = (data) => {
  return request.post('/user/customer/accountBalance', data);
};
// 修改信用额度
export const changeCreditLimit = (data) => {
  return request.post('/customer/creditLimit', data);
};
// 操作日志
export const getCustomerOperateLog = (data) => {
  return request.post('/fa/customerOperateLog/getCustomerOperateLog/', data);
};
// 应收生成分页
export const billFeeDetailsPage = (data) => {
  return request.post('/fa/custmerBillFeeDetails/page', data);
};
// 应收生成调整费率
export const reviseExchangeRate = (data) => {
  return request.post('/fa/custmerBillFeeDetails/reviseExchangeRate', data);
};
// 应收管理
export const feeDetailsGroup = (data) => {
  return request.get('/fa/custmerBillFeeDetails/feeDetailsGroup/' + data);
};
// 添加费用
export const addFee = (data) => {
  return request.post('/fa/custmerBillFeeDetails/addFee', data);
};
// 删除费用
export const removeFees = (data) => {
  return request.post('/fa/custmerBillFeeDetails/removeFees', data);
};
// 批量添加费用
export const batchAddFee = (data) => {
  return request.post('/fa/custmerBillFeeDetails/batchAddFee', data);
};
// 应收生成导出
export const billFeeDetailsExport = (data) => {
  return request.post('/fa/custmerBillFeeDetails/export', data);
};
// 添加备注
export const setMultipleRemark = (data) => {
  return request.post('/fa/custmerBillFeeDetails/setMultipleRemark', data);
};
// 生成应收
export const generateBillNumber = (data) => {
  return request.post('/fa/custmerBillFeeDetails/generateBillNumber', data);
};
// 应收管理费用作废
export const toVoidInvalid = (data) => {
  return request.post('/fa/custmerBillFeeDetails/invalid?ids=' + data.ids);
};
// 客户对账分页
export const customerBillPage = (data) => {
  return request.post('/fa/custmerBill/page', data);
};
// 应收对账反审
export const unAuditBill = (data) => {
  return request.post('/fa/custmerBill/unAuditBill', qs.stringify(data));
};
// 生成收款单
export const generateReceiptNumber = (data) => {
  return request.post('/fa/custmerBill/generateReceiptNumber', qs.stringify(data));
};
// 审核对账单
export const auditBill = (data) => {
  return request.post('/fa/custmerBill/auditBill', qs.stringify(data));
};
// 取消应收
export const cancelBill = (data) => {
  return request.post('/fa/custmerBill/cancelBill', qs.stringify(data));
};
// 删除费用
export const deleteFee = (data) => {
  return request.post('/fa/custmerBillFeeDetails/deleteFee', qs.stringify(data));
};
// 收款
export const crediting = (data) => {
  return request.post('/fa/customerReceipt/crediting', data);
};
// 重新计算价格
export const reRecalculatePrice = (data) => {
  return request.post('/fa/custmerBillFeeDetails/reRalculatePrice', data);
};
// 获取重新计算价格结果
export const getReRecalculatePriceList = (data) => {
  return request.post('/fa/custmerBillFeeDetails/getReCalculatePriceResult', data);
};
// 确认提交重新计算价格结果
export const confirmReCalculatePriceResult = (data) => {
  return request.get('/fa/custmerBillFeeDetails/confirmReCalculatePriceResult/' + data);
};
// 应付报价详情基础数据查询
export const apiGetPayableOfferPriceById = (data, isBool = true) => {
  return request({
    method: 'get',
    url: '/payableOfferPriceDetail/getPayableOfferPriceById/' + data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
