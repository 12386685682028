<template>
  <div class="permissionBox">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="PC" name="PCpage">
        <div class="permissionClass">
          <!-- PC -->
          <positionManage />
        </div>
      </el-tab-pane>
      <el-tab-pane label="PDA" name="PDApage">
        <div class="permissionClass">
          <!-- PDA -->
          <pdaPermission />
        </div>
      </el-tab-pane>
      <el-tab-pane :label="$t('organizationManage.dispatchSystem')" name="调度系统">
        <div class="permissionClass">
          <!-- 调度系统 -->
          <dispatchingSystem />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import positionManage from './positionManage';
import pdaPermission from './pda/pdaPermission.vue';
import dispatchingSystem from './dispatchingSystem.vue';
export default {
  name: 'PmsManagement',
  components: {
    positionManage,
    pdaPermission,
    dispatchingSystem
  },
  data() {
    return {
      activeName: 'PCpage'
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
  .permissionBox{
    padding: 20px;
    box-sizing: border-box;
  }
  .permissionClass{
    max-height: 650px;
    overflow: auto;
  }
  .permissionBox ::v-deep .el-tabs__content{
    padding: 0;
  }
</style>
