<template>
  <div class="boxInfo">
    <el-tabs type="border-card" style="height: 100%;">
      <!--'新增分区'\'分区详情'-->
      <el-tab-pane :label="$t('basicData.PartitionDetails')">
        <div class="addFormBox">
          <el-form
            ref="queryForm"
            class="queryFormClass"
            :model="formData"
            :rules="rules"
            :label-width="$i18n.locale=='zh'?'120px':''"
            :label-position="$i18n.locale!='zh'?'top':'right'"
          >
            <el-row>
              <el-col :span="20">
                <!--分区方案名称-->
                <el-form-item :label="$t('basicData.PartitionSchemeName')" prop="name">
                  <el-input
                    v-model.trim="formData.name"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    clearable
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="20">
                <!--备注内容-->
                <el-form-item :label="$t('basicData.RemarkContent')">
                  <el-input
                    v-model="formData.remark"
                    :placeholder="$t('orderDetails.pleaseInput')"
                    type="textarea"
                    :rows="6"
                  />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="20">
                <!--有始发州-->
                <el-form-item :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')">
                  <el-checkbox v-model="formData.stateOfOrigin" :true-label="1" :false-label="0" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div>
            <!--新增分区-->
            <el-button
              v-permit:remove="'but:finance:basicData:partition:edit'"
              size="small"
              type="primary"
              @click="handleAdd"
            >{{ $t('basicData.AddPartition') }}</el-button>
            <el-popover
              placement="bottom-end"
              trigger="hover"
            >
              <div>
                <import-download :table-data="formData" @importPartition="importPartition" />
              </div>
              <!--导入/下载-->
              <el-button
                slot="reference" size="small" plain
                style="margin-left: 10px"
              >{{ $t('basicData.ImportDownload') }}</el-button>
            </el-popover>
          </div>
          <div style="margin-top: 10px;">
            <el-form
              ref="tableForm"
              :model="formData"
              label-width="0"
              class="demo-ruleForm"
            >
              <el-table
                :data="formData.partitionAffiliateList"
                border
              >
                <el-table-column :label="$t('basicData.PartitionName')" width="160">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <!--分区名称-->
                      <span>{{ $t('basicData.PartitionName') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.partitionIdentifier'"
                        :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-input
                          v-model="scope.row.partitionIdentifier"
                          maxlength
                          :placeholder="$t('basicData.PartitionName')"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <!-- 始发州 -->
                <el-table-column :label="$t('orderDetails.desRem') + $t('GlobalSetObj.Estado')" width="180">
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="`partitionAffiliateList.${scope.$index}.state`"
                      >
                        <StateSelectCmp
                          :disabled="formData.stateOfOrigin === 0" :list="stateList" :value="scope.row.state"
                          @input="val => scope.row.state = val"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>

                <!--邮编-->
                <el-table-column :label="$t('basicData.zipCode')">
                  <template slot="header">
                    <div>
                      <span style="color: red;">*</span>
                      <span>{{ $t('orderDetails.desRem') + $t('basicData.zipCode') }}</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <div>
                      <el-form-item
                        :prop="'partitionAffiliateList.' + scope.$index + '.postalCode'"
                        :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                      >
                        <el-input
                          v-model="scope.row.postalCode"
                          :placeholder="$t('basicData.ZipCodeExample')"
                          @change="zipcodeCollation(scope.$index, scope.row.postalCode)"
                        />
                      </el-form-item>
                    </div>
                  </template>
                </el-table-column>
                <!--操作-->
                <el-table-column v-if="typeIndex !== 2" :label="$t('basicData.operate')" width="120">
                  <template slot-scope="scope">
                    <!--确定删除吗？-->
                    <el-popconfirm
                      :title="$t('basicData.AreYouSureYouWantToDelete')"
                      @confirm="handleDel(scope)"
                    >
                      <!--删除-->
                      <el-button slot="reference" type="danger" size="mini">{{ $t('basicData.Delete') }}</el-button>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </div>
        <div
          v-permit:remove="'but:finance:basicData:partition:edit'"
          class="addFooter"
        >
          <!--取消-->
          <el-button @click="() => handleUpdatePage(false)">{{ $t('basicData.Cancel') }}</el-button>
          <!--保存-->
          <el-button v-if="typeIndex !== 2" type="primary" @click="submitForm">{{ $t('basicData.Save') }}</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { apiAffiliateAdd, apiAffiliateDelete, apiAffiliateEdit, apiGetAffiliateById } from '@/api/finance/partition';
import { compressList } from '@/utils/zipcode';
import StateSelectCmp from '@/views/finance/basicData/partition/cmp/StateSelectCmp.vue';
import i18n from '@/lang';

// 添加模板
const addTmp = {
  partitionIdentifier: '',
  postalCode: '',
  id: undefined,
  state: ''
};

export default {
  components: {
    'import-download': () => import('@/views/finance/basicData/partition/cmp/ImportDownload'),
    StateSelectCmp
  },
  props: {
    // 类型标识 1新增 2查看 3 编辑
    typeIndex: {
      type: Number,
      default: 1
    },
    editData: {
      type: Object,
      default: () => {
        return {};
      }
    },

    // 所有一级州
    stateList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    // 州校验
    const stateCheck = (rule, value, callback) => {
      const { stateOfOrigin } = this.formData;
      // 没有勾选
      if (stateOfOrigin !== 1) {
        return callback();
      }

      if (!value) {
        return callback(new Error(i18n.t('GlobalSetObj.pleaseChoose'))); // '请选择州'
      }

      return callback();
    };

    return {
      rules: {
        name: [
          { required: true, message: this.$i18n.t('basicData.CannotBeEmpty'), trigger: 'blur' },
          // '长度在 2 到 20 个字符'
          { min: 2, max: 20, message: this.$i18n.t('basicData.TheLengthBetween2And20Characters'), trigger: 'blur' }
        ],

        state: [
          {
            validator: stateCheck,
            trigger: 'blur'
          }
        ]
      },
      errData: [],
      dialogVisible: false,
      formData: {
        name: '',
        remark: '',
        stateOfOrigin: 0,
        partitionAffiliateList: []
      }
    };
  },
  created() {
    if (this.editData.partitionId) {
      this.asyncGetAffiliateById(this.editData.partitionId);
    }
  },

  methods: {
    // 邮编整理
    zipcodeCollation(index, zipcode) {
      this.formData.partitionAffiliateList[index].postalCode = compressList(zipcode).join(',');
    },
    // 增加分区
    handleAdd() {
      this.formData.partitionAffiliateList = this.formData.partitionAffiliateList || [];
      const obj = {
        ...addTmp
        // partitionIdentifier: '',
        // postalCode: '',
        // id: undefined,
        // state: ''
      };
      this.formData.partitionAffiliateList.push(obj);
    },
    // 导入分区
    importPartition(arr) {
      const arrData = arr.map(item => {
        return {
          postalCode: compressList(item.zipcode).join(','),
          partitionIdentifier: item.name
        };
      });
      this.formData.partitionAffiliateList = [...this.formData.partitionAffiliateList, ...arrData];
    },
    // 删除分区
    handleDel(scope) {
      if (scope.row.id) {
        apiAffiliateDelete(scope.row.id).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
          }
        });
      }
      this.formData.partitionAffiliateList.splice(scope.$index, 1);
    },
    // 取消
    handleUpdatePage(type) {
      this.$emit('updatePage', type);
    },
    // 邮编校验
    verifyZipCode(arr) {
      const errObj = {
        errArr: [],
        repeat: []
      };
      // const reg = /^[A-Z0-9 \-]+$/g;
      const reg = /[A-Z \-]*[0-9]+/g; // 至少存在一个数字
      arr.forEach((item, ind) => {
        let zipcodeStr = '';
        if (item.postalCode.length > 0) {
          zipcodeStr = item.postalCode.trim();
        }
        zipcodeStr = zipcodeStr.replace(/，/ig, ',');
        // 没有填写
        if (zipcodeStr.length === 0) {
          if (!errObj.errArr.includes(ind + 1)) {
            errObj.errArr.push(ind + 1);
          }
        }
        // 获取每个分区的邮编
        const zipcodeArr = zipcodeStr.split(',').map(item => {
          return item.trim();
        });
        // 判断跨行交集
        arr.map((itemInd, index) => {
          let zipCode = '';
          if (itemInd.postalCode.length > 0) {
            zipCode = itemInd.postalCode.trim();
          }
          const aa = zipcodeArr.map(list => {
            return list.split('~');
          });
          const bb = zipCode.replace(/，/ig, ',').split(',').map(list => { return list.split('~'); });
          if (ind !== index) {
            for (let a = 0; a < aa.length; a++) {
              if (aa[a].length === 1) {
                aa[a][1] = aa[a][0];
              }
              for (let b = 0; b < bb.length; b++) {
                if (bb[b].length === 1) {
                  bb[b][1] = bb[b][0];
                }
                if (
                  (parseInt(aa[a][0]) >= parseInt(bb[b][0]) && parseInt(aa[a][0]) <= parseInt(bb[b][1])) ||
                  (parseInt(aa[a][1]) >= parseInt(bb[b][0]) && parseInt(aa[a][1]) <= parseInt(bb[b][1]))
                ) {
                  if (!errObj.repeat.includes(ind + 1)) {
                    errObj.repeat.push(ind + 1);
                  }
                } else if (
                  (parseInt(aa[a][0]) <= parseInt(bb[b][0]) && parseInt(aa[a][1]) >= parseInt(bb[b][0]))
                ) {
                  if (!errObj.repeat.includes(ind + 1)) {
                    errObj.repeat.push(ind + 1);
                  }
                }
              }
            }
          }
        });

        // 不能出现 "41900,,,"
        if (zipcodeArr.includes('')) {
          if (!errObj.errArr.includes(ind + 1)) {
            errObj.errArr.push(ind + 1);
          }
        }

        const isBool = zipcodeArr.every(item => {
          const list = item.split('~').map(item => {
            return item.trim();
          });

          if (list.length === 1) {
            reg.lastIndex = 0;
            return reg.test(list[0]);
          }

          // 不能出现 100~1000~10000
          if (list.length >= 3) {
            return false;
          }

          // 不能出现 "~100" "~" "100~"
          if (list.includes('')) {
            return false;
          }

          // 不能出现 10~9 10~10，前提是都为纯数字
          const num1 = Number(list[0]);
          const num2 = Number(list[1]);
          if ((num1 >= 0) && (num2 >= 0) && (num1 >= num2)) {
            return false;
          }

          return list.every(key => {
            reg.lastIndex = 0;
            return reg.test(key);
          });
        });

        if (!isBool) {
          // 邮箱不合格
          if (!errObj.errArr.includes(ind + 1)) {
            errObj.errArr.push(ind + 1);
          }
        }
      });

      return errObj;
    },

    // 处理提交的数据格式
    setSubmitFormDataType() {
      const { name, remark, partitionAffiliateList, stateOfOrigin } = this.formData;
      const obj = {
        id: '',
        name,
        remark,
        stateOfOrigin,
        partitionAffiliateList: []
      };

      // const tableData = this.formData.partitionAffiliateList;

      obj.partitionAffiliateList = partitionAffiliateList.map(item => {
        const { postalCode, partitionIdentifier, state, id } = item;

        const obj = {
          partitionIdentifier,
          postalCode, // 邮编
          state,
          id,
          partitionId: this.editData.partitionId
        };

        // 没有勾选
        if (!stateOfOrigin) {
          obj.state = undefined;
        }

        return obj;
      });

      return obj;
    },

    // 新增
    asyncAffiliateAdd(obj) {
      apiAffiliateAdd(obj).then(res => {
        const { status } = res;
        if (status !== 'OK') {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 查看订单详情
    asyncGetAffiliateById(ids) {
      apiGetAffiliateById(ids).then(res => {
        const { status, data } = res;
        if (status !== 'OK') {
          return;
        }
        const {
          name,
          remark,
          stateOfOrigin,
          partitionAffiliateList
        } = data;

        const arr = (partitionAffiliateList || []).map(item => {
          const { partitionIdentifier, postalCode, id, state } = item;
          return {
            ...addTmp,
            id,
            partitionIdentifier,
            postalCode,
            state: Number(state) || ''
          };
        });

        this.formData = {
          name,
          remark,
          stateOfOrigin,
          partitionAffiliateList: arr
        };
      }).catch(err => {
        console.error(err);
      });
    },
    // 编辑
    asyncAffiliateEdit(obj) {
      apiAffiliateEdit(obj).then(res => {
        const { status } = res;
        if (status !== 'OK') {
          return;
        }
        this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded'));
        this.handleUpdatePage(true);
      }).catch(err => {
        console.error(err);
      });
    },
    // 保存
    submitForm() {
      this.$refs.queryForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        if (this.formData.partitionAffiliateList.length === 0) {
          this.$message.warning(this.$i18n.t('basicData.PleaseAddPartitions')); // `请添加分区`,);
          return false;
        }
        this.$refs.tableForm.validate((tabRules) => {
          if (!tabRules) {
            return false;
          }
          this.errData = [];
          const errCode = this.verifyZipCode(this.formData.partitionAffiliateList);
          if (errCode.errArr.length > 0) {
            const tip = this.$i18n.t('basicData.Lines') + `${errCode.errArr.join(',')}` + this.$i18n.t('basicData.areMalformed'); // 第*和*邮编格式错误
            this.errData.push(tip);
          }
          if (errCode.repeat.length > 0) {
            const tip = this.$i18n.t('basicData.Lines') + `${errCode.repeat.join(',')}` + this.$i18n.t('basicData.intersect'); // 第*和*邮编存在交集
            this.errData.push(tip);
          }
          const findByItems = (eq) => (arr) => arr.filter(
            (x, i) => arr.find((y, j) => i !== j && eq(x, y))
          );
          const duplicatedItems = findByItems((a, b) => a.partitionIdentifier.toString() === b.partitionIdentifier.toString());
          const duplicatedZipCode = findByItems((a, b) => a.postalCode.toString() === b.postalCode.toString());
          const arrDuplicated = duplicatedItems(this.formData.partitionAffiliateList);
          const arrZipCode = duplicatedZipCode(this.formData.partitionAffiliateList);
          const nameArr = [];
          const zipCode = [];
          this.formData.partitionAffiliateList.map((item, ind) => {
            arrDuplicated.map(list => {
              if (list.partitionIdentifier === item.partitionIdentifier) {
                if (!nameArr.includes(ind + 1)) {
                  nameArr.push(ind + 1);
                }
              }
            });
            arrZipCode.map(list => {
              if (list.postalCode === item.postalCode) {
                if (!zipCode.includes(ind + 1)) {
                  zipCode.push(ind + 1);
                }
              }
            });
          });
          if (nameArr.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${nameArr.join(',')}` + this.$i18n.t('basicData.repeat'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (zipCode.length > 1) {
            const tip = this.$i18n.t('basicData.Lines') + `${zipCode.join(',')}` + this.$i18n.t('basicData.areDuplicated'); // 第*和*行重复
            this.errData.push(tip);
          }
          if (this.errData.length > 0) {
            this.dialogVisible = true;
            return;
          }
          const obj = this.setSubmitFormDataType();

          if (this.typeIndex === 1) {
            // 新增
            this.asyncAffiliateAdd(obj);
            return;
          }

          if (this.typeIndex === 2) {
            // 3.编辑
            obj.id = this.editData.partitionId;
            this.asyncAffiliateEdit(obj);
          }
          // this.handleUpdatePage(true);
        });
      });
      // this.$emit('updatePage', true);
    }
  }
};
</script>

<style scoped>

</style>
<style>
.boxInfo {
  height: 100%;
}
.boxInfo .el-tabs__content{
  height: calc(100vh - 40px);
  padding: 0;
}
.boxInfo .el-tabs__content .el-tab-pane {
  height: 100%;
}
.boxInfo .el-tabs__content .el-tab-pane .addFormBox{
  height: calc(100% - 65px);
  padding: 15px;
  overflow-y: auto;
}
.addFooter {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: right;
}
</style>
