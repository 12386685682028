<template>
  <div>
    <div style="margin-bottom: 10px">
      <!--下载模板-->
      <el-button
        icon="el-icon-download" type="primary" size="small"
        @click="downloadTemplate(1)"
      >{{ $t('basicData.DownloadTheTemplate') }}</el-button>
    </div>
    <div>
      <!--批量导入-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="BulkImport"
      />
    </div>
    <el-dialog
      :append-to-body="true"
      :title="$t('basicData.tips')"
      :visible.sync="dialogVisible"
      width="400"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color:#f00;margin-bottom: 8px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { compressList } from '@/utils/zipcode';
import { apiCommonSite } from '@/api/logisticsOrders';
import { Loading } from 'element-ui';
import { apiAreaPage } from '@/api/finance/basicData';
import { saveAs } from 'file-saver';

const options = {
  lock: true,
  spinner: 'el-icon-loading',
  text: 'loading...',
  background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
};
const ExcelJs = require('exceljs');
export default {
  name: 'ImportDownload',
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    outletsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allState: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allCity: {
      type: Array,
      default: () => {
        return [];
      }
    },
    providerList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      BulkImport: this.$t('basicData.BulkImport'), // 批量导入
      MultiPartitionImport: this.$t('basicData.MultiPartitionImport'), // 批量导入
      errData: [],
      dialogVisible: false
    };
  },
  methods: {
    // 下载模板
    downloadTemplate() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const Sheet4 = wb.addWorksheet('Sheet4');
        const Sheet5 = wb.addWorksheet('Sheet5');
        const Sheet6 = wb.addWorksheet('Sheet6');
        Sheet1.columns = [
          {
            header: '*' + that.$t('GlobalSetObj.SubordinateSupplier'), // 所属供应商
            key: 'providerId',
            width: 40
          },
          {
            header: '*' + that.$t('GlobalSetObj.DeliveryPoint'), // 派送网点
            key: 'sendSiteId',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.DeliveryProvince'), // 派送省份
            key: 'stateId',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.DeliveryCity'), // 派送城市
            key: 'cityId',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.ZipCodeSection'), // 邮编区间
            key: 'postalCode',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.RiskAreas'), // 风险区域
            key: 'riskRegion',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.RiskRatio'), // 风险比例
            key: 'riskRatio',
            width: 40
          },
          {
            header: '*' + that.$t('basicData.DeliveryDistance'), // 派送距离
            key: 'sendDistance',
            width: 40
          }
        ];
        const Sheet1_data = [];
        Sheet1.addRows(Sheet1_data);
        Sheet2.columns = [
          {
            header: that.$t('GlobalSetObj.SubordinateSupplier'), // 所属供应商
            key: 'providerId',
            width: 40
          }
        ];
        const Sheet2_data = [];
        that.providerList.map(item => {
          Sheet2_data.push({
            providerId: item.providerName + '->' + item.id
          });
        });
        Sheet2.addRows(Sheet2_data);
        Sheet3.columns = [
          {
            header: that.$t('GlobalSetObj.DeliveryPoint'), // 派送网点
            key: 'sendSiteId',
            width: 40
          }
        ];
        const Sheet3_data = [];
        that.outletsList.map(item => {
          Sheet3_data.push({
            sendSiteId: item.name + '->' + item.id
          });
        });
        Sheet3.addRows(Sheet3_data);
        Sheet4.columns = [
          {
            header: that.$t('basicData.DeliveryProvince'), // 派送省份
            key: 'stateId',
            width: 40
          }
        ];
        const Sheet4_data = [];
        that.allState.map(item => {
          Sheet4_data.push({
            stateId: item.name + '->' + item.id
          });
        });
        Sheet4.addRows(Sheet4_data);
        Sheet5.columns = [
          {
            header: that.$t('basicData.DeliveryCity'), // 派送城市
            key: 'cityId',
            width: 40
          }
        ];
        const Sheet5_data = [];
        that.allCity.map(item => {
          Sheet5_data.push({
            cityId: item.name + '->' + item.id
          });
        });
        Sheet5.addRows(Sheet5_data);
        Sheet6.columns = [
          {
            header: that.$t('basicData.RiskAreas'), // 风险区域
            key: 'riskRegion',
            width: 40
          }
        ];
        const Sheet6_data = [];
        const riskRegion = ['Sem Risco', 'Risco', 'Zona Rural'];
        riskRegion.map(item => {
          Sheet6_data.push({
            riskRegion: item
          });
        });
        Sheet6.addRows(Sheet6_data);

        new Array(10000).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          Sheet1.getCell(row, 1).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet4!$A$2:$A${Sheet4_data.length + 1}`]
          };
          Sheet1.getCell(row, 4).dataValidation = {
            type: 'list',
            formulae: [`=Sheet5!$A$2:$A${Sheet5_data.length + 1}`]
          };
          Sheet1.getCell(row, 6).dataValidation = {
            type: 'list',
            formulae: [`=Sheet6!$A$2:$A${Sheet6_data.length + 1}`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();

        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        saveAs(blob, that.$t('basicData.ImportDeliveryReferenceTemplate') + '.xlsx');
        return;
      }
      ddd();
    },
    // 批量导入报价
    // 导入数据处理
    importDataProcessing(arr) {
      return new Promise((resolve) => {
        const header = arr[0].header;
        let required = [];
        let headObj = [];
        required = [
          '*' + this.$t('GlobalSetObj.SubordinateSupplier'), // 所属供应商
          '*' + this.$t('GlobalSetObj.DeliveryPoint'), // 派送网点
          '*' + this.$t('basicData.DeliveryProvince'), // 派送省份
          '*' + this.$t('basicData.DeliveryCity'), // 派送城市
          '*' + this.$t('basicData.ZipCodeSection'), // 邮编区间
          '*' + this.$t('basicData.RiskAreas'), // 风险区域
          '*' + this.$t('basicData.RiskRatio'), // 风险比例
          '*' + this.$t('basicData.DeliveryDistance') // 派送距离
        ];
        headObj = [
          'providerId', // 所属供应商
          'sendSiteId', // 派送网点
          'stateId', // 派送省份
          'cityId', // 派送城市
          'postalCode', // 邮编区间
          'riskRegion', // 风险区域
          'riskRatio', // 风险比例
          'sendDistance' // 派送距离
        ];
        this.errData = [];
        const allData = [];
        arr[0].results.map((item, ind) => {
          const keyArr = [];
          const data = {};
          header.map(list => {
            data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
          });
          allData.push(data);
          Object.keys(item).forEach((key) => {
            keyArr.push(key);
          });
          required.map(list => {
            if (!keyArr.includes(list)) {
              const text = this.$t('basicData.row') + (ind + 1) + this.$t('basicData.line') + ',' + list + this.$t('basicData.CannotBeEmpty');
              this.errData.push(text);
            }
          });
        });
        const arrData = [];
        allData.map((item, ind) => {
          const data = {};
          const dataName = {};
          Object.keys(item).forEach((key, i) => {
            if (headObj[i] === 'providerId' || headObj[i] === 'sendSiteId' || headObj[i] === 'stateId' || headObj[i] === 'cityId') {
              if (item[key]) {
                data[headObj[i]] = item[key].split('->')[1];
                if (headObj[i] === 'providerId') {
                  dataName['providerName'] = item[key].split('->')[0];
                } else if (headObj[i] === 'sendSiteId') {
                  dataName['sendSiteName'] = item[key].split('->')[0];
                } else if (headObj[i] === 'stateId') {
                  dataName['stateName'] = item[key].split('->')[0];
                } else if (headObj[i] === 'cityId') {
                  dataName['cityName'] = item[key].split('->')[0];
                }
              } else {
                data[headObj[i]] = '';
              }
            } else if (headObj[i] === 'postalCode') {
              if (item[key] !== '' && item[key] !== null && item[key] !== undefined) {
                data[headObj[i]] = compressList(item[key].toString()).join(',');
              } else {
                data[headObj[i]] = '';
              }
            } else {
              data[headObj[i]] = item[key];
            }
          });
          if (data.providerId !== '' && data.providerId !== null && data.providerId !== undefined) {
            const param = {
              pageNumber: 1,
              pageSize: 9999999,
              params: {
                siteStatus: undefined,
                providerId: data.providerId
              }
            };
            apiCommonSite(param, false).then(res => {
              if (res.code === 200) {
                let isTrue = true;
                if (res.data.records && res.data.records.length > 0) {
                  res.data.records.map(list => {
                    if (list.id.toString() === data.sendSiteId.toString()) {
                      isTrue = false;
                    }
                  });
                }
                if (isTrue === true) {
                  // '派送网点不属于所属供应商'
                  const text = this.$t('basicData.row') +
                    (ind + 1) + this.$t('basicData.line') + '【' +
                    dataName.sendSiteName + '】' +
                    this.$t('basicData.doesNotBelongTo') + '【' +
                    dataName.providerName + '】';
                  if (dataName.sendSiteName !== '' && dataName.sendSiteName !== null && dataName.sendSiteName !== undefined) {
                    this.errData.push(text);
                  }
                }
              }
            });
          }
          if (data.stateId !== '' && data.stateId !== null && data.stateId !== undefined) {
            const param = {
              pageNumber: 1,
              pageSize: 99999,
              params: {
                level: 2,
                parentId: data.stateId
              }
            };
            apiAreaPage(param, false).then(res => {
              if (res.code === 200) {
                let isCity = true;
                if (res.data.records && res.data.records.length > 0) {
                  res.data.records.map(list => {
                    if (list.id.toString() === data.cityId.toString()) {
                      isCity = false;
                    }
                  });
                }
                if (isCity === true) {
                  // '城市不属于所属于州'
                  const text = this.$t('basicData.row') +
                    (ind + 1) + this.$t('basicData.line') + '【' +
                    dataName.cityName + '】' +
                    this.$t('basicData.doesNotBelongTo') + '【' +
                    dataName.stateName + '】';
                  if (dataName.stateName !== '' && dataName.stateName !== null && dataName.stateName !== undefined) {
                    if (dataName.cityName !== '' && dataName.cityName !== null && dataName.cityName !== undefined) {
                      this.errData.push(text);
                    }
                  }
                }
              }
            });
          }
          arrData.push(data);
        });
        setTimeout(() => {
          resolve({ arrData, errData: this.errData });
        }, 4000);
      });
    },
    async importLogistics(arr) {
      Loading.service(options);
      const loadingInstance = Loading.service(options);
      const arrData = await this.importDataProcessing(arr);
      if (arrData.errData.length > 0) {
        this.dialogVisible = true;
      } else {
        this.$emit('importPartition', arrData.arrData);
      }
      loadingInstance.close();
    }
  }
};
</script>

<style scoped>

</style>
