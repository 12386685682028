<template>
  <div class="app-container">
    <!--
      expand-on-click-node:是否在点击节点的时候展开或者收缩节点， 默认值为 true，如果为 false，则只有点箭头图标的时候才会展开或者收缩节点。
      default-expand-all:默认展开所有节点
    -->
    <el-tree
      :data="permissionList"
      :expand-on-click-node="false"
      :props="defaultProps"
    >
      <span slot-scope="{ node,data }" class="custom-tree-node mb">
        <span class="titlts">
          <i v-if="data.isParent" class="iconfont icon-folder-open" />
          <i
            v-else
            class="iconfont"
            :class="[data.name==='查询'?'icon-chaxun':data.name==='添加'?'icon-icon--':data.name==='删除'?'icon-shanchu':data.name==='编辑'?'icon-bianji':'icon-wenjian']"
          />
          {{ data.name }}
        </span>

        <span class="custom-tree-node-btn">
          <el-button
            v-permit:remove="'btn:user-center:permission:add2'"
            size="mini"
            type="primary"
            @click="() => addChild(node,data)"
          >{{ $t('organizationManage.addNextLevel') }}</el-button><!-- 添加下级 -->
        </span>

        <span class="custom-tree-node-btn">
          <el-button
            v-permit:remove="'btn:user-center:permission:add1'"
            size="mini"
            type="success"
            @click="() => addBrother(node,data)"
          >{{ $t('organizationManage.addSameLevel') }}</el-button><!-- 添加同级 -->
        </span>

        <span class="custom-tree-node-btn">
          <el-button
            v-permit:remove="'btn:user-center:permission:edit'"
            size="mini"
            type="info"
            @click="() => editNode(node,data)"
          >{{ $t('organizationManage.edit') }}</el-button><!-- 编辑 -->
        </span>
        <span v-if="!data.isParent" class="custom-tree-node-btn">
          <el-popconfirm
            :title="$t('organizationManage.AreYouSureDelete')"
            @confirm="remove(node,data)"
          ><!-- 确定删除吗？ -->
            <el-button
              slot="reference"
              v-permit:remove="'btn:user-center:permission:delete'"
              size="mini"
              type="danger"
            >{{ $t('organizationManage.Delete') }}</el-button><!-- 删除 -->
          </el-popconfirm>
        </span>
      </span>
    </el-tree>

    <!-- 权限操作弹出层 -->
    <permission-operation :permission-opt="permissionOptPop" @on-add-submit="addSubmit" />
  </div>
</template>

<script>

import { getPermissionStructure, removePermission } from '@/api/permission';
import PermissionOperation from './permissionOperation';

export default {
  name: 'PositionManage',
  components: { PermissionOperation },
  data() {
    return {
      // 覆盖默认变量名
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      permissionOptPop: {
        title: '',
        type: '',
        visible: false,
        id: null,
        parentId: null,
        name: '',
        code: '',
        sort: 1,
        remark: ''
      },
      permissionList: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    /* 页面初始化工作*/
    init() {
      getPermissionStructure().then(response => {
        this.permissionList = response.data.permissionStructure;
      }).catch(err => {
        console.error(err);
      });
    },
    // 添加兄弟/同级
    addBrother(node, data) {
      // '添加权限'
      this.permissionOptPop.title = this.$t('roleManage.addPermissions');
      this.permissionOptPop.type = 'add';
      this.permissionOptPop.visible = true;
      const parent = node.parent;
      this.permissionOptPop.id = null;
      this.permissionOptPop.parentId = parent.data.id || 0;
      this.permissionOptPop.parentName = parent.data.name || '';
      this.permissionOptPop.name = '';
      this.permissionOptPop.code = '';
      this.permissionOptPop.sort = 1;
      this.permissionOptPop.remark = '';
    },
    addChild(node, data) {
      // '添加权限'
      this.permissionOptPop.title = this.$t('roleManage.addPermissions');
      this.permissionOptPop.type = 'add';
      this.permissionOptPop.visible = true;
      this.permissionOptPop.id = null;
      this.permissionOptPop.parentId = data.id || 0;
      this.permissionOptPop.parentName = data.name || '';
      this.permissionOptPop.name = '';
      this.permissionOptPop.code = '';
      this.permissionOptPop.sort = 1;
      this.permissionOptPop.remark = '';
    },
    editNode(node, data) {
      // '编辑权限'
      this.permissionOptPop.title = this.$t('roleManage.editPermissions');
      this.permissionOptPop.type = 'edit';
      this.permissionOptPop.visible = true;
      this.permissionOptPop.id = data.id;
      this.permissionOptPop.parentId = data.parentId;
      const parent = node.parent; //
      this.permissionOptPop.parentName = parent.data.name || '';
      this.permissionOptPop.name = data.name;
      this.permissionOptPop.code = data.code;
      this.permissionOptPop.sort = data.sort || 1;
      this.permissionOptPop.remark = data.remark;
    },
    remove(node, data) {
      // 删除服务器上的节点
      var params = { permissionId: data.id };
      removePermission(params).then(() => {
        // if (res.code !== 200) { return; }
        this.init();
      }).catch(err => {
        console.error(err);
      });
    },
    addSubmit() {
      this.init();
    }

  }
};
</script>

<style lang="scss" scoped>
.custom-tree-node {
  font-size: 18px;
  .custom-tree-node-btn {
    margin-left: 10px;
  }
}

.iconfont {
  font-size: 20px;
}

.titlts {
  display: inline-block;
  min-width: 120px;
  // border: 1px solid red;
}
</style>

<style  scoped>
.app-container >>> div.el-tree-node {
  margin-bottom: 5px;
}

.app-container >>> div.el-tree-node__children {
  margin-top: 10px;
}
</style>

