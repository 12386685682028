import { render, staticRenderFns } from "./claimInformation.vue?vue&type=template&id=db87d850&scoped=true"
import script from "./claimInformation.vue?vue&type=script&lang=js"
export * from "./claimInformation.vue?vue&type=script&lang=js"
import style0 from "./claimInformation.vue?vue&type=style&index=0&id=db87d850&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db87d850",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db87d850')) {
      api.createRecord('db87d850', component.options)
    } else {
      api.reload('db87d850', component.options)
    }
    module.hot.accept("./claimInformation.vue?vue&type=template&id=db87d850&scoped=true", function () {
      api.rerender('db87d850', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/orderCenter/logisticsOrders/components/detailBox/components/claimInformation.vue"
export default component.exports