<template>
  <div>
    <div
      v-permit:remove="'but:finance:basicData:partition:edit'"
      style="margin-bottom: 10px"
    >
      <!--下载模板-->
      <el-button type="primary" size="small" @click="downloadTemplate(1)">{{ $t('basicData.DownloadTheTemplate') }}</el-button>
    </div>
    <div
      v-permit:remove="'but:finance:basicData:partition:edit'"
      style="margin-bottom: 10px"
    >
      <!--导入分区-->
      <upload-excel
        :on-success="importLogistics"
        :but-name="fileName"
      />
    </div>
    <div>
      <!--下载分区-->
      <el-button type="primary" size="small" @click="downloadTemplate(2)">{{ $t('basicData.DownloadThePartition') }}</el-button>
    </div>
    <!--导入提示-->
    <el-dialog
      :title="$t('basicData.tips')"
      :visible.sync="importDialogVisible"
      :append-to-body="true"
      width="30%"
    >
      <div>
        <div v-for="(item, ind) in errData" :key="ind" style="color: red;margin-bottom: 10px;">{{ item }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--确 定-->
        <el-button type="primary" @click="importDialogVisible = false">{{ $t('basicData.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiAreaPage } from '@/api/finance/basicData';
import { saveAs } from 'file-saver';
const ExcelJs = require('exceljs');
import { Loading } from 'element-ui';

const options = {
  lock: true,
  spinner: 'el-icon-loading',
  text: 'loading...',
  background: 'rgba(250, 250, 250, 0.6)' // 加载动画的背景
};
export default {
  name: 'ImportDownload',
  components: {
    'upload-excel': () => import('@/components/UploadExcel/index')
  },
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    allState: {
      type: Array,
      default: () => {
        return [];
      }
    },
    allCity: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      fileName: this.$t('basicData.ImportPartitions'),
      errData: [],
      importDialogVisible: false
    };
  },
  methods: {
    // 下载模板\下载分区
    downloadTemplate(type) {
      const that = this;
      async function ddd() {
        const wb = new ExcelJs.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        const Sheet2 = wb.addWorksheet('Sheet2');
        const Sheet3 = wb.addWorksheet('Sheet3');
        const columns = [
          {
            header: that.$t('basicData.PartitionName'), // 分区名称
            key: 'name',
            width: 40
          },
          {
            header: that.$t('collectionCenter.provinceee'), // 州省
            key: 'state',
            width: 40
          },
          {
            header: that.$t('collectionCenter.city'), // 城市
            key: 'city',
            width: 40
          },
          {
            header: that.$t('basicData.zipCode'), // 邮编
            key: 'zipcode',
            width: 40
          }
        ];
        Sheet1.mergeCells('A2:D2');
        Sheet1.getCell('A2').value = that.$t('basicData.PartitionImportRules'); // 注意：分区名称（必填）；邮编（必填，邮编号码段连接符“~”，比如：1001~2001，代表1001到2001之间全部1000个邮编号码）
        Sheet1.getCell('A2').font = {
          color: { argb: 'FF0000' }
        };
        Sheet1.getCell('A2').alignment = { wrapText: true };
        Sheet1.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
        Sheet1.getRow(1).height = 20;
        Sheet1.getRow(2).height = 30;
        const Sheet2_data = [];
        Sheet2.columns = [
          {
            header: that.$t('collectionCenter.provinceee'), // 省份
            key: 'name',
            width: 60
          }
        ];
        that.allState.map(item => {
          Sheet2_data.push({
            name: item.name + '->' + item.id
          });
        });
        Sheet2.addRows(Sheet2_data);
        const Sheet3_data = [];
        Sheet3.columns = [
          {
            header: that.$t('collectionCenter.city'), // 城市
            key: 'name',
            width: 60
          }
        ];
        that.allCity.map(item => {
          Sheet3_data.push({
            name: item.name + '->' + item.id
          });
        });
        Sheet3.addRows(Sheet3_data);
        Sheet1.columns = columns;
        if (type === 2) {
          columns.push({
            header: 'ID', // 邮编
            key: 'id',
            width: 20
          });
          Sheet1.columns = columns;
          const Sheet1_data = [];
          that.tableData.partitionAffiliateList.map((item) => {
            const objState = [];
            const objCity = [];
            that.allState.map(list => {
              item.state.map(keyList => {
                if (list.id === keyList) {
                  objState.push(list.name + '->' + keyList);
                }
              });
            });
            that.allCity.map(list => {
              item.city.map(keyList => {
                if (list.id === keyList) {
                  objCity.push(list.name + '->' + keyList);
                }
              });
            });
            Sheet1_data.push({
              name: item.partitionIdentifier,
              state: objState.join(','),
              city: objCity.join(','),
              zipcode: item.postalCode,
              id: item.id ? item.id : ''
            });
          });
          Sheet1.addRows(Sheet1_data);
        }
        new Array(99999).fill(0).forEach((_, idx) => {
          const row = idx + 2;
          Sheet1.getCell(row, 2).dataValidation = {
            type: 'list',
            formulae: [`=Sheet2!$A$2:$A${Sheet2_data.length + 1}`]
          };
          Sheet1.getCell(row, 3).dataValidation = {
            type: 'list',
            formulae: [`=Sheet3!$A$2:$A${Sheet3_data.length + 1}`]
          };
        });
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });

        const date = new Date().getTime();
        const saveName = type === 2 ? (that.tableData.name + date + '.xlsx') : (that.$t('basicData.PartitionImportTemplate') + '.xlsx'); // 导入分区模板

        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入数据处理
    importDataProcessing(arr) {
      return new Promise((resolve) => {
        const arrData = arr[0].results.splice(1);
        const header = arr[0].header;
        const headObj = ['name', 'state', 'city', 'zipcode', 'id'];
        const allData = [];
        this.errData = [];
        arrData.map((item, ind) => {
          const data = {};
          const dataName = {};
          header.map(list => {
            data[list] = (item[list] !== '' && item[list] !== null && item[list] !== undefined) ? item[list] : '';
          });
          Object.keys(data).forEach((key, i) => {
            if (headObj[i] === 'zipcode') {
              if (item[key] !== null && item[key] !== '' && item[key] !== undefined) {
                data[headObj[i]] = item[key].toString().replace(/[\uff0c]/g, ',');
              } else {
                data[headObj[i]] = '';
              }
            } else if (headObj[i] === 'state' || headObj[i] === 'city') {
              if (item[key] !== null && item[key] !== '' && item[key] !== undefined) {
                const arr = item[key].split(',');
                const arrId = [];
                const arrName = [];
                arr.map(list => {
                  arrId.push(Number(list.split('->')[1]));
                  arrName.push(list);
                });
                data[headObj[i]] = arrId;
                if (headObj[i] === 'state') {
                  dataName['stateName'] = arrName;
                } else if (headObj[i] === 'city') {
                  dataName['cityName'] = arrName;
                }
              } else {
                data[headObj[i]] = [];
                dataName['stateName'] = [];
                dataName['cityName'] = [];
              }
            } else {
              data[headObj[i]] = item[key];
            }
          });
          if (data.state !== '' && data.state !== null && data.state !== undefined && data.state.length > 0) {
            const param = {
              pageNumber: 1,
              pageSize: 99999,
              params: {
                level: 2,
                parentIdList: data.state
                // parentId: data.state
              }
            };
            apiAreaPage(param, false).then(res => {
              if (res.code === 200) {
                const isCity = [];
                const isCityName = [];
                data['allCity'] = res.data.records;
                if (res.data.records && res.data.records.length > 0) {
                  const allCityId = [];
                  res.data.records.map(list => {
                    allCityId.push(list.id);
                  });
                  data.city.map(item1 => {
                    if (allCityId.includes(item1) === false) {
                      isCity.push(item1);
                    }
                  });
                  isCity.map(item1 => {
                    dataName['cityName'].map(list => {
                      if (item1 === Number(list.split('->')[1])) {
                        isCityName.push(list.split('->')[0]);
                      }
                    });
                  });
                }
                if (isCityName.length > 0) {
                  // '城市不属于所属于州'
                  const stateName = [];
                  dataName['stateName'].map(keyItem => {
                    stateName.push(keyItem.split('->')[0]);
                  });
                  const text = this.$t('basicData.row') +
                    (ind + 1) + this.$t('basicData.line') + '【' +
                    isCityName.join('，') + '】' +
                    this.$t('basicData.doesNotBelongTo') +
                      '【' + stateName.join('，') + '】';
                  if (data.state !== '' && data.state !== null && data.state !== undefined && data.state.length > 0) {
                    this.errData.push(text);
                  }
                }
              }
            });
          } else {
            data['allCity'] = this.allCity;
          }
          allData.push(data);
        });
        setTimeout(() => {
          resolve({ allData, errData: this.errData });
        }, 4000);
      });
    },
    // 导入分区
    async importLogistics(arr) {
      Loading.service(options);
      const loadingInstance = Loading.service(options);
      const arrData = await this.importDataProcessing(arr);
      if (arrData.errData.length > 0) {
        this.importDialogVisible = true;
      } else {
        this.$emit('importPartition', arrData.allData);
      }
      loadingInstance.close();
    }
  }
};
</script>

<style scoped>

</style>
