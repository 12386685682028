<template>
  <div class="warp" style="padding:0 20px;height: calc(100vh - 80px); overflow: auto;">
    <div style="margin-bottom: 10px">
      <el-button size="small" type="primary" @click="exportToExcel">{{ $t('newOrder.export') }}</el-button>
    </div>
    <el-table
      :data="tableData" :header-cell-style="{background:'#f2f2f2',color:'black'}" border
      style="width: 100%;"
    >
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" prop="index"
        type="index" width="60px"
      />
      <el-table-column
        :label="$t('GlobalSetObj.waybillNumber')" align="center" min-width="160px"
        prop="waybillNumber"
      />
      <el-table-column
        :label="$t('navbar.unloadOrArrivalTime')"
        align="center"
        min-width="150px"
        prop="unloadOrArrivalTime"
      />
      <el-table-column
        :label="$t('navbar.unloadOrArrivalTime1')"
        align="center"
        min-width="130px"
        prop="loadTimeOrShipmentTime"
      />
      <el-table-column
        :label="$t('navbar.durationTime')" align="center" min-width="130px"
        prop="durationTime"
      />
    </el-table>
    <div style="text-align: right">
      <el-pagination
        :current-page.sync="searchForm.pageNumber"
        :page-size.sync="searchForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 15px 0;"
        @current-change="getSearch"
        @size-change="getSearch"
      />
    </div>
  </div>
</template>

<script>
import { dispatchPageDetail, dispatchPageexportDetail } from '@/api/orderApi';

export default {
  props: {
    queryDateTime: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    dataobj: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      searchForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      },
      tableData: [],
      total: 0
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    getSearch() {
      const data = {
        pageNumber: this.searchForm.pageNumber,
        pageSize: this.searchForm.pageSize,
        params: {
          startTime: this.queryDateTime[0],
          endTime: this.queryDateTime[1],
          state: this.dataobj.state,
          siteId: this.dataobj.siteId,
          ticketType: this.dataobj.ticketType
        }
      };
      dispatchPageDetail(data).then((res) => {
        console.log(res);
        if (res.status === 'OK') {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    exportToExcel() {
      const data = {
        startTime: this.queryDateTime[0],
        endTime: this.queryDateTime[1],
        state: this.dataobj.state,
        siteId: this.dataobj.siteId,
        ticketType: this.dataobj.ticketType
      };
      dispatchPageexportDetail(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$emit('call-parent-method', false);
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    },
    resetForm() {
      this.searchForm = {
        pageNumber: 1,
        pageSize: 10,
        params: {
          waybillNumber: ''
        }
      };
      this.getSearch();
    }
  }
};
</script>

<style scoped>
.search-form {
  margin-bottom: 20px;
}

.warp >>> .el-form-item {
  margin-bottom: 0 !important;
}
</style>
