<template> <!-- 添加快录 -->
  <div style="padding: 0 10px;">
    <div class="addTable">
      <el-card class="box-card">
        <el-form
          ref="basicInformation" :model="addBatchFee.basicInformation"
          label-position="top" size="small"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <!--业务单号-->
              <el-form-item
                :label="$t('receivablePayable.TicketNumber') + '：'"
                prop="waybillNumber"
                :rules="[
                  { required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] },
                  { max: 20, message: $t('basicData.LengthCantExceed20'), trigger: ['blur', 'change'] }
                ]"
              >
                <div style="display:flex;">
                  <div style="width: 50px;font-weight: bold;text-align: center;">QR</div>
                  <el-input v-model="addBatchFee.basicInformation.waybillNumber" :disabled="!!queryFeeParam.id" />
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--客户-->
              <el-form-item
                :label="$t('receivablePayable.customer') + '：'"
                prop="customerId"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <!-- @row="changeCustomer" -->
                <QueryCustomerV2Cmp
                  style="width: 100%;"
                  :val="addBatchFee.basicInformation.customerId"
                  @change="val => addBatchFee.basicInformation.customerId = val"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--业务主体-->
              <el-form-item :label="$t('basicData.BusinessPrincipal') + '：'">
                <span>{{ providerName }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <!--站点-->
              <el-form-item
                :label="$t('userManage.site') + '：'"
                prop="siteId"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <QuerySiteV2Cmp
                  style="width: 100%;"
                  provider-id=""
                  :disabled="!!queryFeeParam.id"
                  :val="addBatchFee.basicInformation.siteId"
                  @change="val => addBatchFee.basicInformation.siteId = val"
                  @row="changeOutlets"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--计费重量-->
              <el-form-item
                :label="$t('receivablePayable.BillableWeight') + '：'"
                prop="chargeWeight"
                :rules="{ required: true, suffix: 3, negative: '', validator: priceType, trigger: ['blur', 'change'] }"
              >
                <el-input v-model="addBatchFee.basicInformation.chargeWeight" :disabled="!!queryFeeParam.id" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <!--业务类型-->
              <el-form-item
                :label="$t('collectionCenter.businessT') + '：'"
                prop="businessType"
                :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="addBatchFee.basicInformation.businessType === 7 ? 0 : addBatchFee.basicInformation.businessType"
                  style="width: 100%;"
                  :placeholder="$t('GlobalSetObj.pleaseChoose')"
                  :disabled="!!queryFeeParam.id"
                  @change="changeBusinessType()"
                >
                  <!--快递-->
                  <el-option :label="$t('collectionCenter.expressDelivery')" :value="0" />
                  <!--揽收-->
                  <el-option :label="$t('collectionCenter.lanshou')" :value="1" />
                  <!--派件-->
                  <el-option :label="$t('collectionCenter.delivery')" :value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="addBatchFee.basicInformation.businessType === 1" :span="8">
              <!--揽收类型-->
              <el-form-item
                :label="$t('newOrder.CollectionType') + '：'"
                prop="collectionCategory"
                :rules="{ required: addBatchFee.basicInformation.businessType === 1, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
              >
                <el-select
                  v-model="addBatchFee.basicInformation.collectionCategory"
                  :placeholder="$t('basicData.PleaseSelect')"
                  style="width: 100%;"
                  :disabled="!!queryFeeParam.id"
                >
                  <!--普通揽收-->
                  <el-option key="0" :label="$t('basicData.OrdinaryPickUp')" :value="0" />
                  <!--备货揽收-->
                  <el-option key="1" :label="$t('basicData.StockingAndPickup')" :value="1" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <!--备注-->
              <el-form-item
                :label="$t('receivablePayable.remark') + '：'"
                prop="remark"
                :rules="{ max: 200, message: $t('basicData.LengthCantExceed200'), trigger: ['blur', 'change'] }"
              >
                <el-input
                  v-model="addBatchFee.basicInformation.remark" :rows="4" type="textarea"
                  :disabled="!!queryFeeParam.id"
                />
              </el-form-item>
            </el-col>
            <el-col v-if="!queryFeeParam.id" :span="24">
              <!--网点对客户同步增加-->
              <el-form-item prop="isSynchronous">
                <el-checkbox v-model="addBatchFee.basicInformation.isSynchronous">{{ $t('receivablePayable.TheNumberOutletsCustomersHasIncreasedSimultaneously') }}</el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <!--费用明细-->
      <div class="headerStyle">{{ $t('receivablePayable.FeeBreakdown') }}</div>
      <el-form ref="addBatchFee" :model="addBatchFee" label-width="0" size="small" class="error-form">
        <el-table
          :key="expenseSubListKey"
          :data="addBatchFee.expenseSubList"
          height="300"
          border
        >
          <el-table-column
            width="55"
            align="center"
          >
            <template slot="header">
              <span class="el-icon-plus addStyle" @click="addCost" />
            </template>
            <template slot-scope="scope">
              <!--确定删除吗？-->
              <el-popconfirm
                :title="$t('basicData.AreYouSureDelete')"
                @confirm="deleteCost(scope)"
              >
                <span slot="reference" class="el-icon-delete deleteStyle" />
              </el-popconfirm>
            </template>
          </el-table-column>
          <!--业务类型-->
          <el-table-column
            prop="businessType"
            :label="$t('collectionCenter.businessT')"
            align="center"
            width="180"
          >
            <template slot="header">
              <div>
                <span style="color: #f00;margin-right: 5px;">*</span>
                <span>{{ $t('collectionCenter.businessT') }}</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="!scope.row.isEdit">
                  <span v-if="scope.row.businessType === 0">{{ $t('collectionCenter.expressDelivery') }}</span>
                  <span v-if="scope.row.businessType === 1">{{ $t('collectionCenter.lanshou') }}</span>
                  <span v-if="scope.row.businessType === 2">{{ $t('collectionCenter.dispatch') }}</span>
                </div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.businessType'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="scope.row.businessType"
                    filterable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    clearable
                    style="width: 100%;"
                    @change="businessTypeChange(scope)"
                  >
                    <el-option
                      v-for="item in businessTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--费用类型-->
          <el-table-column
            prop="feeName"
            :label="$t('basicData.ExpenseType')"
            align="center"
            width="180"
          >
            <template slot="header">
              <div>
                <span style="color: #f00;margin-right: 5px;">*</span>
                <span>{{ $t('basicData.ExpenseType') }}</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="!scope.row.isEdit">{{ scope.row.feeName }}</div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.feeCode'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-select
                    v-model="scope.row.feeCode"
                    filterable
                    clearable
                    :placeholder="$t('operationCenter.PleaseSelect')"
                    style="width: 100%;"
                    @change="feeTypeChange(scope)"
                  >
                    <el-option
                      v-for="item in scope.row.feeTypeOptionList"
                      :key="item.id"
                      :label="item.displayName"
                      :value="item.code"
                    />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--应收金额-->
          <el-table-column
            prop="fee"
            :label="$t('receivablePayable.AmountReceivable')"
            width="120"
            align="center"
          >
            <template slot="header">
              <div>
                <span style="color: #f00;margin-right: 5px;">*</span>
                <span>{{ $t('receivablePayable.AmountReceivable') }}</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="!scope.row.isEdit">{{ scope.row.fee }}</div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.fee'"
                  :rules="{ required: true, suffix: 2, negative: '-?', validator: priceType, trigger: ['blur', 'change'] }"
                >
                  <el-input v-model="scope.row.fee" />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--业务时间-->
          <el-table-column
            prop="operateTime"
            :label="$t('basicData.BusinessTime')"
            align="center"
            width="220"
          >
            <template slot="header">
              <div>
                <span style="color: #f00;margin-right: 5px;">*</span>
                <span>{{ $t('basicData.BusinessTime') }}</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="formStyle">
                <div v-if="!scope.row.isEdit">{{ scope.row.operateTime }}</div>
                <el-form-item
                  v-else
                  :prop="'expenseSubList.' + scope.$index + '.operateTime'"
                  :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
                >
                  <el-date-picker
                    v-model="scope.row.operateTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%;"
                    :placeholder="$t('basicData.SelectADatetime')"
                  />
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <!--备注-->
          <el-table-column
            prop="remark"
            :label="$t('receivablePayable.remark')"
            align="center"
            min-width="160"
          >
            <template slot-scope="scope">
              <div v-if="!scope.row.isEdit">{{ scope.row.remark }}</div>
              <el-form-item
                v-else
                :prop="'expenseSubList.' + scope.$index + '.remark'"
                :rules="{ max: 200, message: $t('basicData.LengthCantExceed200'), trigger: ['blur', 'change'] }"
              >
                <el-input v-model="scope.row.remark" :placeholder="$t('GlobalSetObj.pleaseInputContent')" />
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div style="padding: 20px;text-align: center;border-top: 1px solid #ccc;">
      <!--保存-->
      <el-button type="primary" size="small" @click="saveData(2)">{{ $t('basicData.Save') }}</el-button>
      <!--关闭-->
      <el-button size="small" @click="saveData(1)">{{ $t('orderCenterCont.ShutDown') }}</el-button>
    </div>
  </div>
</template>

<script>
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import {
  apiPayableSiteFastInputFee,
  apiSiteExpenseAddMultiFeeSub,
  apiReCalculateDeleteFeeDTO
} from '@/api/finance/receivables';
import { feePage } from '@/api/finance/partition';
import { cloneDeep } from 'lodash-es';
import QuerySiteV2Cmp from '@/views/finance/cmp/Query/QuerySiteV2Cmp.vue';
import QueryCustomerV2Cmp from '@/views/finance/cmp/Query/QueryCustomerV2Cmp.vue';
import { getOneBusinessTypeList } from './utils.js';

// 业务类型 映射改变
const BUSINESSTYPE_MAP = {
  1: 1,
  0: 0,
  7: 0 // 快递订单(尾程揽收)
};

export default {
  name: 'AddSnapshotFee',

  components: {
    QuerySiteV2Cmp,
    QueryCustomerV2Cmp
  },

  props: {
    queryFeeParam: {
      type: Object,
      default: () => {
        return {
          id: null,
          waybillNumber: '', // 业务单号
          customerId: '', // 客户id
          customerAlias: '', // 客户别称
          providerId: '', // 业务主体id
          siteId: '', // 网点id
          siteName: '', // 网点名称
          chargeWeight: '', // 计费重量
          businessType: '', // 业务类型
          collectionCategory: '', // 揽收类型
          remark: '', // 备注
          isSynchronous: false
        };
      }
    },
    feeTypeOption: {
      type: Array,
      default: () => {
        return [];
      }
    },
    queryForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    selectData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    expenseSubList: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    const priceType = (rule, value, callback) => {
      const reg = new RegExp('(^' + rule.negative + '[1-9]\\d*(\\.\\d{1,' + rule.suffix + '})?$)|(^' + rule.negative + '0(\\.\\d{1,' + rule.suffix + '})?$)');
      const { required } = rule;
      if (!required) {
        // 非必填
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          return callback();
        }
      } else {
        if (value) {
          if (!reg.test(value)) {
            this.adopt = false;
            return callback(new Error(this.$i18n.t('basicData.IncorrectFormatting'))); // 格式不正确
          } else {
            this.adopt = true;
            return callback();
          }
        } else {
          this.adopt = false;
          return callback(this.$i18n.t('basicData.CannotBeEmpty')); // '不能为空'
        }
      }
    };
    return {
      addBatchFee: {
        basicInformation: {
          id: null,
          waybillNumber: '', // 业务单号
          customerId: '', // 客户id
          customerAlias: '', // 客户别称
          providerId: '', // 业务主体id
          siteId: '', // 网点id
          siteName: '', // 网点名称
          chargeWeight: '', // 计费重量
          businessType: '', // 业务类型
          collectionCategory: '', // 揽收类型
          remark: '', // 备注
          isSynchronous: false
        },
        expenseSubList: []
      },
      priceType: priceType,
      createUser: null,
      feeTypeList: [],
      billingMethod: '',
      expenseSubListKey: new Date().getTime(),
      providerName: '' // 业务主体名称
    };
  },

  computed: {
    // 费用明细 业务类型下拉菜单控制
    businessTypeList() {
      const billingMethod = this.billingMethod;
      const { businessType } = this.addBatchFee.basicInformation;

      return getOneBusinessTypeList(businessType, billingMethod);
    }
  },
  created() {
    // 费用类型
    this.feeTypeList = cloneDeep(this.feeTypeOption);
    this.createUser = getUserIdAndNameObj();
    if (this.queryFeeParam.waybillNumber) {
      this.addBatchFee.basicInformation = cloneDeep(this.queryFeeParam);
      if (this.addBatchFee.basicInformation.id) {
        this.addBatchFee.expenseSubList = cloneDeep(this.expenseSubList);
      }
      if (this.addBatchFee.basicInformation.businessType !== '' && this.addBatchFee.basicInformation.businessType !== null && this.addBatchFee.basicInformation.businessType !== undefined) {
        this.asyncFeePage();
      }
    }
  },

  methods: {
    // 费用类型分页
    asyncFeePage() {
      const { businessType } = this.addBatchFee.basicInformation;
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1,
          businessType: BUSINESSTYPE_MAP[businessType] || 0
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.feeTypeList = res.data.records;
        }
      });
    },

    // 费用类型变化
    feeTypeChange(scope) {
      this.addBatchFee.expenseSubList[scope.$index].feeTypeOptionList.map(item => {
        if (item.code === scope.row.feeCode) {
          this.addBatchFee.expenseSubList[scope.$index].feeName = item.name;
        }
      });
    },
    // 业务类型变化
    changeBusinessType() {
      this.addBatchFee.basicInformation.collectionCategory = null;
      if (!this.addBatchFee.basicInformation.id) {
        this.addBatchFee.expenseSubList = [];
      }

      this.asyncFeePage();
    },
    // 网点选项变化
    changeOutlets(obj) {
      const { label, providerName } = obj;
      this.addBatchFee.basicInformation.siteName = label;
      this.providerName = providerName; // 业务主体名称
    },

    // 客户选项变化
    changeCustomer(row) {
      console.log(row);
      const { customerAlias, billingMethod } = row;
      this.addBatchFee.basicInformation.customerAlias = customerAlias;
      this.billingMethod = billingMethod;

      // 新增操作
      if (!this.addBatchFee.basicInformation.id) {
        this.addBatchFee.expenseSubList = [];
      } else {
        // 编辑操作

        // 对费用明细已经选择的业务类型清空出来
        this.addBatchFee.expenseSubList.forEach(item => {
          const { isEdit } = item;
          if (!item.id && !isEdit) {
            // 业务类型清空
            item.businessType = '';
          }
          // // 本次添加的内容
          // if (!isEdit) {
          //   // 业务类型清空
          //   item.businessType = '';
          // }
        });
      }
    },
    // 业务类型变化
    businessTypeChange(scope) {
      this.addBatchFee.expenseSubList[scope.$index].feeName = '';
      this.addBatchFee.expenseSubList[scope.$index].feeCode = '';
      // 获取费用类型
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1,
          businessType: scope.row.businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          this.addBatchFee.expenseSubList[scope.$index].feeTypeOptionList = res.data.records;
        }
      });
    },
    // 关闭，保存
    saveData(type) {
      if (type === 2) {
        const { businessType: type } = this.addBatchFee.basicInformation;
        this.$refs.basicInformation.validate((validIs) => {
          if (validIs) {
            const businessType = type === 7 ? 0 : type;

            const basicInformation = {
              id: this.addBatchFee.basicInformation.id,
              waybillNumber: 'QR' + this.addBatchFee.basicInformation.waybillNumber, // 业务单号
              businessType, // 业务类型
              customerId: this.addBatchFee.basicInformation.customerId, // 客户id
              customerAlias: this.addBatchFee.basicInformation.customerAlias, // 客户别称
              siteId: this.addBatchFee.basicInformation.siteId, // 网点id
              siteName: this.addBatchFee.basicInformation.siteName, // 网点名称
              // providerId: this.addBatchFee.basicInformation.providerId, // 业务主体id
              providerId: null,
              remark: this.addBatchFee.basicInformation.remark, // 备注
              collectionCategory: this.addBatchFee.basicInformation.collectionCategory, // 揽收类型
              chargeWeight: this.addBatchFee.basicInformation.chargeWeight, // 计费重量
              isSynchronous: this.addBatchFee.basicInformation.isSynchronous === true ? 1 : 0
            };
            this.$refs.addBatchFee.validate((valid) => {
              if (valid) {
                const list = [];
                this.addBatchFee.expenseSubList.map(item => {
                  if (item.isEdit === true) {
                    const obj = {
                      id: item.id ? item.id : '',
                      feeCode: item.feeCode,
                      businessType: item.businessType,
                      operateTime: item.operateTime,
                      remark: item.remark,
                      fee: item.fee,
                      feeName: item.feeName
                    };
                    list.push(obj);
                  }
                });
                if (!basicInformation.id) {
                  const param = {
                    ...basicInformation,
                    expenseFastInputSubDTOList: list
                  };
                  if (this.addBatchFee.expenseSubList.length === 0) {
                    this.$message.warning(this.$t('receivablePayable.AddAtLeastOneCharge'));
                    return;
                  }
                  apiPayableSiteFastInputFee(param).then(res => {
                    if (res.code === 200) {
                      this.$message.success(this.$i18n.t('GlobalSetObj.SaveSuccessfully')); // 保存成功
                      this.$emit('saveFormSnapshot', type);
                    }
                  });
                } else {
                  const param = {
                    waybillNumber: basicInformation.waybillNumber,
                    orderNumber: this.queryFeeParam.orderNumber,
                    expenseSubList: list
                  };
                  apiSiteExpenseAddMultiFeeSub(param).then(res => {
                    if (res.code === 200) {
                      this.$message.success(this.$i18n.t('collectionCenter.SaveSuccessfully')); // '保存成功'
                      this.$emit('saveFormSnapshot', type);
                    }
                  });
                }
              }
            });
          }
        });
      } else {
        this.$emit('saveFormSnapshot', type);
      }
    },
    // 删除新增
    deleteCost(scope) {
      if (scope.row.id) {
        apiReCalculateDeleteFeeDTO({ id: scope.row.id }).then(res => {
          if (res.code === 200) {
            this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
            this.addBatchFee.expenseSubList.splice(scope.$index, 1);
          }
        });
      } else {
        this.addBatchFee.expenseSubList.splice(scope.$index, 1);
      }
    },
    // 添加费用
    addCost() {
      const { businessType: type } = this.addBatchFee.basicInformation;
      let businessType = null;
      if (type === 0) {
        if (this.billingMethod === 0 || !this.billingMethod) {
          // businessType = this.addBatchFee.basicInformation.businessType;
          businessType = '';
        } else {
          businessType = 2;
        }
      } if (type === 7) {
        // 快递订单(尾程揽收)
        businessType = BUSINESSTYPE_MAP[type] || 2;
      } else {
        businessType = type || '';
      }
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          state: 1,
          businessType: businessType
        }
      };
      feePage(param, false).then(res => {
        if (res.code === 200) {
          const feeTypeList = res.data.records;
          const data = {
            isEdit: true,
            businessType: businessType,
            feeName: '', // 费用类型
            feeCode: '', // 费用类型代码
            feeTypeOptionList: feeTypeList,
            fee: '', // 应收金额
            remark: '', // 备注
            operateTime: '' // 生成业务时间
          };
          this.addBatchFee.expenseSubList.push(data);
        }
      });
    }
  }
};
</script>

<style scoped>
.headerStyle {
  background:#eee;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  margin-top: 20px;
}
</style>
<style>
.addTable {
  height: calc(100vh - 145px);
  overflow-y: auto;
}
.addTable .el-table tbody .el-table__cell{
  padding: 5px;
}
.addStyle {
  color: #409EFF;
  cursor: pointer;
  font-size: 18px;
}
.deleteStyle {
  color: #F56C6C;
  cursor: pointer;
  font-size: 18px;
}
</style>
