import request from '@/utils/request';
import Qs from 'qs';

const baseAdminURL = process.env.VUE_APP_ADMIN;
/**
 * 获取组织机构
 * @param parentId
 */
export const getOrganizationTree = (parentId) => {
  // return request({
  //   method: 'GET',
  //   url: '/user/organization/getOrganizationTree',
  //   params: { parentId: parentId },
  //   baseURL: baseAdminURL
  // })
  return request.get('/organization/getOrganizationTree', { params: { parentId: parentId }});
};

/**
 * 删除组织机构
 * @param orgId
 * @returns
 */
export const removeOrganization = (orgId) => {
  return request.post('/organization/delete', Qs.stringify({ orgId: orgId }));
};

/**
 * 添加组织机构
 * @param data
 * @returns
 */
export const addOrganization = (data) => {
  return request.post('/organization/add', data);
};

/**
 * 编辑组织机构
 * @param data
 * @returns
 */
export const editOrganization = data => {
  return request.post('/organization/edit', data);
};
