<template>
  <div class="app-container">
    <div>
      <el-form label-width="80px">
        <el-row :gutter="10">
          <el-col :span="6">
            <!-- 业务时间 -->
            <el-form-item :label="$t('basicData.BusinessTime')">
              <el-date-picker
                v-model="queryForm.params.operatorTime"
                type="date"
                :picker-options="pickerOptions"
                style="width: 100%;"
                value-format="yyyy-MM-dd"
                :placeholder="$t('operationCenter.PleaseSelect')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!-- 客户 -->
            <el-form-item :label="$t('GlobalSetObj.client')">
              <QueryCustomerCmp
                :value="queryForm.params.customerId"
                @change="val => queryForm.params.customerId = val"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">

            <!-- 揽收类型 -->
            <el-form-item :label="$t('newOrder.CollectionType')">
              <el-select v-model="queryForm.params.collectType" :placeholder="$t('GlobalSetObj.pleaseChoose')" style="width: 100%;">
                <el-option :label="$t('GlobalSetObj.all')" :value="null" />
                <el-option
                  v-for="item in COLLECT_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button
        type="primary" size="small" icon="el-icon-search"
        @click="handleQuery"
      >{{ $t('newOrder.search') }}</el-button>

      <el-divider />
    </div>

    <el-card>
      <div class="table-but">
        <!-- 刷新数据（含弹窗） -->
        <RefreshDataCmp />

        <!-- 导出 -->
        <el-button
          v-permit:remove="'btn:finance:DataDheet:export'"
          size="small"
          type="primary"
          icon="el-icon-download"
          @click="asyncApiStatisticsExport"
        >{{ $t('CenterForSorting.export') }}</el-button>
      </div>

      <div>
        <el-table
          :data="tableData"
          border
          class="table-height"
          :height="tableHeightRow.height"
        >
          <el-table-column :label="$t('operationCenter.ind')" width="80px" align="center">
            <template slot-scope="scope">
              <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>

          <!--客户-->
          <el-table-column
            :label="$t('GlobalSetObj.client')"
            width="160px"
            align="left"
            prop="customerName"
          />

          <!-- 业务时间 -->
          <el-table-column
            :label="$t('basicData.BusinessTime')"
            width="160px"
            align="left"
            prop="operateTimeStr"
          />

          <!-- 揽收类型 -->
          <el-table-column
            :label="$t('newOrder.CollectionType')"
            width="160px"
            align="left"
            prop="collectTypeStr"
          />

          <!-- 单量 -->
          <el-table-column
            :label="$t('collectionCenter.unit')"
            width="160px"
            align="left"
            prop="orderQuantity"
          />

          <!-- 总费用 -->
          <el-table-column
            :label="$t('orderCenterCont.TotalCost')"
            width="160px"
            align="left"
            prop="totalFee"
          />
          <template v-for="item of tableColumn">

            <el-table-column
              :key="item.code"
              :label="item.label"
              width="160px"
              :prop="item.prop"
            />
          </template>
        </el-table>
        <!-- $t('collectionCenter.particulars') -->
      </div>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="asyncGetTableData"
          @size-change="asyncGetTableData"
        />
      </div>
    </el-card>

  </div>
</template>

<script>
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import QueryCustomerCmp from '@/views/finance/cmp/Query/QueryCustomerCmp.vue';
import { COLLECT_TYPE } from '@/views/finance/enums.js';
import { apiStatisticsPage, apiStatisticsExport } from '@/api/finance/dataDheet';
import { getTableHeader } from './utils.js';
import RefreshDataCmp from './cmp/RefreshDataCmp.vue';
import { goExportList } from '@/utils/goExportList.js';
import { feePage } from '@/api/finance/partition';
import { isPu } from '@/utils/index.js';

export default {
  name: '',

  components: {
    QueryCustomerCmp,
    RefreshDataCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          collectType: null, // 揽收类型(0:普通揽收 1:备货揽收)
          customerId: null, // 客户id
          operatorTime: '' // 业务时间
        }
      },

      feeTypeOption: [], // 所有费用代码

      total: 0,

      tableData: [],
      tableColumn: [], // 动态列

      pickerOptions: {
        // 不能选择今天及以后的时间
        disabledDate: (time) => {
          return time.getTime() >= Date.now() - 8.64e7;
        }
      }
    };
  },

  async created() {
    this.COLLECT_TYPE = COLLECT_TYPE;
    try {
      await this.getExpenseType();
      this.asyncGetTableData();
    } catch (error) {
      console.error(error);
    }
  },

  methods: {

    // 获取费用类型
    getExpenseType() {
      const param = {
        orderDirection: 'DESC',
        pageNumber: 1,
        pageSize: 10000,
        params: {
          attribute: '',
          code: ''
        }
      };
      const isPuLang = isPu();
      console.log(isPuLang, 'isPuLang');

      feePage(param).then(res => {
        if (res.code === 200) {
          // this.feeTypeOption = res.data.records;
          this.feeTypeOption = (res.data.records || []).map(item => {
            const { code, name, namePu } = item;
            return Object.freeze({
              code,
              label: isPuLang ? namePu : name
            });
          });
        }
      }).catch(err => {
        console.error(err);
      });
    },

    // 列表
    asyncGetTableData() {
      apiStatisticsPage(this.queryForm).then(res => {
        console.log(res);
        const { records, total } = res.data;

        this.total = total;

        // 动态列
        {
          const headArr = (records || []).map(item => {
            const { detail } = item;
            return JSON.parse(detail);
          });
          this.tableColumn = getTableHeader(headArr, this.feeTypeOption);
        }

        this.tableData = (records || []).map(item => {
          const { id, customerId, customerName, orderQuantity, collectType, operateTime, totalFee, detail } = item;

          const collectTypeStr = COLLECT_TYPE[collectType]?.label || '';
          const operateTimeStr = (operateTime || '').split(' ')[0] || ''; // 只显示年月日，不需要显示时分秒

          const obj = {
            id,
            customerId,
            customerName, // 客户名称
            orderQuantity, // 单量
            collectType, // 揽收类型
            collectTypeStr, // 揽收类型
            operateTimeStr, // 业务时间
            totalFee // 总费用

          };

          JSON.parse(detail).forEach(item => {
            const [key, value] = Object.entries(item)[0];
            obj[key] = value;
          });

          return Object.freeze(obj);
        });
      }).catch(err => {
        console.error(err);
      });
    },

    // 查询
    handleQuery() {
      this.queryForm.pageNumber = 1;
      this.asyncGetTableData();
    },

    // 导出
    asyncApiStatisticsExport() {
      const obj = {
        ...this.queryForm.params
      };
      apiStatisticsExport(obj).then(res => {
        goExportList(this);
      }).catch(err => {
        console.error(err);
      });
    }
  }

};
</script>

<style scoped>
.table-but {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
