<template>
  <div class="app-container">
    <div style="margin-bottom: 20px">
      <el-form
        ref="formSearch"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item :label="$t('GlobalSetObj.Date')">
              <!--网点入库时间-->
              <el-date-picker
                v-model="queryDateTime"
                :end-placeholder="$t('operationCenter.endDate')"
                :range-separator="$t('operationCenter.to')"
                :start-placeholder="$t('operationCenter.startDate')"
                style="width: 100%"
                type="daterange"
                value-format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <!--客户-->
            <el-form-item :label="$t('GlobalSetObj.client')">
              <el-select
                v-model="queryForm.params.customerIdList"
                :placeholder="$t('GlobalSetObj.all')"
                clearable
                collapse-tags
                filterable
                multiple
                style="width: 100%;"
              >
                <el-option
                  v-for="item in userList"
                  :key="item.customerId"
                  :label="item.customerName"
                  :value="item.customerId"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <div style="margin-top: 27px;">
              <el-button
                icon="el-icon-search"
                size="small"
                type="primary"
                @click="searchResources(1)"
              >{{ $t('operationCenter.Procurar') }}
              </el-button>
              <el-button icon="el-icon-refresh-left" size="small" @click="searchResources(2)">
                {{ $t('operationCenter.reset') }}
              </el-button>
              <el-button
                icon="el-icon-upload2"
                size="small" style="margin-bottom: 10px" type="primary"
                @click="allExportOrder"
              >{{ $t('newOrder.export') }}
              </el-button>
              <el-radio-group
                v-model="queryForm.params.isTest"
                size="small"
                style="margin-left: 10px"
                @click="searchResources(1)"
              >
                <el-radio-button :label="1">{{ $t('newOrder.Formal') }}</el-radio-button>
                <el-radio-button :label="2">{{ $t('newOrder.test1') }}</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#f2f2f2',color:'black', textAlign:'center' }" border>
      <el-table-column
        :label="$t('GlobalSetObj.serial')" align="center" type="index"
        width="80"
      />
      <el-table-column
        v-for="(item, ind) in columnList"
        :key="ind"
        :align="item.align"
        :min-width="item.width"
        :prop="item.prop"
        :show-overflow-tooltip="true"
      >
        <template slot="header">
          <div>{{ item.label }}</div>
        </template>
        <template slot-scope="scope">
          <div
            :class="strARR.includes(item.prop) && scope.row[item.prop] > 0 ? 'table-active' :''"
            @click="seeDetial(scope.row,item.prop,scope.row[item.prop], item.typeNum)"
          >
            {{ scope.row[item.prop] || '--' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="queryForm.pageNumber"
        :page-size.sync="queryForm.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        style="margin: 10px 0"
        @current-change="getCurrentTableData"
        @size-change="getSizeTableData"
      />
    </div>
    <!-- 包裹详情-->
    <el-drawer
      :title="$t('collectionCenter.particulars')"
      :visible.sync="dialogVisible"
      center
      direction="ltr"
      size="1200px"
      @close="closeDrawer"
    >
      <packageDetail
        v-if="dialogVisible"
        ref="votesDetails"
        :dataobj="dataObj"
        @call-parent-method="parentMethod"
      />
    </el-drawer>
  </div>
</template>

<script>
import { endcollectDataPage, endcollectDataPageExport, getCustomerListList1 } from '@/api/orderApi';
import dayjs from 'dayjs';

export default {
  name: 'FranchiseeTimelinessStatistics',
  components: {
    'packageDetail': () => import('./component/packageDetail')
  },
  data() {
    return {
      dialogVisible: false, // 详情弹窗
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          isTest: 1,
          customerIdList: [],
          startTime: '',
          endTime: ''
        }
      },
      tableData: [],
      total: 0,
      columnList: [
        // 日期
        { label: this.$t('GlobalSetObj.Date'), prop: 'dataTime', width: 160, align: 'center' },
        // 客户
        { label: this.$t('GlobalSetObj.client'), prop: 'customerName', width: 120, align: 'center' },
        // 下单票数
        { label: this.$t('changepwd.orderQuantity'), prop: 'orderQuantity', width: 180, typeNum: 1, align: 'center' },
        // 客户出仓票数
        {
          label: this.$t('changepwd.customerSendQuantity'),
          prop: 'customerSendQuantity',
          width: 180,
          typeNum: 2,
          align: 'center'
        },
        // 揽收票数
        { label: this.$t('changepwd.collectQuantity'), prop: 'collectQuantity', width: 180, typeNum: 3, align: 'center' },
        // 揽收差异
        {
          label: this.$t('changepwd.collectDifference'),
          prop: 'collectDifference',
          width: 180,
          typeNum: 4,
          align: 'center'
        },
        // 未揽收
        {
          label: this.$t('collectionCenter.notLanShou'),
          prop: 'waitCollect',
          width: 160,
          typeNum: 11,
          align: 'center'
        },
        // 疑似揽收漏扫
        { label: this.$t('changepwd.collectMissScan'), prop: 'collectMissScan', width: 180, typeNum: 5, align: 'center' },
        // 疑似揽收丢失
        { label: this.$t('changepwd.collectLost'), prop: 'collectLost', width: 180, typeNum: 6, align: 'center' },
        // 揽收装车票数
        {
          label: this.$t('changepwd.collectLoadQuantity'),
          prop: 'collectLoadQuantity',
          width: 180,
          typeNum: 7,
          align: 'center'
        },
        // 揽收装车差异
        {
          label: this.$t('changepwd.collectLoadDifference'),
          prop: 'collectLoadDifference',
          width: 180,
          typeNum: 8,
          align: 'center'
        },
        // 疑似装车漏扫
        { label: this.$t('changepwd.loadMissScan'), prop: 'loadMissScan', width: 180, typeNum: 9, align: 'center' },
        // 疑似揽收装车丢失
        { label: this.$t('changepwd.loadLost'), prop: 'loadLost', width: 180, typeNum: 10, align: 'center' }
      ],
      queryDateTime: [this.getAWeek().startDate, this.getAWeek().endDate],
      userList: [],
      dataObj: {},
      strARR: ['orderQuantity', 'customerSendQuantity', 'collectQuantity', 'collectDifference', 'collectMissScan',
        'collectLost', 'collectLoadQuantity', 'collectLoadDifference', 'loadLost', 'loadMissScan', 'waitCollect'],
      selectDate: ''
    };
  },
  created() {
    this.getUserList();
    this.getTableData(1);
  },
  methods: {
    getUserList() {
      getCustomerListList1().then(res => {
        if (res.status === 'OK') {
          this.userList = res.data;
        }
      });
    },
    // 搜索、重置
    searchResources(type) {
      if (type === 1) {
        this.queryForm.pageNumber = 1;
        this.getTableData();
      } else {
        this.queryForm = {
          pageSize: 10,
          pageNumber: 1,
          params: {
            isTest: 1,
            customerIdList: [],
            startTime: '',
            endTime: ''
          }
        };
        this.queryDateTime = [this.getAWeek().startDate, this.getAWeek().endDate];
        this.getTableData();
      }
    },
    // 获取最近30天时间
    getAWeek() {
      return {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(6, 'day').format('YYYY-MM-DD')
      };
    },
    parentMethod(arg) {
      this.dialogVisible = arg;
    },
    // 获取加盟商时效统计列表
    getTableData(val) {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.startTime = this.queryDateTime[0];
        this.queryForm.params.endTime = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      let loading = null;
      if (val === 1) {
        loading = this.$loading({
          fullscreen: true,
          lock: true,
          spinner: 'el-icon-loading',
          text: 'loading...',
          background: 'rgba(250, 250, 250, 0.6)'
        });
      }
      endcollectDataPage(this.queryForm).then(res => {
        if (res.status === 'OK') {
          this.total = res.data.total;
          this.tableData = res.data.records;
          this.tableData.forEach(item => {
            item.customerName = item.customerName || '--';
          });
          if (val === 1) {
            loading.close();
          }
        }
      }).catch(() => {
        if (val === 1) {
          loading.close();
        }
      });
    },
    allExportOrder() {
      if (this.queryDateTime && this.queryDateTime.length > 0) {
        this.queryForm.params.startTime = this.queryDateTime[0];
        this.queryForm.params.endTime = this.queryDateTime[1];
      } else {
        this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
        return;
      }
      const data = this.queryForm.params;
      endcollectDataPageExport(data).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('orderCenterCont.notRepeatTheExport')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          }).then(action => {
            if (action === 'confirm') {
              this.$router.push({ path: '/resource/exportTaskList' });
            }
          }).catch(() => {
          });
        }
      });
    },
    // 页码变化翻页
    getCurrentTableData(val) {
      this.queryForm.pageNumber = val;
      this.getTableData();
    },
    // 每页显示数量变化
    getSizeTableData(val) {
      this.queryForm.pageNumber = 1;
      this.queryForm.pageSize = val;
      this.getTableData();
    },
    closeDrawer() {
      this.dialogVisible = false;
    },
    seeDetial(row, porp, number, type) {
      console.log(row, porp);
      if (this.strARR.includes(porp)) {
        if (number) {
          if (row.id) {
            this.dataObj = {
              reportId: row.id,
              customerId: row.customerId,
              date: row.dataTime,
              isTest: this.queryForm.params.isTest,
              dataType: type
            };
          } else {
            this.dataObj = {
              reportId: row.id,
              customerIdList: this.queryForm.params.customerIdList,
              date: null,
              startTime: null,
              endTime: null,
              isTest: this.queryForm.params.isTest,
              dataType: type
            };
            if (this.queryDateTime && this.queryDateTime.length > 0) {
              this.dataObj.startTime = this.queryDateTime[0];
              this.dataObj.endTime = this.queryDateTime[1];
            } else {
              this.$message.warning(this.$t('collectionCenter.PleaseSelectTime'));
              return;
            }
          }
          this.dialogVisible = true;
        }
      }
    }
  }
};
</script>

<style scoped>
.app-container >>> .el-drawer__header {
  margin-bottom: 10px;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  color: black;
}

.table-active {
  color: #409EFF;
  cursor: pointer;
}
</style>
