<template>
  <div class="box">
    <div class="formClass">
      <el-form
        ref="queryFormCope"
        class="queryFormClass"
        :model="formData"
        :rules="rules"
        size="small"
        :label-width="$i18n.locale=='zh'?'120px':''"
        :label-position="$i18n.locale!='zh'?'top':'right'"
      >
        <el-row :gutter="20">
          <el-col v-if="operationType === 2" :span="24">
            <!--费用编码-->
            <el-form-item :label="$t('basicData.FeeCode')" prop="code">
              <span>{{ formData.code }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称-->
            <el-form-item
              :label="$t('basicData.ExpenseName')"
              prop="name"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input
                v-model.trim="formData.name" :placeholder="$t('basicData.PleaseEnter')" :maxlength="20"
                show-word-limit
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--费用名称(PU)-->
            <el-form-item
              :label="$t('basicData.ExpenseName') + '(PU)'"
              prop="namePu"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-input v-model.trim="formData.namePu" :placeholder="$t('basicData.PleaseEnter')" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--业务类型-->
            <el-form-item
              :label="$t('collectionCenter.businessT')"
              prop="businessType"
              :rules="{ required: true, message: $t('basicData.CannotBeEmpty'), trigger: ['blur', 'change'] }"
            >
              <el-select v-model="formData.businessType" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                <el-option
                  v-for="item in BUSINESS_TYPE_TWO"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--状态-->
            <el-form-item :label="$t('basicData.status')" prop="state">
              <el-radio-group v-model="formData.state" size="small">
                <!--禁用-->
                <el-radio-button :label="false">{{ $t('basicData.disable') }}</el-radio-button>
                <!--启用-->
                <el-radio-button :label="true">{{ $t('basicData.enable') }}</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!--备注-->
            <el-form-item :label="$t('basicData.remark')" prop="remark">
              <el-input
                v-model="formData.remark" type="textarea" :rows="4"
                :maxlength="200" show-word-limit :placeholder="$t('basicData.PleaseEnter')"
              />
            </el-form-item>
          </el-col>
          <template v-if="formData.businessType===3">
            <el-col :span="24">
              <!--            税费类型-->
              <el-form-item :label="$t('basicData.taxFeeType')" prop="taxationType">
                <el-select v-model="formData.taxationType" @change="taxationTypeChange">
                  <el-option
                    v-for="item in TAX_TYPE"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 税费类型选择代扣个人所得税IRRF时-->
            <template v-if="formData.taxationType===3">
              <el-col :span="24">
                <!--  基础比例-->
                <el-form-item :label="$t('basicData.basicRate')" prop="taxBase">
                  <el-input-number
                    v-model="formData.taxBase" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                    :min="0" :controls="false" :max="999999999"
                  />
                  <span>%</span>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <!-- 详细计算-->
                <el-form-item :label="$t('basicData.detailedCalculation')" :required="true" prop="ajdDetailsList">
                  <table class="tax-table">
                    <thead>
                      <tr>
                        <th>{{ $t('basicData.amountStartInclusive') }}</th>
                        <th>{{ $t('basicData.amountEndExclusive') }}</th>
                        <th>{{ $t('basicData.exemptionPercentage') }}</th>
                        <th>{{ $t('basicData.deductedAmount') }}</th>
                        <th>{{ $t('GlobalSetObj.operate') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in formData.ajdDetailsList" :key="index">
                        <td>
                          <el-form-item
                            class="tax-table-input"
                            :prop="`ajdDetailsList.${index}.startFee`" :rules="{
                              required: true,
                              trigger: ['blur', 'change'],
                              package:item.endFee,
                              validator: validatePrice,
                              negative1: 1
                            }"
                          >
                            <el-input-number
                              v-model="item.startFee" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                              :min="0" :controls="false" :max="999999999"
                            />
                          </el-form-item>
                        </td>
                        <td>
                          <el-form-item
                            class="tax-table-input"
                            :prop="`ajdDetailsList.${index}.endFee`" :rules="{
                              required: true,
                              trigger: ['blur', 'change'],
                              package:item.startFee,
                              validator: validatePrice,
                              negative1: 2
                            }"
                          >
                            <el-input-number
                              v-model="item.endFee" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                              :min="0" :controls="false" :max="999999999"
                            />
                          </el-form-item>
                        </td>
                        <td>
                          <el-form-item
                            class="tax-table-input"
                            :prop="`ajdDetailsList.${index}.reduction`" :rules="{
                              required: true,
                              message: $t('GlobalSetObj.pleaseInput'),
                              trigger: ['blur', 'change']
                            }"
                          >
                            <el-input-number
                              v-model="item.reduction" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                              :min="0" :controls="false" :max="999999999"
                            />
                          </el-form-item>
                        </td>
                        <td>
                          <el-form-item
                            class="tax-table-input"
                            :prop="`ajdDetailsList.${index}.deductionFee`" :rules="{
                              required: true,
                              message: $t('GlobalSetObj.pleaseInput'),
                              trigger: ['blur', 'change']
                            }"
                          >
                            <el-input-number
                              v-model="item.deductionFee" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                              :min="0" :controls="false" :max="999999999"
                            />
                          </el-form-item>
                        </td>
                        <td>
                          <el-button
                            v-if="index!==0" type="danger" size="mini"
                            @click="delAjdDetailsList(index)"
                          >{{ $t('GlobalSetObj.Delete') }}</el-button>
                        </td>
                      </tr>
                    </tbody>
                    <div style="text-align: center">
                      <el-button size="mini" type="primary" @click="addAjdDetailsList">{{ $t('GlobalSetObj.add') }}</el-button>
                    </div>
                  </table>
                </el-form-item>
              </el-col>
            </template>
            <!-- 税费类型选择见面INSS11%时-->
            <!-- 税费类型选择减免Sest Senat时-->
            <template v-if="formData.taxationType===2||formData.taxationType===1">
              <el-col :span="24">
                <el-form-item :label="$t('basicData.detailedCalculation')">
                  <el-row :gutter="0">
                    <el-col :span="9">
                      <!--税基-->
                      <el-form-item prop="taxBase">
                        <el-input-number
                          v-model="formData.taxBase" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                          :min="0" :controls="false" :max="999999999"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <span style="margin-left: 5px">
                        {{ $t('basicData.taxableBasePercentage') }}
                      </span>
                    </el-col>
                    <el-col :span="9">
                      <!-- 减免比例 -->
                      <el-form-item prop="reduction">
                        <el-input-number
                          v-model="formData.reduction" :placeholder="$t('GlobalSetObj.pleaseInput')" :precision="2"
                          :min="0" :controls="false" :max="999999999"
                          style="width: 100%"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="3">
                      <span style="margin-left: 5px">
                        {{ $t('basicData.exemptionRate') }}
                      </span>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </template>
          </template>
        </el-row>
      </el-form>
    </div>
    <div v-permit:remove="'btn:finance:basicData:payable:feesType-editAdd'" style="text-align: right;padding: 10px;border-top: 1px solid #ccc;">
      <!--保存-->
      <el-button type="primary" size="small" @click="saveFormData(1)">{{ $t('basicData.Save') }}</el-button>
      <!--取消-->
      <el-button size="small" @click="saveFormData(2)">{{ $t('basicData.Cancel') }}</el-button>
    </div>
  </div>
</template>

<script>
import {
  addFeeType,
  editFeeType
} from '@/api/finance/partition';
import { BUSINESS_TYPE_TWO, TAX_TYPE } from '@/views/finance/utils.js';

export default {
  name: 'CheckDetail',
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {
          name: '',
          namePu: '',
          remark: '',
          operationMode: 1, // 经营模式 默认自营
          state: true,
          businessType: null,
          id: '',
          attribute: 2
        };
      }
    },
    operationType: {
      type: Number,
      default: 1
    }
  },

  data() {
    // 校验价格
    const validatePrice = (rule, value, callback) => {
      console.log(value, rule.package);
      if (!value) {
        return callback(new Error(this.$i18n.t('GlobalSetObj.pleaseInput'))); // 请输入
      }
      if (rule.package !== null && rule.package !== '' && rule.package !== undefined) {
        if (rule.negative1 === 2) {
          if (parseFloat(value) <= parseFloat(rule.package)) {
            return callback(new Error(this.$i18n.t('basicData.MustGreaterThanStartingValue'))); // 必须大于起始值
          } else {
            return callback();
          }
        } else {
          if (parseFloat(value) >= parseFloat(rule.package)) {
            return callback(new Error(this.$i18n.t('basicData.MustLessThanEndValue'))); // 必须小于结束值
          } else {
            return callback();
          }
        }
      }
      return callback();
    };
    return {
      formData: {
        name: '',
        namePu: '',
        remark: '',
        state: true,
        businessType: null,
        id: '',
        attribute: 2,
        taxBase: '', // 基础比例
        reduction: '', // 减免比例
        taxationType: '' // 税费类型
      },
      rules: {
        taxationType: [
          { required: true, message: this.$t('GlobalSetObj.pleaseChoose'), trigger: ['blur', 'change'] }
        ],
        taxBase: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
        ],
        reduction: [
          { required: true, message: this.$t('GlobalSetObj.pleaseInput'), trigger: ['blur', 'change'] }
        ]
      },
      validatePrice
    };
  },

  watch: {
    'detailData': {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.code) {
          this.formData = {
            ...this.formData,
            ...val
          };
        }
      }
    }
  },

  created() {
    this.BUSINESS_TYPE_TWO = BUSINESS_TYPE_TWO; // 业务类型
    this.TAX_TYPE = TAX_TYPE; // 税费类型
  },

  methods: {
    saveFormData(type) {
      if (type === 1) {
        this.$refs['queryFormCope'].validate((valid) => {
          if (valid) {
            const { name, namePu, id, remark, state, businessType, attribute, taxationType } = this.formData;
            const param = {
              name,
              namePu,
              id,
              remark,
              state,
              businessType,
              attribute,
              taxationType
            };
            // 税费类型不为否时
            if (this.formData.taxationType !== 0) {
              if (this.formData.taxationType === 3) {
                // 代扣个人所得税IRRF
                param.ajdDetailsList = this.formData.ajdDetailsList;
                param.taxBase = this.formData.taxBase;
              } else {
                // 其他
                param.taxBase = this.formData.taxBase;
                param.reduction = this.formData.reduction;
              }
            }
            if (this.operationType === 1) {
              //  新增
              addFeeType(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            } else {
              // 存在ajdID时添加进来
              if (this.formData?.ajdId) {
                param.ajdId = this.formData.ajdId;
              }
              editFeeType(param).then(res => {
                if (res.code === 200) {
                  this.$message.success(this.$i18n.t('basicData.TheOperationSucceeded')); // 操作成功
                  this.$emit('saveChange', type);
                }
              }).catch(err => {
                console.error(err);
              });
            }
          } else {
            console.error('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('saveChange', type);
      }
    },
    /**
     * @description 税费类型切换
     */
    taxationTypeChange() {
      console.log('触发');
      // 代扣个人所得税IRRF
      if (this.formData.taxationType === 3) {
        this.$set(this.formData, 'ajdDetailsList', [
          {
            startFee: '',
            endFee: '',
            reduction: '',
            deductionFee: ''
          }
        ]);
        console.log(this.formData.ajdDetailsList);
      } else if (this.formData.taxationType === 0) {
        // 判断ajdDetailsList是否存在，存在删除
        if (this.formData?.ajdDetailsList) {
          this.$delete(this.formData, 'ajdDetailsList');
        }
        // 再判断taxBase和reduction是否存在，存在删除
        if (this.formData?.taxBase) {
          this.$delete(this.formData, 'taxBase');
        }
        if (this.formData?.reduction) {
          this.$delete(this.formData, 'reduction');
        }
      } else {
        // 判断ajdDetailsList是否存在，存在删除
        if (this.formData?.ajdDetailsList) {
          this.$delete(this.formData, 'ajdDetailsList');
        }
        this.formData.taxBase = '';
        this.formData.reduction = '';
      }
    },
    /**
     * @description 添加详细计算
     */
    addAjdDetailsList() {
      this.formData.ajdDetailsList.push({
        startFee: '',
        endFee: '',
        reduction: '',
        deductionFee: ''
      });
    },
    /**
     * @description 删除详细计算
     */
    delAjdDetailsList(index) {
      this.formData.ajdDetailsList.splice(index, 1);
    }
  }
};
</script>

<style scoped>
.formClass {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 20px;
}
.tax-table-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.tax-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.tax-table th,
.tax-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.tax-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.tax-table tr:nth-child(even) {
  background-color: #fafafa;
}

.tax-table tr:hover {
  background-color: #f0f0f0;
}

.queryFormClass /deep/ .tax-table-input {
  margin-bottom: 0;
}

.queryFormClass /deep/ .el-form-item__error {
  position: sticky;
  text-align: left;
}
</style>
