<template>
  <div class="app-container">
    <el-card class="box-card">
      <el-form
        ref="queryForm"
        class="queryFormClass"
        :model="queryForm"
        label-position="top"
        size="small"
      >
        <el-row :gutter="10">
          <el-col :span="6">
            <!--开票时间-->
            <el-form-item :label="$t('GlobalSetObj.InvoicingTime')" prop="invoicingTime">
              <el-date-picker
                v-model="queryDate"
                type="datetimerange"
                style="width: 100%;"
                :format="$i18n.locale==='zh'?zhDate:ptDate"
                :placeholder="$t('basicData.SelectADatetime')"
                :picker-options="pickerOptions"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                @blur="refreshDate"
              />
            </el-form-item>
          </el-col>
          <!--          <el-col :span="6">-->
          <!--            &lt;!&ndash;开票主体&ndash;&gt;-->
          <!--            <el-form-item :label="$t('basicData.BillingBody')" prop="providerInfoId">-->
          <!--              <el-select-->
          <!--                v-model="queryForm.params.providerId"-->
          <!--                filterable-->
          <!--                clearable-->
          <!--                remote-->
          <!--                reserve-keyword-->
          <!--                :remote-method="getProviderPage"-->
          <!--                :placeholder="$t('spareNumberPoo.Only10AreDisplayed')"-->
          <!--                style="width: 100%;"-->
          <!--                @focus="getProviderPage()"-->
          <!--              >-->
          <!--                <el-option-->
          <!--                  v-for="item in providerList"-->
          <!--                  :key="item.id"-->
          <!--                  :label="item.providerName + '（' + item.code + '）'"-->
          <!--                  :value="item.id"-->
          <!--                />-->
          <!--              </el-select>-->
          <!--            </el-form-item>-->
          <!--          </el-col>-->
          <el-col :span="6">
            <!--业务类型-->
            <el-form-item :label="$t('collectionCenter.businessT')" prop="businessType">
              <el-select
                v-model="queryForm.params.businessType" style="width: 100%;" clearable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
              >
                <!--揽收-->
                <el-option :label="$t('collectionCenter.collect')" value="COLLECT" />
                <!--派送-->
                <el-option :label="$t('collectionCenter.dispatch')" value="DELIVERY" />
                <el-option label="SFS" value="SFS" />
                <!--                &lt;!&ndash;转运&ndash;&gt;-->
                <!--                <el-option key="3" :label="$t('collectionCenter.turnTransport')" :value="3" />-->
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--发票状态-->
            <el-form-item :label="$t('basicData.InvoiceStatus')" prop="invoiceStatus">
              <el-select
                v-model="queryForm.params.invoiceStatus" style="width: 100%;" clearable
                :placeholder="$t('GlobalSetObj.pleaseChoose')"
              >
                <!--成功-->
                <el-option
                  v-for="(value, key) in invoiceStatusObj" :key="key" :label="value"
                  :value="key"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <!--发票号码-->
            <el-form-item class="searchNumberType">
              <template slot="label">
                <el-select v-model="searchNumberType" style="width: 100%;" :placeholder="$t('GlobalSetObj.pleaseChoose')">
                  <!--发票号码-->
                  <el-option key="1" :label="$t('basicData.InvoiceNumber')" :value="1" />
                  <!--业务单号-->
                  <el-option key="2" :label="$t('receivablePayable.TicketNumber')" :value="2" />
                </el-select>
              </template>
              <el-input
                v-model="searchNumber" type="textarea" :rows="4"
                :placeholder="$t('newOrder.PreciseSearch1')"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row type="flex" justify="start">
        <el-col :span="8">
          <div>
            <!--查询-->
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getDataSearch()"
            >{{ $t('basicData.Search') }}
            </el-button>
            <!--重置-->
            <el-button
              icon="el-icon-refresh"
              size="mini"
              @click="getDataSearch(0)"
            >{{ $t('basicData.reset') }}
            </el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card" style="margin-top: 10px;">
      <div style="margin-bottom: 10px;">
        <!--        <el-button-->
        <!--          size="mini"-->
        <!--          type="warning"-->
        <!--          @click="reopenCancel({},1)"-->
        <!--        >-->
        <!--          &lt;!&ndash;重开&ndash;&gt;-->
        <!--          {{ $t('basicData.reopen') }}-->
        <!--        </el-button>-->
        <!--        <el-button-->
        <!--          size="mini"-->
        <!--          type="danger"-->
        <!--          @click="reopenCancel({},2)"-->
        <!--        >-->
        <!--          &lt;!&ndash;取消&ndash;&gt;-->
        <!--          {{ $t('basicData.Cancel') }}-->
        <!--        </el-button>-->
        <el-button
          size="mini"
          type="primary"
          @click="bulkDownload()"
        >
          <!--批量下载-->
          {{ $t('orderCenterCont.BulkDownload') }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="exportData()"
        >
          <!--导出-->
          {{ $t('collectionCenter.export') }}
        </el-button>
        <el-button
          size="mini"
          type="primary"
          @click="onExportusinessOrderNumberInvoiceNumber"
        >
          <!--按业务单号或发票号导出-->
          {{ $t('basicData.numberOrInvoiceNumber') }}
        </el-button>
      </div>
      <el-table
        ref="elTable"
        :data="tableData"
        class="table-height"
        :height="tableHeightRow.height"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="55"
          align="center"
        />
        <!--序号-->
        <el-table-column :label="$t('basicData.serialNumber')" width="80px" align="center">
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!--发票类型-->
        <el-table-column
          :label="$t('collectionCenter.invoiceT')"
          width="160"
          align="center"
        >
          <template>
            <p>CTE</p>
          </template>
        </el-table-column>
        <!--开票时间-->
        <el-table-column
          :label="$t('GlobalSetObj.InvoicingTime')"
          width="160"
          align="center"
          prop="invoiceIssueTime"
        />
        <!--业务类型-->
        <el-table-column :label="$t('collectionCenter.businessT')" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.businessType }}
          </template>
        </el-table-column>
        <!--发票号码-->
        <el-table-column :label="$t('basicData.InvoiceNumber')" width="160" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="top-end"
              popper-class="copy"
              trigger="hover"
            >
              <!--复制-->
              <el-button type="text" @click="copy(scope.row.invoiceNumber)">{{ $t('orderDetails.copy') }}</el-button>
              <span slot="reference" style="color: #409eff;cursor: pointer;" @click="checkDetail(scope.row.id)">{{ scope.row.invoiceNumber }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <!--发票密钥-->
        <el-table-column
          :label="$t('newOrder.InvoiceKey')"
          width="160"
          align="center"
          prop="invoiceSecretKey"
        />
        <!--业务单号-->
        <el-table-column
          :label="$t('receivablePayable.TicketNumber')"
          width="160"
          align="center"
          prop="businessNumber"
        />
        <!--开票主体-->
        <el-table-column
          :label="$t('basicData.BillingBody')"
          width="160"
          align="center"
          prop="invoiceEntity"
        />
        <!--状态-->
        <el-table-column :label="$t('basicData.status')" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.invoiceStatus }}
            <!--            &lt;!&ndash;成功&ndash;&gt;-->
            <!--            <el-tag v-if="scope.row.invoiceStatus === 1" type="success">{{ $t('GlobalSetObj.succeed') }}</el-tag>-->
            <!--            &lt;!&ndash;失败&ndash;&gt;-->
            <!--            <el-tag v-else-if="scope.row.invoiceStatus === 2" type="danger">{{ $t('GlobalSetObj.Lose') }}</el-tag>-->
            <!--            &lt;!&ndash;取消&ndash;&gt;-->
            <!--            <el-tag v-else-if="scope.row.invoiceStatus === 3" type="info">{{ $t('basicData.Cancel') }}</el-tag>-->
          </template>
        </el-table-column>
        <!--发票总金额-->
        <el-table-column
          :label="$t('receivablePayable.totalInvoiceAmount')"
          align="center"
          prop="invoiceTotalAmount"
          width="120"
        />
        <!--应收总金额-->
        <!--        <el-table-column-->
        <!--          :label="$t('receivablePayable.TotalAmountReceivable')"-->
        <!--          align="center"-->
        <!--        />-->
        <!--        icms金额-->
        <el-table-column
          :label="$t('receivablePayable.ICMSAmount')"
          align="center"
          prop="icmsAmount"
          width="120"
        />
        <!--        运费金额-->
        <el-table-column
          :label="$t('receivablePayable.shippingAmount')"
          align="center"
          prop="freightAmount"
          width="120"
        />
        <!--        开票主体cnpj-->
        <el-table-column
          :label="$t('receivablePayable.BillingSubjectCNPJ')"
          align="center"
          prop="invoiceEntityCnpj"
          width="140"
        />
      </el-table>
      <!-- 分页工具 -->
      <div class="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :current-page.sync="queryForm.pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size.sync="queryForm.pageSize"
          :total="total"
          background
          style="margin: 10px 0"
          @current-change="getTableData"
          @size-change="getTableData"
        />
      </div>
    </el-card>

    <!--  按业务单号或发票号导出  -->
    <ExportusinessOrderNumberInvoiceNumberCmp ref="exportusinessOrderNumberInvoiceNumberRef" />
  </div>
</template>

<script>
import { copyData } from '@/utils/copyData';
import {
  apiProviderPage, faBrCteLabel, logisticsInvoiceCancel,
  logisticsInvoiceExport,
  logisticsInvoicePage,
  logisticsInvoiceReGenerateCte
} from '@/api/finance/basicData';
import { batchPdfDownload } from '@/utils/PDF_util';
import { setDateTime2 } from '@/utils/date_util';
import dayjs from 'dayjs';
import { getUserIdAndNameObj } from '@/enums/storageEnum';
import ExportusinessOrderNumberInvoiceNumberCmp from './cmp/ExportusinessOrderNumberInvoiceNumberCmp.vue';
import estimateTableHeight from '@/views/finance/mixins/estimateTableHeight.js';
import { goExportList } from '@/utils/goExportList.js';
import { saveAs } from 'file-saver';

export default {
  name: 'InvoiceManagement',

  components: {
    ExportusinessOrderNumberInvoiceNumberCmp
  },

  mixins: [estimateTableHeight],

  data() {
    return {
      userIdAndNameObj: {}, // 用户列表，对象
      queryDate: [], // 开票时间
      queryForm: {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerId: null, // 开票主体
          businessType: null, // 业务类型 1:揽收 2：派送 3：转运
          invoiceStatus: null// 发票状态 1:开票成功 2:开票失败 3:取消
        }
      },
      zhDate: 'yyyy-MM-dd HH:mm:ss',
      ptDate: 'dd-MM-yyyy HH:mm:ss',
      searchNumberType: 1, // 1:发票号码 2:业务单号
      searchNumber: '',
      tableData: [],
      total: 0,
      selectedData: [],
      providerList: [], // 开票主体列表
      pickerOptions: {
        // 设置不能选择的日期
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate0 = minDate.getTime();
          if (maxDate) {
            this.choiceDate0 = '';
          }
        },
        disabledDate: (time) => {
          const choiceDateTime = new Date(this.choiceDate0).getTime();
          const minTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() - 14
          );
          const maxTime = new Date(choiceDateTime).setDate(
            new Date(choiceDateTime).getDate() + 14
          );
          const min = minTime;
          const todayEnd = new Date();
          todayEnd.setHours(23, 59, 59, 999); // 今天的结束时间
          const max = todayEnd.getTime() < maxTime ? todayEnd.getTime() : maxTime; // 选择今天或今天之前的日期

          // 如果已经选中一个日期，则返回该日期前后 15 天时间可选，但不能超过今天
          if (this.choiceDate0) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中，则返回当前日期以前日期可选
          return time.getTime() > todayEnd.getTime();
        }
      },
      // 发票状态
      invoiceStatusObj: {
        'AUTORIZADO': `AUTORIZADO（${this.$t('GlobalSetObj.succeed')}）`,
        'CANCELADO': `CANCELADO（${this.$t('GlobalSetObj.cancel')}）`,
        'CORRIGIDO': `CORRIGIDO（${this.$t('receivablePayable.correct')}）`,
        'FALHA NA EMISSÃO': `FALHA NA EMISSÃO（${this.$t('GlobalSetObj.Lose')}）`,
        'NO RESPONSE': `NO RESPONSE（${this.$t('receivablePayable.noReply')}）`
      }

    };
  },
  created() {
    this.queryDate = [dayjs().format('YYYY-MM-DD') + ' 00:00:00', dayjs().format('YYYY-MM-DD') + ' 23:59:59'];
    this.getTableData();
    // 获取用户列表
    this.userIdAndNameObj = getUserIdAndNameObj();
  },
  methods: {
    // 勾选数据
    handleSelectionChange(data) {
      this.selectedData = data;
    },
    // 点击复制
    copy(data) {
      copyData(data);
    },
    // 操作
    operation(row, type) {},
    /**
     * @description 批量下载
     */
    async bulkDownload() {
      if (this.selectedData && this.selectedData.length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.CheckForm'));
        return;
      }
      // 获取invoiceNumbers列表最后逗号拼接
      const ids = this.selectedData.map(item => item.id).join(',');
      // 手动显示加载中动画
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        spinner: 'el-icon-loading',
        text: this.$i18n.t('orderCenterCont.DesperatelyLoading'), // '拼命加载中',
        background: 'rgba(250, 250, 250, 0.6)'
      });

      faBrCteLabel({ ids }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.length) {
            batchPdfDownload(res.data).then((res) => {
              // const saveName = setDateTime2() + '.pdf';
              // saveAs(res, saveName);
              const dateType = setDateTime2();
              const saveName = `${dateType}` + '.pdf';
              saveAs(res, saveName);
              // const a = document.createElement('a');
              // a.download = `${dateType}`;
              // a.href = res;
              // a.download = `${dateType}` + '.pdf';
              // a.click();
              // a.remove();
            }).finally(() => {
              loading.close();
            });
          } else {
            loading.close();
          }
        } else {
          loading.close();
        }
      }).catch((err) => {
        console.error(err);
        loading.close();
      });
    },
    /**
     * @description 导出
     */
    exportData() {
      const params = this.getParams().params;
      if (params.invoiceNumberList > 2000 || params.businessNumberList > 2000) {
        this.$message.warning(this.$t('orderCenterCont.queried2000pieces'));
        return;
      }
      if (this.selectedData && this.selectedData.length) {
        params.ids = this.selectedData.map(item => item.id);
      }
      params.exportType = 1;
      logisticsInvoiceExport(params).then(res => {
        goExportList(this);
      });
    },

    /**
     * @description 重开/取消
     * @param type 1:重开 2:取消
     */
    reopenCancel(row, type) {
      if (this.selectedData.length === 0 && Object.keys(row).length === 0) {
        this.$message.warning(this.$t('GlobalSetObj.pleaseChoose'));
        return;
      }
      const params = new URLSearchParams();
      if (row && Object.keys(row).length > 0) {
        params.append('ids', row.id);
      } else {
        const ids = this.selectedData.map(item => item.id);
        params.append('ids', ids);
      }
      // 重开
      if (type === 1) {
        this.$confirm(this.$t('GlobalSetObj.doYouWantToContinue'), this.$t('basicData.tips'), {
          confirmButtonText: this.$t('basicData.determine'),
          cancelButtonText: this.$t('basicData.Cancel'),
          type: 'warning'
        }).then(() => {
          // 重开
          logisticsInvoiceReGenerateCte(params).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded'));
              this.getTableData();
            }
          });
        }).catch(() => {});
      } else {
        // 取消
        this.$confirm(this.$t('GlobalSetObj.doYouWantToContinue'), this.$t('basicData.tips'), {
          confirmButtonText: this.$t('basicData.determine'),
          cancelButtonText: this.$t('basicData.Cancel'),
          type: 'warning'
        }).then(() => {
          // 取消
          logisticsInvoiceCancel(params).then(res => {
            if (res.code === 200) {
              this.$message.success(this.$t('basicData.TheOperationSucceeded'));
              this.getTableData();
            }
          });
        }).catch(() => {});
      }
    },
    // 查看发票
    checkDetail(val) {
      faBrCteLabel({ ids: val })
        .then((res) => {
          if (res.code === 200) {
            const url = res.data[0];
            if (url) {
              window.open(url);
            }
          }
        });
    },
    /**
     * @description 获取查询数据
     * @param type 1:查询 0:重置
     */
    getDataSearch(type = 1) {
      if (type === 1) {
        this.getTableData();
      } else {
        this.queryDate = [dayjs().format('YYYY-MM-DD') + ' 00:00:00', dayjs().format('YYYY-MM-DD') + ' 23:59:59'];
        this.queryForm.params.providerId = null;
        this.queryForm.params.businessType = null;
        this.queryForm.params.invoiceStatus = null;
        this.queryForm.pageNumber = 1;
        this.queryForm.pageSize = 10;
        this.searchNumberType = 1;
        this.searchNumber = '';
      }
    },
    /**
     * @description 获取查询导出参数
     * @returns {{pageNumber: number, pageSize: number, params: {invoicingTime: (string|null), providerId: null, businessType: null, invoiceStatus: null, searchNumberType: number, searchNumber: string}}}
     */
    getParams() {
      // 查询时间
      let [invoiceIssueTimeStart, invoiceIssueTimeEnd] = ['', ''];
      if (this.queryDate && this.queryDate.length > 0) {
        invoiceIssueTimeStart = this.queryDate[0];
        invoiceIssueTimeEnd = this.queryDate[1];
      }
      // 查询单号
      let [invoiceNumberList, businessNumberList] = [[], []];
      if (this.searchNumber) {
        const numberList = [];
        const numbers = this.searchNumber.split('\n');
        numbers.map(item => {
          if (item) {
            numberList.push(item.trim());
          }
        });
        if (this.searchNumberType === 1) {
          invoiceNumberList = numberList;
        } else {
          businessNumberList = numberList;
        }
      }
      const params = {
        pageSize: this.queryForm.pageSize,
        pageNumber: this.queryForm.pageNumber,
        params: {
          invoiceIssueTimeStart,
          invoiceIssueTimeEnd,
          providerId: this.queryForm.params.providerId,
          businessType: this.queryForm.params.businessType,
          invoiceStatus: this.queryForm.params.invoiceStatus,
          invoiceNumberList,
          businessNumberList
        }
      };
      return params;
    },
    /**
     * @description 获取表格数据
     */
    getTableData() {
      const params = this.getParams();
      if (params.params.invoiceNumberList.length > 2000 || params.params.businessNumberList.length > 2000) {
        this.$message.warning(this.$t('orderCenterCont.queried2000pieces'));
        return;
      }
      logisticsInvoicePage(params).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    /**
     * @description 开票主体列表
     * @param name
     */
    getProviderPage(name = '') {
      const param = {
        pageSize: 10,
        pageNumber: 1,
        params: {
          providerName: name,
          state: 1
        }
      };
      apiProviderPage(param, false).then(res => {
        if (res.code === 200) {
          this.providerList = res.data.records;
        }
      });
    },
    // 日期选择器失去焦点
    refreshDate() {
      this.choiceDate0 = '';
    },

    // 按业务单号或发票号导出
    onExportusinessOrderNumberInvoiceNumber() {
      this.$refs.exportusinessOrderNumberInvoiceNumberRef.onOpen();
    }
  }
};
</script>
<style>
.searchNumberType .el-form-item__label .el-select .el-input .el-input__inner,
.searchNumberType .el-form-item__label .el-select .el-input .el-input__icon{
  height: 28px;
  line-height: 28px;
  border: none;
  padding: 0;
}
.searchNumberType .el-form-item__label .el-select .el-input .el-input__inner{
  font-weight: bold;
  font-size: 14px;
}
</style>
<style scoped>

</style>
