import request from '@/utils/request';

const baseUserURL = process.env.VUE_APP_USER;

export function getRoleList() {
  return request({
    url: '/role/list',
    method: 'get',
    baseURL: baseUserURL
  });
}

export function getRolePage(data) {
  return request({
    // url: '/role/page',
    url: '/role/page',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

export function addRole(data) {
  return request({
    url: '/role/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

export function editRole(data) {
  return request({
    url: '/role/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

export function deleteRole(roleId) {
  return request({
    url: '/role/delete',
    method: 'post',
    params: { roleId },
    baseURL: baseUserURL
  });
}

export function getRoleInfo(roleId) {
  return request({
    url: '/role/getRoleInfo',
    method: 'get',
    params: { roleId },
    baseURL: baseUserURL
  });
}

// 查看字符串权限
export function getPermissionStructureByField() {
  return request({
    url: '/permission/getPermissionStructureByField',
    method: 'get',
    baseURL: baseUserURL
  });
}

/* roleName:角色名称*/
export function checkRoleParameter(roleName) {
  return request({
    url: '/role/check',
    method: 'get',
    params: { roleName },
    isLoading: false,
    baseURL: baseUserURL
  });
}

/**
 * 应用权限
 * @param data 角色ID
 * @returns {*}
 */
export function applyRole(data) {
  return request({
    url: '/role/applyRole',
    method: 'post',
    params: data,
    baseURL: baseUserURL
  });
}

export function getPmRolePage(data) {
  return request({
    url: '/pmRole/page',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 查询权限树
export function getPermissionStructure(data) {
  return request({
    url: '/pmPermission/getPermissionStructure',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 新增角色
export function addPmRole(data) {
  return request({
    url: '/pmRole/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 编辑角色
export function editPmRole(data) {
  return request({
    url: '/pmRole/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 新增供应商权限
export function addPmPermissionApi(data) {
  return request({
    url: '/pmPermission/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 编辑供应商权限
export function editPmPermissionApi(data) {
  return request({
    url: '/pmPermission/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 删除权限
export function deletePmPermissionApi(data) {
  return request({
    url: '/pmPermission/delete',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 供应商账号分页列表
export function pmUserPageApi(data) {
  return request({
    url: '/pmUser/page',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 新增供应商角色
export function pmUserAddApi(data) {
  return request({
    url: '/pmUser/add',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 启用、禁用供应商账户状态
export function updateStateApi(data) {
  return request({
    url: '/pmUser/updateState',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 留言板分页查询
export function messageBoardPageApi(data) {
  return request({
    url: '/messageBoard/getPage',
    method: 'post',
    data
  });
}

// 导出留言
export function messageBoardExportApi(data) {
  return request({
    url: '/messageBoard/export',
    method: 'post',
    data
  });
}

// 揽收供应商kpi
export function collectProviderpageInfo(data) {
  return request({
    url: '/collectProvider/pageInfo',
    method: 'post',
    data
  });
}

// 待退回包裹查询
export function paraSerDevolvido(data) {
  return request({
    url: '/unreturned/page',
    method: 'post',
    data
  });
}

// 批量处理待退回包裹
export function disposeWaybill(data) {
  return request({
    url: '/unreturned/disposeWaybill',
    method: 'post',
    data
  });
}

// 重置供应商账号密码
export function apiResetPwd(data) {
  return request({
    url: '/pmUser/resetPwd',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 供应商账号编辑
export function apiPmUserEdit(data) {
  return request({
    url: '/pmUser/edit',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 获取操作手册菜单
export function apiPmOperationDocGetTree(data) {
  return request({
    url: '/operationDoc/getTree',
    method: 'post',
    data
  });
}

// 添加操作手册菜单
export function apiPmOperationDocAdd(data) {
  return request({
    url: '/operationDoc/add',
    method: 'post',
    data
  });
}

// 编辑操作手册菜单
export function apiPmOperationDocUpdate(data) {
  return request({
    url: '/operationDoc/update',
    method: 'post',
    data
  });
}

// 删除操作手册菜单
export function apiPmOperationDocDeleted(data) {
  return request({
    url: '/operationDoc/deleted',
    method: 'post',
    data
  });
}

// 获取操作手册菜单内容
export function apiPmOperationDocGetById(data) {
  return request({
    url: '/operationDoc/getById?id=' + data,
    method: 'get'
  });
}

// 新增供应商角色
export function getSiteInfo(data) {
  return request({
    url: '/pmUser/getSiteInfo',
    method: 'post',
    data,
    baseURL: baseUserURL
  });
}

// 添加问答
export function questionAdd(data) {
  return request({
    url: '/operation/question/add',
    method: 'post',
    data
  });
}

// 获取问题列表
export function questionlist(data) {
  return request({
    url: '/operation/question/page',
    method: 'post',
    data
  });
}

// 添加问答
export function questionUpdate(data) {
  return request({
    url: '/operation/question/update',
    method: 'post',
    data
  });
}

// 删除问题
export const deleteQuestion = id => {
  return request.delete(`/operation/question/${id}`);
};
