import i18n from '@/lang';
import { MessageBox } from 'element-ui';
import router from '@/router/index.js';

//  导入成功 + 前往 + 文件上传管理查看详情
const FileUploadManagement = i18n.t('orderCenterCont.ImportWasSuccessful') + '!' + i18n.t('newOrder.togo') + '《' + i18n.t('route.FileUploadManagement') + '》' + i18n.t('orderCenterCont.ViewDetails');

/**
 * 导出回调函数
 *    在页面中使用goExportList.call(this);
 * @this {vue} that this
 * @returns {Promise<boolean>}
 */
export function goExportList(that, url = '/resource/exportTaskList') {
  const h = that.$createElement;
  return that.$msgbox({
    // '提示'
    title: i18n.t('collectionCenter.tips'),
    message: h('p', null, [
      // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
      h('span', i18n.t('collectionCenter.ExportSuccessful')),
      // '请勿重复导出！'
      h('span', { style: 'color: red' }, i18n.t('collectionCenter.notRepeatTheExport'))
    ]),
    showCancelButton: true,
    // '立即前往'
    confirmButtonText: i18n.t('collectionCenter.Immediately'),
    // '关闭'
    cancelButtonText: i18n.t('collectionCenter.close')
  }).then(action => {
    if (action === 'confirm') {
      that.$router.push({ path: url });
      return true;
    }
    return false;
  }).catch(() => {
    return false;
  });
}

// 前往文件管理页面
export function goFileUploadManagement(that) {
  const h = that.$createElement;
  return that.$msgbox({
    // '提示'
    title: i18n.t('collectionCenter.tips'),
    message: h('p', null, [
      // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'FileUploadManagement
      h('span', FileUploadManagement),
      // '请勿重复导出！'
      h('span', { style: 'color: red' }, i18n.t('GlobalSetObj.notrepeatOperation'))
    ]),
    showCancelButton: true,
    // '立即前往'
    confirmButtonText: i18n.t('collectionCenter.Immediately'),
    // '关闭'
    cancelButtonText: i18n.t('collectionCenter.close')
  }).then(action => {
    if (action === 'confirm') {
      that.$router.push({ name: 'FileUploadManagement' });
      return true;
    }
    return false;
  }).catch(() => {
    return false;
  });
}

// 前往资源任务列表 提示内容
const ResourceTaskListStr = `
  <div>
    <p>${i18n.t('orderCenterCont.ImportWasSuccessful')} ${i18n.t('newOrder.togo')} 《${i18n.t('route.TheResourceTaskList')}》</p>
    <p style="color: red">${i18n.t('GlobalSetObj.notrepeatOperation')}</p>
  </div>
`;

// 前往资源任务列表
export function goResourceTaskList() {
  MessageBox.confirm(ResourceTaskListStr, i18n.t('collectionCenter.tips'), {
    dangerouslyUseHTMLString: true,
    confirmButtonText: i18n.t('collectionCenter.Immediately'), // 立即前往
    cancelButtonText: i18n.t('collectionCenter.close') // 关闭
  }).then(res => {
    router.push({ name: 'exportTaskList' });
  }).catch(err => {
    console.error(err);
  });
}

// 导出跳转页面处理
export function goExport(that, data) {
  if (data && data.taskId) {
    // 跳转到资源任务列表
    goResourceTaskList();
    return;
  }

  // 跳转到资源导出列表
  goExportList(that);
}

// 导出跳转页面处理
export function goExportPage(data) {
  if (data && data.taskId) {
    // 跳转到资源任务列表

    return '/resource/exportTaskList';
  }

  // 跳转到资源导出列表
  return '/resource/exportList';
}
