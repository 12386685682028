import { render, staticRenderFns } from "./SMSManagement.vue?vue&type=template&id=108cc0b3&scoped=true"
import script from "./SMSManagement.vue?vue&type=script&lang=js"
export * from "./SMSManagement.vue?vue&type=script&lang=js"
import style0 from "./SMSManagement.vue?vue&type=style&index=0&id=108cc0b3&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "108cc0b3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('108cc0b3')) {
      api.createRecord('108cc0b3', component.options)
    } else {
      api.reload('108cc0b3', component.options)
    }
    module.hot.accept("./SMSManagement.vue?vue&type=template&id=108cc0b3&scoped=true", function () {
      api.rerender('108cc0b3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/collectionCenter/Report/SMSManagement.vue"
export default component.exports