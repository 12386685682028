<template>
  <div>
    <div>
      <el-button
        icon="el-icon-download"
        size="small"
        type="primary"
        @click="operationBtn"
      >{{ $t('receivablePayable.DownloadTheTemplate') }}</el-button>
      <upload-excel
        :on-success="importLogistics"
        :but-name="$t('basicData.ImportAddRegion')"
        style="display: inline-block;margin-left: 20px;"
      />
    </div>
  </div>
</template>

<script>
import { apiAreaImportAdd } from '@/api/finance/basicData';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');
import uploadExcel from '@/components/UploadExcel';

export default {
  name: 'ImportArea',
  components: {
    uploadExcel
  },
  data() {
    return {
      errData: []
    };
  },
  methods: {
    // 下载模板
    operationBtn() {
      const that = this;
      async function ddd() {
        const wb = new ExcelJS.Workbook();
        const Sheet1 = wb.addWorksheet('Sheet1');
        Sheet1.columns = [
          {
            header: '州名',
            key: 'aName',
            width: 40
          },
          {
            header: '州二字码',
            key: 'twoCode',
            width: 20
          },
          {
            header: '城市名',
            key: 'cName',
            width: 20
          },
          {
            header: '城市邮编',
            key: 'zipCode',
            width: 50
          },
          {
            header: 'ISS税率(%)',
            key: 'issTaxation',
            width: 20
          }
        ];
        // Sheet1.getCell('A2').value = 'Estado';
        // Sheet1.getCell('B2').value = 'Código de duas palavras';
        // Sheet1.getCell('C2').value = 'cidade';
        // Sheet1.getCell('D2').value = 'Código postal';
        const buffer = await wb.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/octet-stream'
        });
        const saveName = that.$t('basicData.ImportAddRegion') + '.xlsx';
        saveAs(blob, saveName);
        return;
      }
      ddd();
    },
    // 导入费用
    importLogistics(array) {
      const dataArr = [];
      const arrData = array[0].results;
      const logisticsKey = {
        '州名': 'aName',
        '州二字码': 'twoCode',
        '城市名': 'cName',
        '城市邮编': 'zipCode',
        'ISS税率(%)': 'issTaxation'
      };
      arrData.map((item) => {
        const dataObj = {};
        for (const k in logisticsKey) {
          if (item[k]) {
            dataObj[logisticsKey[k]] = item[k];
          } else {
            dataObj[logisticsKey[k]] = '';
          }
        }
        dataArr.push(dataObj);
      });
      const param = [];
      this.errData = {};
      // const errCode = [];
      dataArr.map((item, ind) => {
        const obj = {
          parentNode: {
            name: item.aName,
            twoCode: item.twoCode
          },
          name: item.cName,
          zipCode: item.zipCode,
          issTaxation: item.issTaxation
        };
        param.push(obj);
      });
      apiAreaImportAdd(param).then(res => {
        if (res.code === 200) {
          this.$message.success(this.$t('basicData.TheOperationSucceeded'));
          this.$emit('importChange');
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
