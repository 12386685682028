import request from '@/utils/request';

const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 财务分区分页
export const apiByServiceCode = (data, isBool = true) => {
  return request({
    method: 'POST',
    url: '/siteExpense/export/byServiceCode',
    data,
    isLoading: isBool, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};
