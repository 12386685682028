var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", size: "small" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Resource.licensePlateNumber") } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.queryForm.params.vehicleNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "vehicleNumber", $$v)
                          },
                          expression: "queryForm.params.vehicleNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Resource.vehicleBrand") } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.queryForm.params.vehicleBrand,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm.params, "vehicleBrand", $$v)
                          },
                          expression: "queryForm.params.vehicleBrand",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Resource.vehicleIde") } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.queryForm.params.vehicleFrameNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryForm.params,
                              "vehicleFrameNumber",
                              $$v
                            )
                          },
                          expression: "queryForm.params.vehicleFrameNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("Resource.vehicleCondition") } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.params.vehicleStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.queryForm.params,
                                "vehicleStatus",
                                $$v
                              )
                            },
                            expression: "queryForm.params.vehicleStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("userManage.normal"),
                              value: 1,
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("newOperationCenter.abnormal"),
                              value: 0,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-search",
                      size: "mini",
                      type: "primary",
                    },
                    on: { click: _vm.getTableData },
                  },
                  [_vm._v(_vm._s(_vm.$t("operationCenter.Procurar")) + " ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-refresh", size: "mini" },
                    on: { click: _vm.resetData },
                  },
                  [_vm._v(_vm._s(_vm.$t("operationCenter.reset")) + " ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-button",
        {
          attrs: { size: "small", type: "primary" },
          on: {
            click: function ($event) {
              _vm.showAddVehicleDialog = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("Resource.addNewVehicle")) + " ")]
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.selectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              "reserve-selection": false,
              align: "center",
              type: "selection",
              width: "45",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("collectionCenter.ind"),
              align: "center",
              width: "80",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.$index + 1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.licensePlateNumber"),
              prop: "vehicleNumber",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#409eff", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.getVehicleInfo(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.vehicleNumber))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.registrationDate"),
              prop: "licensingTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.vehicleBrand"),
              prop: "vehicleBrand",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.vehicleModel"),
              prop: "vehicleType",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.vehicleIde"),
              prop: "vehicleFrameNumber",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("Resource.sshop"), prop: "store" },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.vehicleCondition"),
              prop: "vehicleStatus",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.vehicleStatus
                            ? _vm.$t("userManage.normal")
                            : _vm.$t("newOperationCenter.abnormal")
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.departmentInUse"),
              prop: "userDepartment",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.orgObjList["orgCode-" + scope.row.userDepartment]
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.currentDriver"),
              prop: "currentDriver",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.userIdAndNameObj[scope.row.currentDriver]) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.latestMaintenance"),
              prop: "serviceTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.latestRepair"),
              prop: "repairTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.InsuranceExpirationDate"),
              prop: "insuranceExpiryTime",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Resource.latestInventoryCheck"),
              prop: "updateTime",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("GlobalSetObj.operate"), align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCheck(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Resource.records")))]
                    ),
                    _c("el-divider", { attrs: { direction: "vertical" } }),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleLog(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Resource.register")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            staticStyle: { margin: "10px 0" },
            attrs: {
              "current-page": _vm.queryForm.pageNumber,
              "page-size": _vm.queryForm.pageSize,
              "page-sizes": [10, 20, 50, 100],
              total: _vm.total,
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.queryForm, "pageNumber", $event)
              },
              "update:pageSize": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "update:page-size": function ($event) {
                return _vm.$set(_vm.queryForm, "pageSize", $event)
              },
              "current-change": _vm.getTableData,
              "size-change": _vm.getTableData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Resource.addNewVehicle"),
            visible: _vm.showAddVehicleDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddVehicleDialog = $event
            },
            close: _vm.closeNewVehicle,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.newVehicle,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.licensePlateNumber"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "vehicleNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.newVehicle.vehicleNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.newVehicle, "vehicleNumber", $$v)
                              },
                              expression: "newVehicle.vehicleNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.registrationDate"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseChoose"),
                              trigger: ["blur", "change"],
                            },
                            prop: "licensingTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              placeholder: _vm.$t("GlobalSetObj.pleaseChoose"),
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.newVehicle.licensingTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.newVehicle, "licensingTime", $$v)
                              },
                              expression: "newVehicle.licensingTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.vehicleBrand"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "vehicleBrand",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.newVehicle.vehicleBrand,
                              callback: function ($$v) {
                                _vm.$set(_vm.newVehicle, "vehicleBrand", $$v)
                              },
                              expression: "newVehicle.vehicleBrand",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.vehicleModel"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "vehicleType",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.newVehicle.vehicleType,
                              callback: function ($$v) {
                                _vm.$set(_vm.newVehicle, "vehicleType", $$v)
                              },
                              expression: "newVehicle.vehicleType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.vehicleIde"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "vehicleFrameNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.newVehicle.vehicleFrameNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newVehicle,
                                  "vehicleFrameNumber",
                                  $$v
                                )
                              },
                              expression: "newVehicle.vehicleFrameNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.sshop"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "store",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.newVehicle.store,
                              callback: function ($$v) {
                                _vm.$set(_vm.newVehicle, "store", $$v)
                              },
                              expression: "newVehicle.store",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAddVehicleDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addVehicle } },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Resource.addNewRecord"),
            visible: _vm.innerVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.innerVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "maintenanceRef",
              attrs: {
                model: _vm.maintenance,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.licensePlateNumber"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "vehicleNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.maintenance.vehicleNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.maintenance, "vehicleNumber", $$v)
                              },
                              expression: "maintenance.vehicleNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("GlobalSetObj.Type"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseChoose"),
                              trigger: ["blur", "change"],
                            },
                            prop: "type",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.maintenance.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.maintenance, "type", $$v)
                                },
                                expression: "maintenance.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("Resource.maintenance"),
                                  value: 0,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("Resource.repair"),
                                  value: 1,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.shop"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "serviceShop",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.maintenance.serviceShop,
                              callback: function ($$v) {
                                _vm.$set(_vm.maintenance, "serviceShop", $$v)
                              },
                              expression: "maintenance.serviceShop",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.content"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "serviceContent",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                            },
                            model: {
                              value: _vm.maintenance.serviceContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.maintenance, "serviceContent", $$v)
                              },
                              expression: "maintenance.serviceContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.handler"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseChoose"),
                              trigger: ["blur", "change"],
                            },
                            prop: "createUser",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.maintenance.createUser,
                                callback: function ($$v) {
                                  _vm.$set(_vm.maintenance, "createUser", $$v)
                                },
                                expression: "maintenance.createUser",
                              },
                            },
                            _vm._l(_vm.customerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.realName +
                                    (item.username
                                      ? "（" + item.username + "）"
                                      : ""),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.expenses"),
                            rules: {
                              required: true,
                              message: _vm.$t("GlobalSetObj.pleaseInput"),
                              trigger: ["blur", "change"],
                            },
                            prop: "fee",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$t("GlobalSetObj.pleaseInput"),
                              type: "number",
                            },
                            model: {
                              value: _vm.maintenance.fee,
                              callback: function ($$v) {
                                _vm.$set(_vm.maintenance, "fee", $$v)
                              },
                              expression: "maintenance.fee",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.innerVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addRecord } },
                [_vm._v(_vm._s(_vm.$t("Resource.addNewRecord")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Resource.vehicleInventory"),
            visible: _vm.showVehicleInventoryDialog,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVehicleInventoryDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.vehicleInventory,
                "label-position": "top",
                size: "small",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.licensePlateNumber"),
                          },
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.vehicleInventory.vehicleNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.vehicleInventory,
                                  "vehicleNumber",
                                  $$v
                                )
                              },
                              expression: "vehicleInventory.vehicleNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.currentMileage") } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.vehicleInventory.mileage,
                              callback: function ($$v) {
                                _vm.$set(_vm.vehicleInventory, "mileage", $$v)
                              },
                              expression: "vehicleInventory.mileage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.currentDriver") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.vehicleInventory.currentDriver,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vehicleInventory,
                                    "currentDriver",
                                    $$v
                                  )
                                },
                                expression: "vehicleInventory.currentDriver",
                              },
                            },
                            _vm._l(_vm.customerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.realName +
                                    (item.username
                                      ? "（" + item.username + "）"
                                      : ""),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("Resource.vehicleCondition") },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.vehicleInventory.vehicleStatus,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vehicleInventory,
                                    "vehicleStatus",
                                    $$v
                                  )
                                },
                                expression: "vehicleInventory.vehicleStatus",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("userManage.normal"),
                                  value: 1,
                                },
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("newOperationCenter.abnormal"),
                                  value: 0,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("Resource.insuranceExpiration"),
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.vehicleInventory.insuranceExpiryTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.vehicleInventory,
                                  "insuranceExpiryTime",
                                  $$v
                                )
                              },
                              expression:
                                "vehicleInventory.insuranceExpiryTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.repair") } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.vehicleInventory.repairTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.vehicleInventory,
                                  "repairTime",
                                  $$v
                                )
                              },
                              expression: "vehicleInventory.repairTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.maintenance") } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              format:
                                _vm.$i18n.locale == "zh"
                                  ? _vm.zhDate
                                  : _vm.ptDate,
                              type: "datetime",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                            },
                            model: {
                              value: _vm.vehicleInventory.serviceTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.vehicleInventory,
                                  "serviceTime",
                                  $$v
                                )
                              },
                              expression: "vehicleInventory.serviceTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.locationOfUse") } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.vehicleInventory.usePlace,
                              callback: function ($$v) {
                                _vm.$set(_vm.vehicleInventory, "usePlace", $$v)
                              },
                              expression: "vehicleInventory.usePlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.inventoryClerk") } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "spareNumberPoo.Only10AreDisplayed"
                                ),
                                clearable: "",
                                filterable: "",
                              },
                              model: {
                                value: _vm.vehicleInventory.createUser,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vehicleInventory,
                                    "createUser",
                                    $$v
                                  )
                                },
                                expression: "vehicleInventory.createUser",
                              },
                            },
                            _vm._l(_vm.customerList, function (item, ind) {
                              return _c("el-option", {
                                key: ind,
                                attrs: {
                                  label:
                                    item.realName +
                                    (item.username
                                      ? "（" + item.username + "）"
                                      : ""),
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.frontOfTheCar") } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: _vm.filtTypeArr.join(","),
                                "before-upload": function (params) {
                                  return _vm.beforeUpload(params, "fileList1")
                                },
                                "file-list": _vm.fileList1,
                                "http-request": function (params) {
                                  return _vm.enclosureUpload(
                                    params,
                                    "fileList1"
                                  )
                                },
                                multiple: true,
                                "on-remove": function (params) {
                                  return _vm.handleRemove(params, "fileList1")
                                },
                                "show-file-list": true,
                                action: "",
                                "list-type": "picture-card",
                                size: "small",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.carInterior") } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: _vm.filtTypeArr.join(","),
                                "before-upload": function (params) {
                                  return _vm.beforeUpload(params, "fileList2")
                                },
                                "file-list": _vm.fileList2,
                                "http-request": function (params) {
                                  return _vm.enclosureUpload(
                                    params,
                                    "fileList2"
                                  )
                                },
                                multiple: true,
                                "on-remove": function (params) {
                                  return _vm.handleRemove(params, "fileList2")
                                },
                                "show-file-list": true,
                                action: "",
                                "list-type": "picture-card",
                                size: "small",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.trunk") } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: _vm.filtTypeArr.join(","),
                                "before-upload": function (params) {
                                  return _vm.beforeUpload(params, "fileList3")
                                },
                                "file-list": _vm.fileList3,
                                "http-request": function (params) {
                                  return _vm.enclosureUpload(
                                    params,
                                    "fileList3"
                                  )
                                },
                                multiple: true,
                                "on-remove": function (params) {
                                  return _vm.handleRemove(params, "fileList3")
                                },
                                "show-file-list": true,
                                action: "",
                                "list-type": "picture-card",
                                size: "small",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("Resource.exterior") } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                accept: _vm.filtTypeArr.join(","),
                                "before-upload": function (params) {
                                  return _vm.beforeUpload(params, "fileList4")
                                },
                                "file-list": _vm.fileList4,
                                "http-request": function (params) {
                                  return _vm.enclosureUpload(
                                    params,
                                    "fileList4"
                                  )
                                },
                                multiple: true,
                                "on-remove": function (params) {
                                  return _vm.handleRemove(params, "fileList4")
                                },
                                "show-file-list": true,
                                action: "",
                                "list-type": "picture-card",
                                size: "small",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showVehicleInventoryDialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("GlobalSetObj.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addVehicleInventory },
                },
                [_vm._v(_vm._s(_vm.$t("Resource.saveInformation")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("Resource.vehicleDetails"),
            visible: _vm.drawer,
            direction: "ltr",
            size: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card" } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("Resource.vehicleDetails") } },
                [
                  _c(
                    "el-card",
                    [
                      _c(
                        "el-form",
                        { attrs: { "label-position": "top", size: "small" } },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.licensePlateNumber"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.vehicleNumber)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.registrationDate"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.licensingTime)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.vehicleBrand"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.vehicleBrand)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.vehicleModel"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.vehicleType)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.vehicleIde"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.vehicleInfo.vehicleFrameNumber
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.sshop"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.vehicleInfo.store)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.vehicleCondition"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.vehicleInfo.vehicleStatus
                                              ? _vm.$t("userManage.normal")
                                              : _vm.$t(
                                                  "newOperationCenter.abnormal"
                                                )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.departmentInUse"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orgObjList[
                                              "orgCode-" +
                                                _vm.vehicleInfo.userDepartment
                                            ]
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.currentDriver"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.userIdAndNameObj[
                                              _vm.vehicleInfo.currentDriver
                                            ]
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.latestMaintenance"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.serviceTime)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t("Resource.latestRepair"),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.repairTime)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.InsuranceExpirationDate"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.vehicleInfo.insuranceExpiryTime
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "Resource.latestInventoryCheck"
                                        ),
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.vehicleInfo.updateTime)
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("Resource.maintenanceRecords") } },
                [
                  _c("el-card", { staticClass: "box-card" }, [
                    _c(
                      "div",
                      { staticStyle: { height: "80vh", "overflow-y": "auto" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.maintenanceList },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.licensePlateNumber"),
                                prop: "vehicleNumber",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("GlobalSetObj.Type"),
                                prop: "type",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.type
                                              ? _vm.$t("Resource.repair")
                                              : _vm.$t("Resource.maintenance")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.shop"),
                                prop: "serviceShop",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.content"),
                                prop: "serviceContent",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.handler"),
                                prop: "createUser",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.userIdAndNameObj[
                                              scope.row.createUser
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.expenses"),
                                prop: "fee",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pagination" },
                          [
                            _c("el-pagination", {
                              staticStyle: { margin: "10px 0" },
                              attrs: {
                                "current-page":
                                  _vm.maintenanceQueryForm.pageNumber,
                                "page-size": _vm.maintenanceQueryForm.pageSize,
                                "page-sizes": [10, 20, 50, 100],
                                total: _vm.maintenanceTotal,
                                background: "",
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                              },
                              on: {
                                "update:currentPage": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageNumber",
                                    $event
                                  )
                                },
                                "update:current-page": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageNumber",
                                    $event
                                  )
                                },
                                "update:pageSize": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageSize",
                                    $event
                                  )
                                },
                                "update:page-size": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageSize",
                                    $event
                                  )
                                },
                                "current-change": _vm.getMaintenanceList,
                                "size-change": _vm.getMaintenanceList,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$t("Resource.inventoryRecords") } },
                [
                  _c("el-card", { staticClass: "box-card" }, [
                    _c(
                      "div",
                      { staticStyle: { height: "80vh", "overflow-y": "auto" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: _vm.inventoryRecordsList },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.licensePlateNumber"),
                                prop: "vehicleNumber",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.currentMileage"),
                                prop: "mileage",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.currentDriver"),
                                prop: "currentDriver",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.userIdAndNameObj[
                                              scope.row.currentDriver
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.vehicleCondition"),
                                prop: "vehicleStatus",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.vehicleStatus
                                              ? _vm.$t("userManage.normal")
                                              : _vm.$t(
                                                  "newOperationCenter.abnormal"
                                                )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t(
                                  "Resource.InsuranceExpirationDate"
                                ),
                                prop: "insuranceExpiryTime",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.CreationTime"),
                                prop: "createTime",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: _vm.$t("Resource.inventoryClerk"),
                                prop: "createUser",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.userIdAndNameObj[
                                              scope.row.createUser
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pagination" },
                          [
                            _c("el-pagination", {
                              staticStyle: { margin: "10px 0" },
                              attrs: {
                                "current-page":
                                  _vm.maintenanceQueryForm.pageNumber,
                                "page-size": _vm.maintenanceQueryForm.pageSize,
                                "page-sizes": [10, 20, 50, 100],
                                total: _vm.maintenanceTotal,
                                background: "",
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                              },
                              on: {
                                "update:currentPage": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageNumber",
                                    $event
                                  )
                                },
                                "update:current-page": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageNumber",
                                    $event
                                  )
                                },
                                "update:pageSize": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageSize",
                                    $event
                                  )
                                },
                                "update:page-size": function ($event) {
                                  return _vm.$set(
                                    _vm.maintenanceQueryForm,
                                    "pageSize",
                                    $event
                                  )
                                },
                                "current-change": _vm.getInventoryRecordsList,
                                "size-change": _vm.getInventoryRecordsList,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }