<template>
  <div class="kanban_content">
    <div style="display: flex; justify-content: space-between;">
      <div>{{ $t('customerManagements.dataUpdateTime') }}: {{ centerData.updateTime || '--' }}</div>
      <div>
        <span style="margin-right: 10px;">{{ $t('collectionCenter.transferCenter') }}</span>
        <el-select
          v-model="value" :placeholder="$t('GlobalSetObj.pleaseChoose')"
          filterable
          @change="getcenterData"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
    </div>
    <!--   数据内容   -->
    <div class="data_01">
      <el-row :gutter="100">
        <el-col :span="5" class="classGuird">
          <div class="class_font">{{ $t('customerManagements.NumberArrived') }}</div>
          <el-card shadow="hover">
            <div class="class_font18">{{ centerData.unload || 0 }}</div>
          </el-card>
        </el-col>
        <el-col :span="5" class="classGuird">
          <div class="class_font">{{ $t('customerManagements.loaderArrived') }}</div>
          <el-card shadow="hover">
            <div class="class_font18">{{ centerData.loading || 0 }}</div>
          </el-card>
        </el-col>
        <el-col :span="5" class="classGuird">
          <div class="class_font">{{ $t('customerManagements.differenceArrived') }}</div>
          <el-card shadow="hover">
            <div class="class_font18">{{ centerData.difference || 0 }}</div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="data_02">
      <div>
        <span style="font-weight: bold;font-size: 18px;">{{ $t('customerManagements.NumberVotes') }}</span>
        <span
          style="margin-left: 10px;color: #409eff;text-decoration: underline;cursor: pointer;"
          @click="downloadNumber"
        >{{ $t('customerManagements.downloadNumber') }}</span>
      </div>
      <div style="display: flex;margin-top: 30px;">
        <div>
          <div style="font-weight: bold">{{ $t('customerManagements.HoursDetention24') }}</div>
          <div style="color:#b47925;font-size: 30px;font-weight: bold;">{{ centerData.twentyFourHours || 0 }}</div>
        </div>
        <div style="margin: 0 50px;">
          <div style="font-weight: bold">{{ $t('customerManagements.HoursDetention48') }}</div>
          <div style="color:#14bdbd;font-size: 30px;font-weight: bold;">{{ centerData.fortyEightHours || 0 }}</div>
        </div>
        <div>
          <div style="font-weight: bold">{{ $t('customerManagements.HoursDetention72') }}</div>
          <div style="color: #8482f1;font-size: 30px;font-weight: bold;">{{ centerData.seventyTwoHours || 0 }}</div>
        </div>
      </div>
    </div>
    <div style="display: flex;margin-top: 50px;">
      <div style="margin-right: 50px;">
        <div style="font-weight: bold">{{ $t('customerManagements.DayDetention7') }}</div>
        <div style="color: #107d80;font-size: 30px;font-weight: bold;">{{ centerData.sevenDays || 0 }}</div>
      </div>
      <div>
        <div style="font-weight: bold">{{ $t('customerManagements.DayDetention15') }}</div>
        <div style="color: #9d0319;font-size: 30px;font-weight: bold;">{{ centerData.fifteenDays || 0 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSiteTransfer, getTransferDashboard, getTransferDashdownload } from '@/api/orderApi';

export default {
  name: 'Index',
  data() {
    return {
      value: '',
      options: [],
      centerData: {}
    };
  },
  mounted() {
    this.getSiteTransfer();
  },
  methods: {
    getSiteTransfer() {
      getSiteTransfer().then(res => {
        if (res.status === 'OK') {
          this.options = res.data;
          this.getcenterData();
        }
      });
    },
    getcenterData() {
      const id = this.value || this.$store.getters.siteId;
      getTransferDashboard(id).then(res => {
        if (res.status === 'OK') {
          console.log(res, 'getcenterData');
          this.centerData = res.data;
          const transterId = this.centerData.siteId; // 转运中心id
          const obj = this.options.find(item => item.id === transterId);
          if (obj) {
            this.$nextTick(() => {
              this.value = obj.id;
            });
          }
        }
      });
    },
    downloadNumber() {
      const id = this.value;
      getTransferDashdownload(id).then(res => {
        if (res.status === 'OK') {
          const h = this.$createElement;
          this.$msgbox({
            title: this.$i18n.t('operationCenter.tips'),
            message: h('p', null, [
              h('span', this.$i18n.t('orderCenterCont.ExportSuccessful14')), // '导出成功，数据将会在1~10分钟内生成表格，请到系统管理中的资源导出列表中下载，'
              h('span', { style: 'color: red' }, this.$i18n.t('customerManagements.pleaseDownLoad')) // '请勿重复导出！'
            ]),
            showCancelButton: true,
            confirmButtonText: this.$i18n.t('orderCenterCont.GoNow'),
            cancelButtonText: this.$i18n.t('orderCenterCont.ShutDown')
          })
            .then((action) => {
              if (action === 'confirm') {
                this.$router.push({ path: '/resource/exportTaskList' });
              }
            })
            .catch(() => {
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.kanban_content {
  padding: 20px;
}

.kanban_content /deep/ .el-card__body {
  padding: 20px 10px;
}

.class_font18 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.class_font {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}

.data_01 {
  margin-top: 20px;
}

.data_02 {
  margin-top: 60px;
}
</style>
