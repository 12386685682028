<template>
  <div class="listQueryBox">
    <div style="margin-bottom: 20px;">
      <el-form class="selectClass">
        <el-row>
          <el-col :span="7" style="width: auto;">
            <!-- 省份： -->
            <el-form-item :label="$t('collectionCenter.province')">
              <el-select
                v-model="queryForm.params.twoCode" :placeholder="$t('collectionCenter.pleaseChoose')" clearable
                filterable
              >
                <el-option
                  v-for="(item, index) in twoCodeList"
                  :key="index"
                  :label="item.twoCode"
                  :value="item.twoCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="width: auto;">
            <!-- 起始邮编： -->
            <el-form-item :label="$t('collectionCenter.startZipC')">
              <el-input v-model.trim="queryForm.params.startZipCode" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <!-- 城市名称： -->
            <el-form-item :label="$t('collectionCenter.cityName')">
              <el-input v-model.trim="queryForm.params.extra" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :span="12">
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:zipZone:search'"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="searchClick"
          >{{ $t('collectionCenter.search') }}</el-button>
          <!-- 查询 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:zipZone:reset'"
            icon="el-icon-refresh"
            size="mini"
            @click="resetClick"
          >{{ $t('collectionCenter.reset') }}</el-button>
          <!-- 重置 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:zipZone:add'"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="addClick"
          >{{ $t('collectionCenter.add') }}</el-button><!-- 新增 -->
          <el-button
            v-permit:remove="'btn:collection-center:basicDate:zipZone:export'"
            icon="el-icon-upload2"
            size="mini"
            :class="idList.length <=0 && isClick ? 'onlyClickOnce10' : ''"
            @click="download"
          >{{ $t('collectionCenter.export') }}</el-button>
          <!-- 导出 -->
        </el-col>
      </el-row>
      <el-divider />
    </div>
    <div>
      <el-table
        ref="tableRef"
        border
        :data="tableDate"
        :max-height="600"
        @select="batchSelect"
        @select-all="batchSelectAll"
      >
        <el-table-column
          type="selection"
          width="55"
        />
        <!-- 序号 -->
        <el-table-column
          :label="$t('collectionCenter.ind')" type="index" width="60"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ (queryForm.pageNumber - 1) * queryForm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="twoCode"
          align="center"
          :label="$t('collectionCenter.provinceee')"
        /><!-- 省份 -->
        <el-table-column
          prop="city"
          align="center"
          :label="$t('collectionCenter.city')"
        /><!-- 城市名称 -->
        <el-table-column
          prop="startZipCode"
          align="center"
          :label="$t('collectionCenter.startZipCode')"
        /><!-- 起始邮编 -->
        <el-table-column
          prop="endZipCode"
          align="center"
          :label="$t('collectionCenter.endZipCode')"
        /><!-- 结束邮编 -->
        <el-table-column
          prop="divideGroups"
          align="center"
          :label="$t('collectionCenter.group')"
        /><!-- 组别 -->
        <el-table-column
          prop="macroRegion"
          align="center"
          :label="$t('collectionCenter.region')"
        /><!-- 大区 -->
        <el-table-column
          prop="microRegion"
          align="center"
          :label="$t('collectionCenter.area')"
        /><!-- 区域 -->
        <el-table-column
          v-if="edit || del"
          :label="$t('collectionCenter.operation')"
          fixed="right"
          width="160"
          align="center"
        ><!-- 操作 -->
          <template slot-scope="scope">
            <el-button
              v-if="edit" type="primary" plain
              size="mini" @click="editCellClick(scope.row)"
            >{{ $t('collectionCenter.modify') }}</el-button>
            <el-popconfirm
              :title="$t('collectionCenter.deleteSure')"
              @confirm="delCellClick(scope.row, scope.$index)"
            >
              <el-button
                v-if="del" slot="reference" type="danger"
                plain size="mini"
              >{{ $t('collectionCenter.Delete') }}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="right">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        :current-page.sync="queryForm.pageNumber"
        :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size.sync="queryForm.pageSize"
        :total="total"
        background
        style="margin: 10px 0"
        @current-change="getListPage"
        @size-change="getPageSize"
      />
    </div>

    <div v-if="drawerIsOpen">
      <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerIsOpen"
        :direction="direction"
        size="40%"
        @close="closeDrawer"
      >
        <div class="drawerClass">
          <el-form ref="drawerFormRef" :model="drawerForm" :rules="rules">
            <el-row type="flex" justify="space-between" style="flex-wrap: wrap;">
              <el-col :span="11">
                <!-- 省份： -->
                <el-form-item prop="twoCode" :label="$t('collectionCenter.province')">
                  <el-input v-model="drawerForm.twoCode" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 省份扩展名称： -->
                <el-form-item prop="twoCodeExtra" :label="$t('collectionCenter.provinceExtensionName')">
                  <el-input v-model="drawerForm.twoCodeExtra" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" style="flex-wrap: wrap;">
              <el-col :span="11">
                <!-- 城市名称： -->
                <el-form-item prop="city" :label="$t('collectionCenter.city') + '：'">
                  <el-input v-model="drawerForm.city" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 城市扩展名称： -->
                <el-form-item prop="extra" :label="$t('collectionCenter.cityExtraName')">
                  <el-input v-model="drawerForm.extra" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" style="flex-wrap: wrap;">
              <el-col :span="11">
                <!-- 起始邮编： -->
                <el-form-item prop="startZipCode" :label="$t('collectionCenter.startZipC')">
                  <el-input v-model.trim="drawerForm.startZipCode" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 结束邮编： -->
                <el-form-item prop="endZipCode" :label="$t('collectionCenter.endZipC')">
                  <el-input v-model.trim="drawerForm.endZipCode" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between" style="flex-wrap: wrap;">
              <el-col :span="11">
                <!-- 组别： -->
                <el-form-item prop="divideGroups" :label="$t('collectionCenter.group') + '：'">
                  <el-input v-model.trim="drawerForm.divideGroups" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <!-- 大区： -->
                <el-form-item prop="macroRegion" :label="$t('collectionCenter.region') + '：'">
                  <el-input v-model.trim="drawerForm.macroRegion" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="start">
              <el-col :span="11">
                <!-- 区域： -->
                <el-form-item prop="microRegion" :label="$t('collectionCenter.area') + '：'">
                  <el-input v-model.trim="drawerForm.microRegion" clearable :placeholder="$t('collectionCenter.pleaseInput')" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="submitClickBtn">
            <el-button @click="submitClick">{{ $t('collectionCenter.Save') }}</el-button><!-- 保存 -->
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  zipCodeDivision,
  provinceQueryAll,
  zipCodeDivisionAdd,
  zipCodeDivisionEdit,
  zipCodeDivisionDel,
  zipCodeDivisionExport
} from '@/api/lanshou';
import { goExportList } from '@/utils/goExportList.js';

export default {
  name: 'ZipZone',
  data() {
    return {
      isClick: false,
      edit: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:zipZone:edit'),
      del: this.$store.getters.button_permissions.includes('btn:collection-center:basicDate:zipZone:del'),
      drawerIsOpen: false,
      drawerTitle: '',
      direction: 'ltr',
      queryForm: {
        pageNumber: 1,
        pageSize: 10,
        params: {
          twoCode: '',
          startZipCode: '',
          extra: ''
        }
      },
      twoCodeList: [],
      total: 0,
      tableDate: [],
      idList: [], // 导出id
      drawerForm: {
        twoCode: '',
        city: '', // 城市名称
        extra: '', // 城市扩展名称
        twoCodeExtra: '', // 省份扩展名称
        startZipCode: '',
        endZipCode: '',
        divideGroups: '',
        macroRegion: '',
        microRegion: ''
      },
      rules: {
        twoCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              const twoCodeRep = /^[A-Z]{1,2}$/g;
              if (!twoCodeRep.test(value)) {
                return callback(new Error(this.$t('collectionCenter.pleaseInputStateTwoCode'))); // '请填写对应州二字码(大写)'
              } else {
                callback();
              }
            }
          }
        ],
        startZipCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              const reg = /^[0-9]{8}$/g;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.input8Sum'))); // '请输入8位数字'
              } else if ((this.drawerForm.endZipCode && this.drawerForm.startZipCode >= this.drawerForm.endZipCode) &&
                (this.drawerForm.startZipCode.length === 8 && this.drawerForm.endZipCode.length === 8)) {
                return callback(new Error(this.$t('collectionCenter.startZipcodeLessEndZipcode'))); // '开始邮编不能大于或等于结束邮编'
              } else {
                if (this.drawerForm.endZipCode && this.drawerForm.endZipCode.length === 8) {
                  this.$refs.drawerFormRef.clearValidate('endZipCode');
                }
                callback();
              }
            }
          }
        ],
        endZipCode: [
          { required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] },
          {
            validator: async(rule, value, callback) => {
              const reg = /^[0-9]{8}$/g;
              if (!reg.test(value)) {
                return callback(new Error(this.$t('collectionCenter.input8Sum'))); // '请输入8位数字'
              } else if ((this.drawerForm.startZipCode && this.drawerForm.startZipCode >= this.drawerForm.endZipCode) &&
                (this.drawerForm.startZipCode.length === 8 && this.drawerForm.endZipCode.length === 8)) {
                return callback(new Error(this.$t('collectionCenter.startZipcodeLessEndZipcode'))); // '开始邮编不能大于或等于结束邮编'
              } else {
                if (this.drawerForm.startZipCode && this.drawerForm.startZipCode.length === 8) {
                  this.$refs.drawerFormRef.clearValidate('startZipCode');
                }
                callback();
              }
            }
          }
        ],
        city: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        extra: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        twoCodeExtra: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        divideGroups: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        macroRegion: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }],
        microRegion: [{ required: true, message: this.$t('collectionCenter.notNull'), trigger: ['blur', 'change'] }]
      }
    };
  },
  created() {
    this.defaultCondition();
    this.searchClick();
    this.getSheng();
    this.isClick = false;
  },
  methods: {
    getSheng() {
      const data = {};
      provinceQueryAll(data).then(res => {
        if (res.status === 'OK') {
          this.twoCodeList = [];
          res.data.forEach(item => {
            this.twoCodeList.push({
              twoCode: item
            });
          });
        } else {
          this.twoCodeList = [];
        }
      }).catch(err => {
        console.log(err);
        this.twoCodeList = [];
      });
    },
    getListPage() { // 表格
      this.$nextTick(() => {
        this.idList = [];
        this.$refs.tableRef.clearSelection();
      });
      const queryForm = JSON.parse(localStorage.getItem('zzQueryForm')) || this.queryForm;
      queryForm.pageNumber = this.queryForm.pageNumber;
      queryForm.pageSize = this.queryForm.pageSize;
      zipCodeDivision(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    defaultCondition() { // 默认查询条件
      this.queryForm.params = {
        twoCode: '',
        startZipCode: '',
        extra: ''
      };
    },
    getPageSize() {
      this.queryForm.pageNumber = 1;
      const query = JSON.parse(localStorage.getItem('zzQueryForm')) || this.queryForm;
      const queryForm = {
        pageNumber: 1,
        pageSize: this.queryForm.pageSize,
        params: query.params
      };
      zipCodeDivision(queryForm).then(res => {
        if (res.status === 'OK') {
          this.tableDate = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    searchClick() {
      if (this.queryForm.params.startZipCode.length > 8) {
        this.$message.warning(this.$t('collectionCenter.input8ZipCode')); // 请输入8位邮编
        return;
      }
      this.queryForm.pageNumber = 1;
      localStorage.setItem('zzQueryForm', JSON.stringify(this.queryForm));
      // 查询
      this.getListPage();
    },
    resetClick() { // 重置
      this.queryForm.params.twoCode = '';
      this.queryForm.params.startZipCode = '';
      this.queryForm.params.extra = '';
    },
    addClick() {
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.add');
    },
    editCellClick(row) {
      this.drawerIsOpen = true;
      this.drawerTitle = this.$t('collectionCenter.modify');
      this.drawerForm = {
        twoCode: row.twoCode,
        city: row.city, // 城市名称
        extra: row.extra, // 城市扩展名称
        twoCodeExtra: row.twoCodeExtra, // 省份扩展名称
        startZipCode: row.startZipCode,
        endZipCode: row.endZipCode,
        divideGroups: row.divideGroups,
        macroRegion: row.macroRegion,
        microRegion: row.microRegion
      };
      this.drawerForm.id = row.id;
    },
    submitClick() {
      this.$refs['drawerFormRef'].validate(valid => {
        if (!valid) { return; }
        if (this.drawerForm.startZipCode.length !== 8 || this.drawerForm.endZipCode.length !== 8) {
          return;
        }
        if (this.drawerTitle === this.$t('collectionCenter.add')) {
          zipCodeDivisionAdd(this.drawerForm).then(res => {
            if (res.status === 'OK') {
              this.drawerIsOpen = false;
              this.$message({
                message: this.$t('collectionCenter.SaveSuccessfully'), // '保存成功'
                type: 'success'
              });
              this.closeDrawer();
              this.searchClick();
              this.getSheng();
            }
          });
        }
        if (this.drawerTitle === this.$t('collectionCenter.modify')) {
          zipCodeDivisionEdit(this.drawerForm).then(res => {
            if (res.status === 'OK') {
              this.drawerIsOpen = false;
              this.$message({
                message: this.$t('collectionCenter.ModificationSuccessful'), // '修改成功'
                type: 'success'
              });
              this.closeDrawer();
              this.searchClick();
              this.getSheng();
            }
          });
        }
      });
      // this.searchClick(); // 提交成功后调用
    },
    closeDrawer() { // 关闭弹窗
      this.drawerForm = {
        twoCode: '',
        city: '', // 城市名称
        extra: '', // 城市扩展名称
        twoCodeExtra: '', // 省份扩展名称
        startZipCode: '',
        endZipCode: '',
        divideGroups: '',
        macroRegion: '',
        microRegion: ''
      };
      this.$refs['drawerFormRef'].clearValidate();
    },
    delCellClick(row, index) {
      zipCodeDivisionDel(row.id).then(res => {
        if (res.status === 'OK') {
          this.$message({
            message: this.$t('collectionCenter.DeleteSucceeded'), // '删除成功'
            type: 'success'
          });
          this.searchClick();
          this.getSheng();
        }
      });
    },
    batchSelect(selection, row) {
      const collectListIdList = [];
      selection.forEach(list => {
        collectListIdList.push(list.id);
      });
      this.idList = collectListIdList;
    },
    batchSelectAll(selection) {
      const collectListIdListAll = [];
      selection.forEach(list => {
        collectListIdListAll.push(list.id);
      });
      this.idList = collectListIdListAll;
    },
    download() { // 导出
      this.isClick = true;
      if (this.idList.length <= 0) {
        // 请勾选要导出的数据
        this.$message.warning(this.$t('collectionCenter.PleaseTickTheDataExport'));
      } else {
        const queryForm = {
          idList: this.idList
        };
        zipCodeDivisionExport(queryForm).then(res => {
          if (res.status === 'OK') {
            this.isClick = false;
            this.idList = [];
            this.$refs.tableRef.clearSelection();
            goExportList(this);
          }
        }).catch(err => {
          console.log(err);
          this.isClick = false;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>

// 防止按钮重复点击
@keyframes throttle{
  from{
    filter: opacity(40%);
    pointer-events: none;
  }
  to{
    pointer-events: all;
  }
}
.onlyClickOnce2{
  animation: throttle 2s step-end forwards;
}
.onlyClickOnce2:active{
  filter: opacity(40%);
  animation: none;
}
.onlyClickOnce10{
  animation: throttle 10s step-end forwards;
}
.onlyClickOnce10:active{
  filter: opacity(40%);
  animation: none;
}

.selectClass ::v-deep .el-form-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.selectClass ::v-deep .el-form-item__label{
  padding: 0;
  line-height: 16px;
  white-space: nowrap;
  margin-left: 20px;
}
.selectClass ::v-deep .el-form-item__content{
  margin-left: 0 !important;
  width: 100%;
}
.submitClickBtn{
  text-align: right;
  margin-top: 30px;
}
.selectClass ::v-deep .el-select{
  width: 100%;
}
.listQueryBox, .drawerClass{
  padding: 20px;
  box-sizing: border-box;
}
.right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
