import { render, staticRenderFns } from "./SealForm.vue?vue&type=template&id=e2b21d04&scoped=true"
import script from "./SealForm.vue?vue&type=script&lang=js"
export * from "./SealForm.vue?vue&type=script&lang=js"
import style0 from "./SealForm.vue?vue&type=style&index=0&id=e2b21d04&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2b21d04",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e2b21d04')) {
      api.createRecord('e2b21d04', component.options)
    } else {
      api.reload('e2b21d04', component.options)
    }
    module.hot.accept("./SealForm.vue?vue&type=template&id=e2b21d04&scoped=true", function () {
      api.rerender('e2b21d04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/transfer/transferRoute/components/form/SealForm.vue"
export default component.exports