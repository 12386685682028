var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "pcpdaClass" }, [_vm._v("PC")]),
      _c("el-tree", {
        ref: "treeRefPC",
        attrs: {
          data: _vm.permissionList.pc,
          "default-checked-keys": _vm.defSelectedPermissionList.pc,
          props: _vm.props,
          "icon-class": "el-icon-thumb",
          "node-key": "id",
          "show-checkbox": "",
        },
      }),
      _c(
        "div",
        { staticClass: "pcpdaClass", staticStyle: { "margin-top": "20px" } },
        [_vm._v("PDA")]
      ),
      _c("el-tree", {
        ref: "treeRefPDA",
        attrs: {
          data: _vm.permissionList.pda,
          "default-checked-keys": _vm.defSelectedPermissionList.pda,
          props: _vm.props,
          "icon-class": "el-icon-thumb",
          "node-key": "id",
          "show-checkbox": "",
        },
      }),
      _c(
        "div",
        { staticClass: "pcpdaClass", staticStyle: { "margin-top": "20px" } },
        [_vm._v("调度")]
      ),
      _c("el-tree", {
        ref: "treeRefSchedule",
        attrs: {
          data: _vm.permissionList.schedule,
          "default-checked-keys": _vm.defSelectedPermissionList.schedule,
          props: _vm.props,
          "icon-class": "el-icon-thumb",
          "node-key": "id",
          "show-checkbox": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }