import { render, staticRenderFns } from "./signFor.vue?vue&type=template&id=08d92667&scoped=true"
import script from "./signFor.vue?vue&type=script&lang=js"
export * from "./signFor.vue?vue&type=script&lang=js"
import style0 from "./signFor.vue?vue&type=style&index=0&id=08d92667&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d92667",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/work/jenkins/workspace/重构-快递前端/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08d92667')) {
      api.createRecord('08d92667', component.options)
    } else {
      api.reload('08d92667', component.options)
    }
    module.hot.accept("./signFor.vue?vue&type=template&id=08d92667&scoped=true", function () {
      api.rerender('08d92667', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/operatingPlatform/signFor.vue"
export default component.exports