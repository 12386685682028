import request from '@/utils/request';
const baseFinanceURL = process.env.VUE_APP_FINANCE;

// 理赔列表

// 分页
export const apiClaimformPage = data => {
  return request({
    method: 'POST',
    url: '/claimform/page',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 详情
export const apiClaimformIds = id => {
  return request({
    method: 'GET',
    url: `/claimform/${id}`,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 保存
export const apiClaimformAdd = data => {
  return request({
    method: 'POST',
    url: '/claimform/add',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 关闭
export const apiClaimformClose = id => {
  return request({
    method: 'GET',
    url: `/claimform/close/${id}`,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 修改
export const apiClaimformEdit = data => {
  return request({
    method: 'PUT',
    url: '/claimform/edit',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 提交审核
export const apiClaimformCommit = id => {
  return request({
    method: 'POST',
    url: `/claimform/commit/${id}`,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 关联订单详情-普通订单/备货揽收/快递单
export const apiClaimformOrderDetail = (data, isLoading = true) => {
  return request({
    method: 'POST',
    url: '/claimform/orderDetail',
    data,
    isLoading, // 是否显示加载中参数
    baseURL: baseFinanceURL
  });
};

// 提交付款
export const apiClaimformCommitPay = data => {
  return request({
    method: 'POST',
    url: '/claimform/commitPay',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 审核
export const apiClaimformRejectAndPass = data => {
  return request({
    method: 'POST',
    url: '/claimform/rejectAndPass',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 关联订单-导出
export const apiClaimformExport = data => {
  return request({
    method: 'POST',
    url: '/claimform/export',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

// 理赔列表-导出
export const apiClaimformFormExport = data => {
  return request({
    method: 'POST',
    url: '/claimform/formExport',
    data,
    isLoading: false, // 是否显示加载中参数
    baseURL: baseFinanceURL
  })
};

